import React from "react";

const VirginiaRates = () => {
  return (
    <div className="container">
      <div className="panel-grey">
        <div id="virginia">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className='modal-heading'>Virginia</h4>
              </div>
              <p className="centered-and-italic">
                Fig Loans is not currently originating loans in the State of Virginia.
              </p>
              <p>
                <b>Fee Examples</b>
              </p>
              <p>
                <div className="disclosurewrap">
                  <div className="full-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Amount Advanced</th>
                          <td>$300.00</td>
                          <td>$500.00</td>
                          <td>$1,000.00</td>
                        </tr>
                        <tr>
                          <th>
                            Loan Length
                          </th>
                          <td>3 months</td>
                          <td>5 months</td>
                          <td>10 months</td>
                        </tr>
                        <tr>
                          <th>
                            Loan APR<sup>1</sup>
                          </th>
                          <td>172.64%</td>
                          <td>128.08%</td>
                          <td>83.73%</td>
                        </tr>
                        <tr>
                          <th>
                            Monthly Maintenance Fees<sup>2</sup>
                          </th>
                          <td>$72.00</td>
                          <td>$125.00</td>
                          <td>$250.00</td>
                        </tr>
                        <tr>
                          <th>Interest Charge</th>
                          <td>$18.18</td>
                          <td>$45.89</td>
                          <td>$172.31</td>
                        </tr>
                        <tr>
                          <th>Monthly Payment Amount</th>
                          <td>$130.06</td>
                          <td>$134.18</td>
                          <td>$142.23</td>
                        </tr>
                        <tr>
                          <th>Total Repayment Amount</th>
                          <td>$390.18</td>
                          <td>$670.89</td>
                          <td>$1,422.31</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mobile-size">
                    <table>
                      <tr>
                        <th>Sample $300 Dollar 3 Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $300.00
                        </td>
                        <td>
                          <b>Loan Length:</b> 3 months
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          172.64%
                        </td>
                        <td>
                          <b>
                            Monthly Maintenance Fees<sup>2</sup>:
                          </b>{" "}
                          $72.00
                        </td>
                        <td>
                          <b>Interest Charge:</b> $18.18
                        </td>
                        <td>
                          <b>Monthly Payment Amount:</b> $130.06
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$390.18
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $500 Dollar 5 Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $500.00
                        </td>
                        <td>
                          <b>Loan Length:</b> 5 months
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          128.08%
                        </td>
                        <td>
                          <b>
                            Monthly Maintenance Fees<sup>2</sup>:
                          </b>{" "}
                          $125.00
                        </td>
                        <td>
                          <b>Interest Charge:</b> $45.89
                        </td>
                        <td>
                          <b>Monthly Payment Amount:</b> $134.18
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$670.89
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $1,000 Dollar 10 Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $1,000.00
                        </td>
                        <td>
                          <b>Loan Length:</b> 10 months
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          83.73%
                        </td>
                        <td>
                          <b>
                            Monthly Maintenance Fees<sup>2</sup>:
                          </b>{" "}
                          $250.00
                        </td>
                        <td>
                          <b>Interest Charge:</b> $172.31
                        </td>
                        <td>
                          <b>Monthly Payment Amount:</b> $142.23
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$1,422.31
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </p>
              <p className="footnote">
                1. The APR shown is based on the federal Truth in Lending Act
                and does not reflect specialized state APR calculations, if any.
                <br />
              </p>
              <p>
                The loan information shown here is an example and may not
                reflect the rates available on all loans. Before entering into
                any transaction, you should read the disclosure statements and
                contract that are provided to you by Fig Loans.
              </p>
              <p className="footnote">
                2. The monthly maintenance fee will be the lesser of 8% of your
                loan amount or $25.00.
                <br />
              </p>
              <p>
                <b>Notices</b>
              </p>
              <p>
                If you wish to file a complaint against us, you may contact the
                Virginia Bureau of Financial Institutions at (800) 552-7945 or
                at scc.virginia.gov.
              </p>
              <p>
                <b>LICENSES</b>
              </p>
              <p>
                Fig Loans is a lender licensed by the Virginia State Corporation 
                Commission.
              </p>
              <p>
                <img
                  src="https://figloans-static-images.s3.us-west-2.amazonaws.com/licenses/virginia_license.jpg"
                  alt="Lending License"
                  className="img-responsive faq-image"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default VirginiaRates;