import React, { Fragment } from "react";
import { connect } from "react-redux";
import { getProducts } from "../../actions/info_actions";
import IllinoisRates from "./rates/IllinoisRates";
import MissouriRates from "./rates/MissouriRates";
import OhioRates from "./rates/OhioRates";
import TexasRates from "./rates/TexasRates";
import UtahRates from "./rates/UtahRates";
import CaliforniaRates from "./rates/CaliforniaRates";
import FloridaRates from "./rates/FloridaRates";
import NewMexicoRates from "./rates/NewMexicoRates";
import VirginiaRates from "./rates/VirginiaRates";
import { Helmet } from "react-helmet";

const NoticesRates = ({ site, products, userInfo, getProducts, landing }) => {
  const id = userInfo ? userInfo.id : null;

  if (!products) {
    if (landing) {
      getProducts(landing, id);
      return <div />;
    } else if (site) {
      getProducts(site, id);
      return <div />;
    }
  }

  const getRateDisplay = () => {
    const location = landing || site;
    switch (location) {
      case "CA":
        return <CaliforniaRates />;
      case "FL":
        return <FloridaRates products={products} />;
      case "IL":
        return <IllinoisRates products={products} />;
      case "MO":
        return <MissouriRates products={products} />;
      case "NM":
        return <NewMexicoRates products={products} />;
      case "OH":
        return <OhioRates products={products} />;
      case "UT":
        return <UtahRates products={products} />;
      case "TX":
        return <TexasRates products={products} />;
      case "VA":
        return <VirginiaRates />;  
      default:
        return (
          <Fragment>
            <CaliforniaRates />
            <FloridaRates />
            <IllinoisRates />
            <MissouriRates />
            <NewMexicoRates />
            <OhioRates />
            <UtahRates />
            <TexasRates />
            <VirginiaRates />
          </Fragment>
        );
    }
  };

  // landing page takes priority
  return (
    <React.Fragment>
      <Helmet>
        <link rel="canonical" href="https://www.figloans.com/rates" />
        <title>Fig Loans | Rates and Notices</title>
      </Helmet>
      <div className="container">
        <div className="panel-grey">
          <div className="mission">
            <div className="mission-text">
              <h4 className='modal-heading'>Rates and Notices</h4>
              {getRateDisplay()}
            </div>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  site: state.info.location,
  products: state.info.products,
  userInfo: state.session.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getProducts: (state, user_id) => dispatch(getProducts(state, user_id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticesRates);
