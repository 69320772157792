import { merge } from "lodash";
import {
  RECEIVE_BLOG_POST,
  RECEIVE_BLOG_SAMPLES
} from "../actions/blog_actions";

const defaultState = { samples: [], blogPost: {} };

const blogPostsReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_BLOG_POST:
      return merge({}, oldState, { blogPost: action.blogPost });
    case RECEIVE_BLOG_SAMPLES:
      return merge({}, oldState, {
        samples: action.samples.posts,
        allPosts: action.samples.allPosts
      });
    default:
      return oldState;
  }
};

export default blogPostsReducer;
