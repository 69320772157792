import React from "react";
import { Helmet } from "react-helmet";

const NewMexicoRates = ({ products }) => {
  products = products || {
    loans: {
      traditional_loan: {
        "300": { 6: { pmt: 78.56, apr: 1.76 } },
        "350": { 6: { pmt: 91.65, apr: 1.76 } },
        "400": { 6: { pmt: 104.75, apr: 1.76 } },
        "450": { 6: { pmt: 117.84, apr: 1.76 } },
        "500": { 6: { pmt: 130.93, apr: 1.76 } }
      }
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>New Mexico Rates and Notices | Fig Loans</title>
        <meta
          name="description"
          content="Do you need a loan in New Mexico? Fig Loans has the friendliest terms in the state!"
        />
        <meta
          property="og:description"
          content="Do you need a loan in New Mexico? Fig Loans has the friendliest terms in the state!"
        />
      </Helmet>
      <div className="panel-grey">
        <div id="new-mexico">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className='modal-heading'>New Mexico</h4>
              </div>

              <b>Fee Examples</b>
              <p>
                Loan costs depend on the amount advanced and length of the loan.
              </p>
              <p>
                <div className="disclosurewrap">
                  <div className="full-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Amount Advanced</th>
                          <td>$100.00</td>
                          <td>$400.00</td>
                          <td>$400.00</td>
                        </tr>
                        <tr>
                          <th>Loan Length</th>
                          <td>120 days</td>
                          <td>120 days</td>
                          <td>180 days</td>
                        </tr>
                        <tr>
                          <th>
                            Loan APR<sup>1</sup>
                          </th>
                          <td>175%</td>
                          <td>175%</td>
                          <td>175%</td>
                        </tr>
                        <tr>
                          <th>Interest Charge</th>
                          <td>$38.92</td>
                          <td>$155.70</td>
                          <td>$225.08</td>
                        </tr>
                        <tr>
                          <th>Total Repayment Amount</th>
                          <td>$138.92</td>
                          <td>$555.70</td>
                          <td>$625.08</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mobile-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Sample $100 Dollar 13 Day Loan</th>
                          <td>
                            <b>Amount Advanced:</b> $100.00
                          </td>
                          <td>
                            <b>Loan Length:</b> 13 days
                          </td>
                          <td>
                            <b>
                              Loan APR<sup>1</sup>:
                            </b>{" "}
                            176%
                          </td>
                          <td>
                            <b>Interest Charge:</b> $6.27
                          </td>
                          <td>
                            <b>Total Repayment Amount:</b>
                            <br />
                            $106.27
                          </td>
                        </tr>
                        <tr>
                          <th>Sample $400 Dollar 30 Day Loan</th>
                          <td>
                            <b>Amount Advanced:</b> $400.00
                          </td>
                          <td>
                            <b>Loan Length:</b> 30 days
                          </td>
                          <td>
                            <b>
                              Loan APR<sup>1</sup>:
                            </b>{" "}
                            176%
                          </td>
                          <td>
                            <b>Interest Charge:</b> $57.86
                          </td>
                          <td>
                            <b>Total Repayment Amount:</b>
                            <br /> $457.86
                          </td>
                        </tr>
                        <tr>
                          <th>Sample $400 Dollar 180 Day Loan</th>
                          <td>
                            <b>Amount Advanced:</b> $400.00
                          </td>
                          <td>
                            <b>Loan Length:</b> 180 days
                          </td>
                          <td>
                            <b>
                              Loan APR<sup>1</sup>:
                            </b>{" "}
                            176%
                          </td>
                          <td>
                            <b>Interest Charge:</b> $225.08
                          </td>
                          <td>
                            <b>Total Repayment Amount:</b>
                            <br /> $625.08
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </p>
              <p className="footnote">
                1. The APR is based on a six payment, 180 day amortizing loan.
                The APR shown is based on the federal Truth in Lending Act and
                does not reflect specialized state APR calculations, if any.<br />
              </p>
              <p>
                The loan information shown here is an example and may not
                reflect the rates available on all loans. Before entering into
                any transaction, you should read the disclosure statements and
                contract that are provided to you by Fig Loans.
              </p>

              <p>
                {" "}
                <u> Optional Fees </u>{" "}
              </p>
              <p>
                {" "}
                You have the option of paying with a debit card. If you make a
                payment with a debit card, you will not be charged aprocessing
                fee.
              </p>
              <p>
                <b>Notices</b>
              </p>
              <p>
                The maximum Annual Percentage Rate Fig Loans will charge on an
                installment loan is 175.00%. This lender is regulated by
                New Mexico Regulation and Licensing Department, Financial Institutions Division, P.O. BOX 25101, 2550 Cerrillos Road, Santa Fe, New Mexico 87504.
                To report any unresolved problems or complaints, contact the division by telephone
                number (5050) 476-4885 or visit the website, http://www.rld.state.nm.us/financialinstitutions
              </p>
              <p>
                For help understanding this credit transaction, please refer to this Brochure complete with common
                terms and definitions:
              </p>
              <p>
                <ul>
                  <li><a href="https://figloans-static-images.s3.us-west-2.amazonaws.com/disclosures/NM_Brochure_12.18.3.pdf" target="_blank" rel="noopener noreferrer">Important Consumer Information</a></li>
                  <li><a href="https://figloans-static-images.s3.us-west-2.amazonaws.com/disclosures/Rates_Disclosures_24_PT.pdf" target="_blank" rel="noopener noreferrer">Loan Rates and Fees Disclosures</a></li>
                </ul>
              </p>
              <p>
                <b>LICENSES</b>
              </p>
              <p>
                Fig Loans is a lender licensed by the State of New Mexico
                Department of Financial & Professional Regulation.
              </p>
              <p>
                <img
                  src="https://figloans-static-images.s3.us-west-2.amazonaws.com/licenses/new_mexico_license_2023.jpg"
                  alt="Fig Lending License"
                  className="img-responsive faq-image"
                  style={{ clip: "rect(0px, 100px, 1000px, 100px)" }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMexicoRates;
