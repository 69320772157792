/* eslint-disable */
import React, { Component } from "react";
import PropTypes from "prop-types";
import About from "./About";
import Info from "./Info";
import { Link } from "react-router-dom";
import { domain } from "../helpers/route_helper";

export default class NavBar extends Component {
  static propTypes = {
    changeLoading: PropTypes.func.isRequired,
    logout: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      mobileMenu: false,
      about: false,
      info: false,
      logo: this.correctLogo(props),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      let logo = this.correctLogo();
      if (logo !== this.state.logo) {
        this.setState({ logo });
      }
    }
  }

  correctLogo = (props) => {
    props = props || this.props;
    if (props.location.pathname.includes("/community-loans")) {
      return "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/fig_36_navbar.png";
    } else {
      return "https://figloans-static-images.s3.us-west-2.amazonaws.com/logos/figlogo_lg_2020.png";
    }
  };

  handleSignOut = () => {
    this.props.changeLoading(true);
    this.props.logout();
  };

  handleNavigate = () => {
    this.setState({ about: false, info: false, mobileMenu: false });
  };

  render() {
    let SessionButton;

    if (this.props.loggedIn) {
      SessionButton = (
        <li key={5} className="desktop" onClick={this.handleSignOut}>
          SIGN OUT
        </li>
      );
    } else {
      SessionButton = (
        <li
          key={5}
          className="desktop"
          onClick={() => this.props.history.push("/signin")}
        >
          SIGN IN
        </li>
      );
    }

    return (
      <div className="content-container">
        <div className="content navbar-top">
          <div className="group">
            <div id="navbar-mobile-toggle">
              <div className="icons">
                <span
                  id="toggle-normal"
                  onClick={() =>
                    this.setState({
                      mobileMenu: !this.state.mobileMenu,
                    })
                  }
                  data-icon={this.state.mobileMenu ? "\uE056" : "\u0062"}
                />
              </div>
            </div>
            <Link to="/">
              <img
                src={this.state.logo}
                className="logo desktop"
                alt="Fig Tech, Inc"
              />
            </Link>
            <nav className="nav-main">
              <ul>
                {this.props.loggedIn && this.props.userInfo.first_name
                  ? [
                      <li key={1} className="desktop">
                        <Link to="/me" onClick={this.handleNavigate}>
                          My Account
                        </Link>
                      </li>,
                      <li key={3} className="desktop">
                        <Link to="/help" onClick={this.handleNavigate}>
                          Help
                        </Link>
                      </li>,
                      <li key={4} className="desktop">
                        <Link to="/" onClick={this.handleSignOut}>
                          Sign Out
                        </Link>
                      </li>,
                    ]
                  : [
                      <li className="desktop" key={0}>
                        <span
                          onClick={() =>
                            this.setState({
                              about: !this.state.about,
                              info: false,
                            })
                          }
                          id="about_link"
                          className={this.state.about ? "selected" : ""}
                          href="#"
                        >
                          ABOUT
                        </span>
                      </li>,
                      <li className="desktop" key={1}>
                        <a
                          href="https://support.figloans.com/hc/en-us"
                          target="_blank"
                        >
                          HELP CENTER
                        </a>
                      </li>,
                      <li className="desktop" key={2}>
                        <Link
                          onClick={() => this.setState({ mobileMenu: false })}
                          to="/credit-builder"
                        >
                          CREDIT BUILDER
                        </Link>
                      </li>,
                      <li className="desktop" key={3}>
                        <Link to="/blog" onClick={this.handleNavigate}>
                          BLOG
                        </Link>
                      </li>,
                      SessionButton,
                    ]}

                <li key={6}>
                  {this.props.location.pathname == "/credit-builder" || this.props.location.pathname.includes("community-loans") ? null : (
                    <Link id="apply_link" to="/app">
                      <p className="button">APPLY</p>
                    </Link>
                  )}
                </li>
              </ul>
              <div className="clear" />

              {this.state.about ? (
                <div className="desktop">
                  <div id="subbar">
                    <div className="group" id="about_subbar">
                      <ul>
                        <About handleNavigate={this.handleNavigate} />
                        <span className="stretch" />
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {this.state.info ? (
                <div className="desktop">
                  <div id="subbar">
                    <div className="group" id="info_subbar">
                      <ul>
                        <Info
                          site={this.props.site}
                          handleNavigate={this.handleNavigate}
                        />
                        <span className="stretch" />
                      </ul>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </nav>
          </div>
        </div>
        {this.state.mobileMenu ? (
          <nav className="nav-mobile">
            <ul>
              <Link
                onClick={() => this.setState({ mobileMenu: false })}
                id="root"
                to="/me"
              >
                <li>My account</li>
              </Link>
              <hr />
              <span
                onClick={() => this.setState({ about: true, info: false })}
                id="about_link"
                href="#"
              >
                <li className={this.state.about ? "selected" : ""}>ABOUT</li>
              </span>

              {this.state.about ? (
                <About handleNavigate={this.handleNavigate} />
              ) : (
                ""
              )}

              <hr />
              <a href="https://support.figloans.com/hc/en-us" target="_blank">
                <li>Help Center</li>
              </a>
              <hr />
              <Link
                onClick={() => this.setState({ mobileMenu: false })}
                to="/blog"
              >
                <li>BLOG</li>
              </Link>
              <hr />
              <Link
                onClick={() => this.setState({ mobileMenu: false })}
                to="/credit-builder"
              >
                <li>CREDIT BUILDER</li>
              </Link>
              <hr />
              <Link
                onClick={() => this.setState({ mobileMenu: false })}
                to="/what-is-fig"
              >
                <li>WHAT IS FIG</li>
              </Link>
              <hr />
              {this.props.loggedIn ? (
                <Link
                  onClick={() => {
                    this.handleSignOut();
                    this.setState({ mobileMenu: false });
                  }}
                  id="root"
                  to="/"
                >
                  <li>SIGN OUT</li>
                </Link>
              ) : (
                <Link
                  onClick={() => {
                    this.setState({ mobileMenu: false });
                  }}
                  to="/signin"
                >
                  <li>SIGN IN</li>
                </Link>
              )}
            </ul>
          </nav>
        ) : (
          ""
        )}
      </div>
    );
  }
}
