import React from "react";
import { Helmet } from "react-helmet";

const MissouriRates = ({ products }) => {
  products = products || {
    loans: {
      traditional_loan: {
        "300": { 4: { pmt: 106.86, apr: 1.9 } },
        "350": { 4: { pmt: 124.67, apr: 1.9 } },
        "400": { 4: { pmt: 142.47, apr: 1.9 } },
        "450": { 4: { pmt: 160.28, apr: 1.9 } },
        "500": { 4: { pmt: 178.09, apr: 1.9 } }
      }
    }
  };
  const length1 = Object.keys(products.loans.traditional_loan["300"])[0];
  const length2 = Object.keys(products.loans.traditional_loan["400"])[0];
  const length3 = Object.keys(products.loans.traditional_loan["500"])[0];

  const apr1 = products.loans.traditional_loan["300"][length1].apr;
  const apr2 = products.loans.traditional_loan["400"][length2].apr;
  const apr3 = products.loans.traditional_loan["500"][length3].apr;

  const payment1 = products.loans.traditional_loan["300"][length1].pmt;
  const payment2 = products.loans.traditional_loan["400"][length2].pmt;
  const payment3 = products.loans.traditional_loan["500"][length3].pmt;

  return (
    <div className="container">
      <Helmet>
        <title>Missouri Rates and Notices | Fig Loans</title>
        <meta
          name="description"
          content="Do you need a loan in Missouri? Fig Loans has the friendliest terms in the state!"
        />
        <meta
          property="og:description"
          content="Do you need a loan in Missouri? Fig Loans has the friendliest terms in the state!"
        />
      </Helmet>
      <div className="panel-grey">
        <div id="missouri">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className='modal-heading'>Missouri</h4>
              </div>
              <p>
                Loan costs depend on the amount advanced and length of the loan.  We do not currently charge an origination fee.  Our maximum APR is currently 279%.
              </p>
              <p>
                <b>Fee Examples</b>
              </p>
              <p>
                <div className="disclosurewrap">
                  <div className="full-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Amount Advanced</th>
                          <td>$300.00</td>
                          <td>$400.00</td>
                          <td>$500.00</td>
                        </tr>
                        <tr>
                          <th>Loan Length</th>
                          <td>{length1 * 30} days</td>
                          <td>{length2 * 30} days</td>
                          <td>{length3 * 30} days</td>
                        </tr>
                        <tr>
                          <th>
                            Loan APR<sup>1</sup>
                          </th>
                          <td>{apr1 * 100}%</td>
                          <td>{apr2 * 100}%</td>
                          <td>{apr3 * 100}%</td>
                        </tr>
                        <tr>
                          <th>Interest Charge</th>
                          <td>
                            ${(
                              Math.round(length1 * payment1 * 100) / 100 -
                              300
                            ).toFixed(2)}
                          </td>
                          <td>
                            ${(
                              Math.round(length2 * payment2 * 100) / 100 -
                              400
                            ).toFixed(2)}
                          </td>
                          <td>
                            ${(
                              Math.round(length3 * payment3 * 100) / 100 -
                              500
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th>Total Repayment Amount</th>
                          <td>
                            ${(
                              Math.round(length1 * payment1 * 100) / 100
                            ).toFixed(2)}
                          </td>
                          <td>
                            ${(
                              Math.round(length2 * payment2 * 100) / 100
                            ).toFixed(2)}
                          </td>
                          <td>
                            ${(
                              Math.round(length3 * payment3 * 100) / 100
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mobile-size">
                    <table>
                      <tr>
                        <th>Sample $300 Dollar {length1} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $300.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length1 * 30} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>
                          {apr1 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> $
                          {(
                            Math.round(length1 * payment1 * 100) / 100 -
                            300
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />${(
                            Math.round(length1 * payment1 * 100) / 100
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $400 Dollar {length2} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $400.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length2 * 30} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          {apr2 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> ${(
                            Math.round(length2 * payment2 * 100) / 100 -
                            400
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br /> ${(
                            Math.round(length2 * payment2 * 100) / 100
                          ).toFixed(2)}
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $500 Dollar {length3} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $500.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length3 * 30} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          {apr3 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> $
                          {(
                            Math.round(length3 * payment3 * 100) / 100 -
                            500
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br /> ${(
                            Math.round(length3 * payment3 * 100) / 100
                          ).toFixed(2)}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </p>
              <p className="footnote">
                1. The APR is based on a {length1} payment, {length1 * 30} day
                amortizing loan. The APR shown is based on the federal Truth in
                Lending Act and does not reflect specialized state APR
                calculations, if any.<br />
              </p>
              <p>
                The loan information shown here is an example and may not
                reflect the rates available on all loans. Before entering into
                any transaction, you should read the disclosure statements and
                contract that are provided to you by Fig Loans.
              </p>

              <p>
                {" "}
                <u> Optional Fees </u>{" "}
              </p>
              <p>
                {" "}
                You have the option of paying with a debit card. If you make a
                payment with a debit card, you will be charged a 3% processing
                fee that will be automatically added to your total payment.{" "}
              </p>
              <p>
                <b>Notices</b>
              </p>
              <p>
                This lender is regulated by
                Missouri Division of Finance. In the event of a complaint or
                concern, Missouri residents may contact the:<br />
                <br />
                Missouri Division of Finance<br />
                P.O. Box 716<br />
                Jefferson City, MO, 65102-0716<br />
                (573) 751-3463.
              </p>
              <p>
                <b>LICENSES</b>
              </p>
              <p>
                Fig Loans is a lender licensed by the State of Missouri Division
                of Finance.
              </p>
              <p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/licenses/2023/missouri_license.png"
                  alt="CAB License"
                  className="img-responsive faq-image"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissouriRates;
