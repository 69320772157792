import { merge } from "lodash";
import {
  RECEIVE_STATEMENT_ERRORS,
  CLEAR_ERRORS
} from "../actions/error_actions";

const defaultState = [];

const statementErrorsReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_STATEMENT_ERRORS:
      if (action.errors && action.errors.responseJSON) {        
        return merge({}, oldState, action.errors.responseJSON.errors);
      } else {
        return merge({}, oldState, action.errors.errors);
      }
    case CLEAR_ERRORS:
      return {};
    default:
      return oldState;
  }
};

export default statementErrorsReducer;
