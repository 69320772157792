import { connect } from "react-redux";
import { setApplication } from "../actions/application_actions";
import { getProducts, setState } from "../actions/info_actions";
import { changeLoading } from "../actions/loader_actions";
import LoanSelector from "./LoanSelector";

const mapStateToProps = state => ({
  application: state.entities.application,
  products: state.info.products,
  userInfo: state.session.currentUser || {},
  state: state.info.location,
  source: state.entities.utm.source,
  loading: state.ui.loading,
  utm: state.entities.utm,
  sessionId: state.session.id
});

const mapDispatchToProps = dispatch => ({
  setApplication: application => dispatch(setApplication(application)),
  getProducts: (state, user_id, source, partner_id) =>
    dispatch(getProducts(state, user_id, source, partner_id)),
  changeLoading: loadingState => dispatch(changeLoading(loadingState)),
  setLocation: state => dispatch(setState(state))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoanSelector);
