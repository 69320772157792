import React from "react";
import CAB from "../../disclosures/CAB";
import { domain } from "../../../helpers/route_helper";

const TexasRates = ({ products }) => {
  products = products || {
    loans: {
      traditional_loan: {
        "300": { 4: { pmt: 106.86, apr: 1.9 } },
        "350": { 4: { pmt: 124.67, apr: 1.9 } },
        "400": { 4: { pmt: 142.47, apr: 1.9 } },
        "450": { 4: { pmt: 160.28, apr: 1.9 } },
        "500": { 4: { pmt: 178.09, apr: 1.9 } }
      }
    }
  };

  const length1 = Object.keys(products.loans.traditional_loan["300"])[0];
  const length2 = Object.keys(products.loans.traditional_loan["400"])[0];
  const length3 = Object.keys(products.loans.traditional_loan["500"])[0];

  const apr1 = products.loans.traditional_loan["300"][length1].apr;
  const apr2 = products.loans.traditional_loan["400"][length2].apr;
  const apr3 = products.loans.traditional_loan["500"][length3].apr;

  return (
    <div className="container">
      <div className="panel-grey">
        <div id="texas">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className='modal-heading'>Texas</h4>
              </div>
              <p>
                <b>ABOUT PAYDAY LOANS</b>
              </p>
              <p>
                An advance of money obtained through a payday loan or auto title
                loan is not intended to meet long-term financial needs.  A
                payday loan or auto title loan should only be used to meet
                immediate short-term cash needs.  Refinancing the loan rather
                than paying the debt in full when due will require the payment
                of additional charges. If you have any additional concerns,
                please reach out to:&nbsp;
                <a
                  href="http://occc.texas.gov/consumers/complaint-resolution"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="disclosure_popup"
                >
                  OCCC Consumer Complaints
                </a>
              </p>
              <p>
                <b>TEXAS OCCC NOTICE & FEE SCHEDULE</b>
              </p>
              <p>
                The loan agreement is governed by the applicable laws of the
                state of Texas.
              </p>
              <p>
                <b>TEXAS OCCC NOTICE:</b>
              </p>
              <p>
                An advance of money obtained through a payday loan is not
                intended to meet long-term financial needs. A payday loan should
                only be used to meet immediate short-term cash needs.
                Refinancing the loan rather than paying the debt in full when
                due will require the payment of additional charges.
              </p>
              <p>
                CONSUMER CREDIT NOTICE:&nbsp;This business is licensed and
                examined by the State of Texas-Office of Consumer Credit
                Commissioner. Call the Consumer Credit Hotline or write for
                credit information or assistance with credit problems.
              </p>
              <p>
                OFFICE OF CONSUMER CREDIT COMMISSIONER:&nbsp;2601 North Lamar
                Boulevard, Austin, Texas 78705-4207, (800) 538-1579,
                consumer.complaints@occc.state.tx.us,&nbsp;{" "}
                <a className="rate_a" href="http://www.occc.state.tx.us/">
                  www.occc.state.tx.us
                </a>
              </p>
              <ul>
                <li>
                  <a
                    href={`${domain}/cso_multi_payday.pdf?apr=${apr1}&cash_advanced=300&installment_num=4`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Texas OCCC Consumer Disclosure: Payday Loan Multipayment
                    $300 4-Month example
                  </a>
                </li>
                <li>
                  <a
                    href={`${domain}/cso_multi_payday.pdf?apr=${apr2}&cash_advanced=500&installment_num=4`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Texas OCCC Consumer Disclosure: Payday Loan Multipayment
                    $500 4-Month example
                  </a>
                </li>
                <li>
                  <a
                    href={`${domain}/cso_multi_payday.pdf?apr=${apr3}&cash_advanced=500&installment_num=6`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Texas OCCC Consumer Disclosure: Payday Loan Multipayment
                    $500 6-Month example
                  </a>
                </li>
              </ul>

              <p>
                <b>LICENSES</b>
              </p>
              <p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/licenses/342_license.jpg"
                  alt="Lending License"
                  className="img-responsive faq-image"
                />
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/licenses/fig_license-1.png"
                  alt="CAB License"
                  className="img-responsive faq-image"
                />
              </p>

              <p>
                <b>CREDIT ACCESS BUSINESS DISCLOSURE:</b>
              </p>
              <CAB />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TexasRates;
