import React from "react";

const HomePress = () => {
  return (
    <section className="press-container">
      <div className="press-title">As featured on</div>
      <div className="logos-container">
        <div className="logos-row">
          <a
            href="https://www.badcredit.org/news/fig-loans-offers-a-payday-loan-alternative-with-lower-interest-rates/"
            target="_blank"
            rel="noopener noreferrer"
            className="featured-logo"
          >
            <img
              src="https://www.badcredit.org/wp-content/uploads/2019/01/horizontal-for-light.png"
              alt="Bad Credit"
              className="featured-logo"
            />
          </a>
          <a
            href="https://www.nerdwallet.com/blog/loans/payday-loans-are-dying-dont-cheer-too-loud/"
            target="_blank"
            rel="noopener noreferrer"
            className="featured-logo"
          >
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/ac/Nerdwallet_Horizontal_Logo.svg/1280px-Nerdwallet_Horizontal_Logo.svg.png"
              alt="Nerd Wallet"
              className="featured-logo"
            />
          </a>
        </div>
        <div className="logos-row">
          <a
            href="https://www.cardrates.com/news/credit-builder-by-fig-loans-helps-people-improve-their-credit/"
            target="_blank"
            rel="noopener noreferrer"
            className="featured-logo"
          >
            <img
              src="https://i.imgur.com/b93UUmv.png"
              alt="Card Rates"
              className="featured-logo"
            />
          </a>
          <a href="" className="featured-logo">
            <img
              src="https://figloans-static-images.s3.us-west-2.amazonaws.com/home/press/newsweek_logo.png"
              alt="Newsweek"
              className="featured-logo"
            />
          </a>
          <a
            href="https://www.nytimes.com/2018/05/24/business/inventing-new-ways-to-solve-old-problems.html"
            target="_blank"
            rel="noopener noreferrer"
            className="featured-logo"
          >
            <img
              src="https://figloans-static-images.s3.us-west-2.amazonaws.com/reviews/nyt.png"
              alt="The New York Times"
              className="featured-logo"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default HomePress;
