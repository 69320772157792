import { domain } from "../helpers/route_helper";
import {readCookie} from "../helpers/cookie_helper";

export const ensureApiTokenPresence = (args) => {
  return {
    ...args,
    ...{token: readCookie('apiToken')}
  }
};

export const createUrlParamsForArgs = (args) => {
  let keyValParams = Object.keys(args).map((k) => {
    return `${k}=${args[k]}`;
  });
  return keyValParams.join('&');
}

export const asyncFetchPostRequest = async (url, args = {}) => {
  const response = await fetch(`${domain}/${url}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(ensureApiTokenPresence(args)),
  });
  return await response.json();
};

export const asyncFetchPatchRequest = async (url, args = {}) => {
  const response = await fetch(`${domain}/${url}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(ensureApiTokenPresence(args)),
  });
  return await response.json();
};

export const  asyncFetchGetRequest = async (url, args = {}) => {
  let urlParams = createUrlParamsForArgs(ensureApiTokenPresence(args));
  const response = await fetch(`${domain}/${url}?${urlParams}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json'
    }
  });
  return await response.json();
};