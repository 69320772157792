import React from "react";
import { Link } from "react-router-dom";

const Payments = () => {
  return (
    <div className="row">
      <div className="glance">
        <h4 id="payments">3. Can I make the payments?</h4>
        <p>
          This is the #1 question you need ask when looking at a loan’s payment
          schedule. The information below will help you understand the two types
          of payments and how each affects the size of your payments. BUT only
          you know how much you can comfortably pay each week, 2-weeks or month.
        </p>
        <p>
          There are two types of payday loans:{" "}
          <Link to="/loan-information/loan-terms-dictionary">balloon</Link> and{" "}
          <Link to="/loan-information/loan-terms-dictionary">installment</Link>.
          The two loan types have different payments, each named after the loan
          type.
        </p>
        <p>
          A <Link to="/loan-information/loan-terms-dictionary">balloon</Link>{" "}
          loan is paid off with one large payment at the end of the length of
          the loan, (aka balloon payment). For this type of loan, you pay back
          all of the borrowed money and cost of the loan at once. Most payday
          loans are balloon loans and you need a lot of extra money to pay them
          back on time!
        </p>
        <p>
          An{" "}
          <Link to="/loan-information/loan-terms-dictionary">installment</Link>{" "}
          loan that is repaid by in portions over time (aka installment
          payments). These loans are often repaid in equal monthly payments that
          include interest and a part of the originally borrowed amount. Since
          you will be making multiple payments for an installment loan, it is
          important to consider if your budget allows you to make these regular
          payments for the entire length of your loan!
        </p>
        <p>
          Now {"let's"} look at the payments for some sample online payday
          loans.
        </p>
        <div id="pmt_des_table">
          <div className="clear" />
          <div className="image_header">
            <img
              src="https://s3.us-west-2.amazonaws.com/figloans-static-images/peers/freedom_cash.png"
              alt="Payday Lender"
            />
          </div>

          <p className="example_title">
            <b>Payments by length and loan type for $100 Loan at a 680% APR</b>
          </p>

          <table cellSpacing="0" cellPadding="0" border="0">
            <tbody>
              <tr>
                <td className="header">Loan</td>
                <td className="header">Length of Loan</td>
                <td className="header mobile_hidden">Loan Type</td>
                <td className="header">Payment Freq</td>
                <td className="header">Amount per Payment</td>
                <td className="header">Total Paid Back</td>
              </tr>
              <tr>
                <td>A</td>
                <td>2 weeks</td>
                <td className="mobile_hidden">Balloon</td>
                <td>1, due end of loan</td>
                <td>$126.15</td>
                <td>$126.15</td>
              </tr>
              <tr>
                <td>B</td>
                <td>1 month</td>
                <td className="mobile_hidden">Balloon</td>
                <td>1, due end of loan</td>
                <td>$156.67</td>
                <td>$156.67</td>
              </tr>
              <tr>
                <td>C</td>
                <td>4 months</td>
                <td className="mobile_hidden">Installment</td>
                <td>8, biweekly</td>
                <td>$30.98</td>
                <td>$247.87</td>
              </tr>
              <tr>
                <td>D</td>
                <td>4 months</td>
                <td className="mobile_hidden">Installment</td>
                <td>4, monthly</td>
                <td>$67.95</td>
                <td>$271.78</td>
              </tr>
            </tbody>
          </table>
          <p className="disclaimer">
            1. This example uses hypothetical information and is only for
            illustrative purposes.
          </p>
        </div>
        <p>
          Looking at Payday Lender A, the balloon payment for this loan
          ($126.15) is due in 14 days! If we’re borrowing $100 today, are we
          sure we will have enough money in two weeks to pay back the entire
          $126.91 due? Keep in mind that this is just for $100… most payday
          lenders only allow loans of $200 or more. Will you have an extra
          $252.30 to pay off a $200 loan 14 days later?
        </p>
        <p>
          Looking at Lender D, paying 4 payments of $67.95 for a $100 loan seems
          like a lot. We could probably pay this off sooner. Paying early would
          lower the length of the loan and save money!
        </p>
        <p>
          Lastly, if we compare Lenders C and D, we see that the payday loan
          offered by Lender C is better because it allows you to make smaller,
          more frequent payments. These earlier payments reduce the amount
          borrowed sooner which saves you money in interest!
        </p>
        <div className="clear" />
      </div>
    </div>
  );
};

export default Payments;
