// helper file to translate active_loan_cycles defined by state.rb on Monolith

export const LoanCycle = Object.freeze({
  // INACTIVE => the user does not have an active loan
  INACTIVE: [
    "ab00", // Email not found
    "ab01", // NOT CURRENTLY IN USE
    "ab02" // User has no active loans
  ],
  // ACTIVE => the user has a loan that's been funded but not paid off or forgiven for some other reason (e.g. bankruptcy)
  ACTIVE: [
    "ab03", // Accepted loan, just funded today or yesterday
    "ab04", // Accepted loan, funded more than 1 day ago
    "ab05", // Active loan, processing payment due today or next business day
    "ab06", // Active loan, processing payment due today or next business day, eligible for new application
    "ab07", // Active loan, refi eligible
    "ab08" // Active loan, refi not eligible
  ]
});
