const BaseRoutes = {
  ACCOUNT_HOME: "/me",
  PAYMENT_METHODS: "/accounts",
  PAYMENT_SELECTION: "/payment",
  CA_ASSIGNED_TO_TA: "/assigned-to-trueaccord"
};

const PaymentMethodsSubRoutes = {
  NEW_BANK: BaseRoutes.PAYMENT_METHODS + "/new-bank",
  NEW_DEBIT: BaseRoutes.PAYMENT_METHODS + "/new-debit"
};

export const PageRoute = Object.freeze({
  ...BaseRoutes,
  ...PaymentMethodsSubRoutes
});