import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { logout } from "../actions/session_actions";
import { changeLoading } from "../actions/loader_actions";
import NavBar from "./NavBar";

const mapStateToProps = state => ({
  loggedIn: Boolean(state.session.currentUser),
  userInfo: state.session.currentUser,
  site: state.info.location
});

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  changeLoading: loadingState => dispatch(changeLoading(loadingState))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavBar));
