import React from "react";
import EmailUnsubscribe from "./EmailUnsubscribe";

const CollectionEmailUnsubscribe = () => {
  return (
    <EmailUnsubscribe
      unsubscribeUrl='/emails/unsubscribe_collection'
      successText="You've successfully unsubscribed from all email communications regarding your loan with Fig."
      />
  )
}

export default CollectionEmailUnsubscribe