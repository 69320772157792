import React, { useEffect, useState } from "react";

import ReactStars from "react-rating-stars-component";

const StarRating = ({ value, size }) => {
  const [rating, setRating] = useState(0);

  useEffect(() => {
    setInterval(increaseRating(rating), 200);
  }, []);

  const increaseRating = (rating) => {
    console.log("hit");
    if (rating < value) {
      setRating((s) => s + 0.5);
    } else if (rating >= value) {
      clearInterval(increaseRating(rating), 200);
    }
  };

  return (
    <ReactStars
      count={5}
      size={size}
      activeColor="#ffd700"
      value={value}
      isHalf={true}
    />
  );
};

export default StarRating;
