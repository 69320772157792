import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const CAB = () => (
  <div>
    <Helmet>
      <title>Fig Loans Texas LLC CAB License | Fig Loans</title>
      <link rel="canonical" href="https://www.figloans.com/cab" />
      <meta
        name="description"
        content="Fig Loans Texas LLC is a Credit Access Business registered with the State of Texas Consumer Credit Division."
      />
      <meta
        property="og:description"
        content="Fig Loans Texas LLC is a Credit Access Business registered with the State of Texas Consumer Credit Division."
      />
    </Helmet>
    <div className="disclosurewrap">
      <h4 className="disclosure">Credit Access Business Disclosure</h4>
      <p>
        Fig Loans Texas, LLC will not be the lender for loans received at this
        site. Fig Loans Texas, LLC is a Credit Access Business. We will attempt
        to arrange a loan between you and a third party lender, and service that
        loan once it is issued. Any questions, service issues or complaints
        about your loan will be addressed by Fig Loans Texas, LLC and not the
        lender.
        <br />
        <br />
        Your Credit Access Business ("CAB") fee average over the term of your
        loan will be $10.10 per $100.00 borrowed each month (see examples below
        for total effective Loan APR<sup>1</sup>). Your specific CAB fee will be
        calculated based on the outstanding loan principal each month. You will
        also be charged interest by your lender of 10% APR on the loan
        principal.
        <br />
        <br />
        For all loans, your CAB fees will not be reduced if you are delinquent
        in any loan or CAB fee payments. You will be required to pay the
        applicable, unreduced CAB fee each month until you become current on
        your obligations to repay the loan and your obligations to pay CAB fees.
        <br />
        <br />
        An example of our fees:
      </p>
      <div className="disclosurewrap">
        <div className="full-size">
          <table>
            <tbody>
              <tr>
                <th>Amount Advanced</th>
                <td>$300.00</td>
                <td>$400.00</td>
                <td>$500.00</td>
              </tr>
              <tr>
                <th>Loan Length</th>
                <td>120 days</td>
                <td>120 days</td>
                <td>120 days</td>
              </tr>
              <tr>
                <th>
                  Loan APR<sup>2</sup>
                </th>
                <td>190%</td>
                <td>190%</td>
                <td>190%</td>
              </tr>
              <tr>
                <th>CAB fees</th>
                <td>$121.19</td>
                <td>$161.55</td>
                <td>$201.94</td>
              </tr>
              <tr>
                <th>Interest Charge</th>
                <td>$6.25</td>
                <td>$8.33</td>
                <td>$10.42</td>
              </tr>

              <tr>
                <th>Total Repayment Amount</th>
                <td>$427.44</td>
                <td>$569.88</td>
                <td>$712.36</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="mobile-size">
          <table>
            <tbody>
              <tr>
                <th>Sample $300 Dollar 4 Month Loan</th>
                <td>
                  <b>Amount Advanced:</b> $300.00
                </td>
                <td>
                  <b>Loan Length:</b> 120 days
                </td>
                <td>
                  <b>
                    Loan APR<sup>2</sup>:
                  </b>{" "}
                  190%
                </td>
                <td>
                  <b>CAB fees:</b> $121.19
                </td>
                <td>
                  <b>Interest Charge:</b> $6.25
                </td>
                <td>
                  <b>Total Repayment Amount:</b>
                  <br />
                  <br />
                  $427.44
                </td>
              </tr>
              <tr>
                <th>Sample $400 Dollar 4 Month Loan</th>
                <td>
                  <b>Amount Advanced:</b> $400.00
                </td>
                <td>
                  <b>Loan Length:</b> 120 days
                </td>
                <td>
                  <b>
                    Loan APR<sup>2</sup>:
                  </b>{" "}
                  190%
                </td>
                <td>
                  <b>CAB fees:</b> $161.55
                </td>
                <td>
                  <b>Interest Charge:</b> $8.33
                </td>
                <td>
                  <b>Total Repayment Amount:</b>
                  <br />
                  <br /> $569.88
                </td>
              </tr>
              <tr>
                <th>Sample $500 Dollar 4 Month Loan</th>
                <td>
                  <b>Amount Advanced:</b> $500.00
                </td>
                <td>
                  <b>Loan Length:</b> 120 days
                </td>
                <td>
                  <b>
                    Loan APR<sup>2</sup>:
                  </b>{" "}
                  190%
                </td>
                <td>
                  <b>CAB fees:</b> $201.94
                </td>
                <td>
                  <b>Interest Charge:</b> $10.42
                </td>
                <td>
                  <b>Total Repayment Amount:</b>
                  <br />
                  <br /> $712.36
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <p className="footnote">
        2. The APR is based on a four payment, 120 day amortizing loan. The APR
        shown is based on the federal Truth in Lending Act and does not reflect
        specialized state APR calculations, if any.
        <br />
      </p>
      <p>
        {" "}
        <u> Optional Fees </u>{" "}
      </p>
      <p>
        {" "}
        You have the option of paying using a debit card. If you make a payment
        with a debit card, you will be charged a 3% processing fee that will be
        automatically added to your total payment.{" "}
      </p>
      <p>
        <b>{"LENDER'S"} PRIVACY POLICY</b>
        <br />
        <Link to="/privacy">
          Fig Loans Texas, LLC and Third Party Lender Privacy Policy
        </Link>
      </p>
      <p>
        <b>NOTICES</b>
      </p>
      <p>
        This business—Fig Loans Texas, LLC—is licensed and examined by the State
        of Texas - Office of Consumer Credit Commissioner. Call the Consumer
        Credit Hotline or write for credit information or assistance with credit
        problems.
        <br />
      </p>
      <p>
        If you have any additional concerns, please reach out to:{" "}
        <a
          href="https://occc.texas.gov/consumers/complaint-resolution"
          className="disclosure_popup"
          target="_blank"
          rel="noopener noreferrer"
        >
          OCCC Consumer Complaints
        </a>
      </p>
      <p>
        Office of Consumer Credit Commissioner: <br />
        2601 North Lamar Boulevard
        <br />
        Austin, Texas 78705-4207
        <br />
        <br />
        Phone: (800) 538-1579 (toll-free)
        <br />
        Email: consumer.complaints@occc.state.tx.us
        <br />
        Website: www.occc.state.tx.us
        <br />
      </p>
    </div>
  </div>
);

export default CAB;
