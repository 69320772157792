import React, { useState } from "react";
import { Link } from "react-router-dom";
import NortonSeal from "./shared/NortonSeal";
import Trustbox from "./shared/Trustbox";
import { setState } from "../actions/info_actions";
import { createCookie, eraseCookie } from "../helpers/cookie_helper";
import { stateNameToCode } from "../helpers/info_helper";

import { connect } from "react-redux";
import { Helmet } from "react-helmet";

function SeoStatePage(props) {
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  // const [state, setState] = useState(props.match.params.state);
  const state = props.match.params.state;
  const [product, setProduct] = useState("el");

  const isStateBased = [
    "texas",
    "illinois",
    "missouri",
    "ohio",
    "florida",
    "utah",
    "new-mexico",
  ].includes(
    props.match.params.state
      ? props.match.params.state.toLowerCase()
      : "random-string"
  );

  const stateName = state
    ? state
        .split("-")
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join(" ")
    : null;
  const handleSubmit = () => {
    const stateCode = stateNameToCode(stateName);
    const info = {
      firstName,
      lastName,
      email,
      stateCode,
    };
    eraseCookie("seo_state_page_info");
    createCookie("seo_state_page_info", JSON.stringify(info), 1);
    props.setLocation(stateCode);
    props.history.push("/app/me");
  };
  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="canonical"
          href="https://www.figloans.com/installment-loan/"
        />
        <title>
          Fig Loans | Installment Loans {stateName ? `| ${stateName}` : ""}
        </title>
      </Helmet>
      <div className="seo-state-page-container">
        <section>
          <h1>
            <b>
              Flexible {isStateBased ? `${stateName} ` : null}Installment Loans
            </b>
          </h1>
          <p>
            Monthly payments,
            <br className="mobile" /> 60% cheaper than competitors,{" "}
            <br className="mobile" />
            100% more honest
          </p>
        </section>
        <div className="panel grey">
          <div className="get-started-container">
            <h3>Get started with Fig today!</h3>
            <p>
              Already have an account? <a href="/me">Log in here!</a>
            </p>
            <input
              placeholder="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <input
              placeholder="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <input
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input disabled placeholder="State" value={stateName} />
            <div className="button primary" onClick={handleSubmit}>
              Get Started!
            </div>
            <NortonSeal />
          </div>
          <br />
          <br />

          <div className="product-nav">
            <div className="product-nav-internal">
              <h4 className="center" style={{ color: "black" }}>
                Products
              </h4>
              <div className="horizontal_list">
                <ul className="lm-products">
                  <li
                    className={
                      "lm-product " +
                      (product === "el" ? "selected" : "greyscale")
                    }
                  >
                    <img
                      src={
                        "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/product/el_color.png"
                      }
                      alt="emergency_loan_logo"
                      onClick={() => setProduct("el")}
                      style={{ width: "100%", display: "block" }}
                    />
                  </li>
                  <li
                    className={
                      "lm-product " +
                      (product === "cb" ? "selected" : "greyscale")
                    }
                  >
                    <img
                      src={
                        "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/product/cb_color.png"
                      }
                      alt="creditbuilder_loan_logo"
                      onClick={() => setProduct("cb")}
                      style={{ width: "100%", display: "block" }}
                    />
                  </li>
                  <li
                    className={
                      "lm-product " +
                      (product === "36" ? "selected" : "greyscale")
                    }
                  >
                    <img
                      src={
                        "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/product/36_color.png"
                      }
                      alt="fig36_logo"
                      onClick={() => setProduct("36")}
                      style={{ width: "100%", display: "block" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div
          className="panel-container white"
          id="el"
          style={{ display: product === "el" ? "block" : "none" }}
        >
          <div className="panel-row">
            <div>
              <div className="panel-left">
                <h5 className="center">Quick funds, no debt traps</h5>
                <p>
                  The original Fig Loan provides $50 - $800 in emergency funds to meet unexpected needs. 
                  Loan terms range from 1 to 6 months* but can be extended without any additional interest 
                  or fees thanks to our flexible repayment policy. We review applications daily and can 
                  often deposit funds within one business day of loan acceptance**. 
                </p>
                <p>
                  <b>Who should apply?</b>
                </p>
                <p>
                  Customers who have an unexpected expense (car repair, medical
                  downpayment, etc..) should consider using this product.
                  Additionally, customers who have been using traditional payday
                  or other high interest rate loans should consider refinancing
                  with our credit building service (available in Texas).
                </p>
                <p style={{ fontSize: "0.75rem"}}>
                  <em>* Loan amounts and terms vary by state of residence</em>
                  <br></br>
                  <em>** Funding times may vary and can take up to 4 business days by ACH</em>
                </p>
              </div>

              <div className="panel-right">
                <h5 className="center">Lower prices over time</h5>
                <p>
                  <b>
                    Sample APRs<sup>1</sup>
                  </b>
                </p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/apr_waterfall_2.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
                <p className="disclaimer">
                  1. Actual APRs vary depending on your repayment history and
                  additional factors.
                </p>
              </div>
              <div className="clear" />
            </div>
            <div className="center">
              <br />
              <Link
                to="/what-is-fig"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
        <div
          className="panel-container white"
          id="cb"
          style={{ display: product === "cb" ? "block" : "none" }}
        >
          <div className="panel-row">
            <div>
              <div className="panel-left">
                <h5 className="center">A proven way to build credit</h5>
                <p>
                  Originally used by credit unions and non-profits, credit
                  builders provide customers a cheaper way to build credit
                  history than a normal loan. On approval, Fig deposits the
                  credit builder funds in an escrow account for the benefit of
                  the customer. The customer makes payments as if it were a
                  normal loan, but gets the entire loan amount from the escrow
                  account back at the end of the loan. Credit builder loans are
                  larger and longer to maximize their potential benefit on
                  customer credit.
                </p>
                <p>
                  <b>Who should apply?</b>
                </p>
                <p>
                  Customers who DO NOT need funds today and are able to make
                  regular monthly payments should consider the credit builder
                  over {"Fig's"} other products. The credit builder is better
                  suited and often more effective than {"Fig's"} traditional
                  loan for reporting good credit. Please note that all payments
                  are reported to the credit bureaus, including late payments,
                  which can negatively impact a {"customer's"} credit score.
                  However, customers can prepay the loan at any time to release
                  the funds held in escrow.
                </p>
                <p>
                  <b>About the escrow funds</b>
                </p>
                <p>
                  Funds for the benefit of the customer are held at a third
                  party institution and not used for {"Fig's"} normal business
                  operations or lent to other customers. Additional information
                  regarding these funds is included in the credit builder
                  documents.
                </p>
              </div>

              <div className="panel-right">
                <h5 className="center">Cash dispersed at loan completion</h5>
                <p>
                  <b>When the creditbuilder is approved</b>
                </p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/fig_2_escrow.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
                <p>
                  Fig sends your loan funds to a 3rd party bank to hold in
                  escrow
                </p>
                <p>
                  <b>During the loan</b>
                </p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/customer_2_fig.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
                <p>Customers make loan payments to Fig</p>
                <p>
                  <b>When the creditbuilder is completed</b>
                </p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/escrow_2_customer.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
                <p>The escrow funds are released to the customer</p>
              </div>
              <div className="clear" />
            </div>
            <div className="center">
              <br />
              <Link
                to="/credit-builder"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
        <div
          className="panel-container white"
          id="36"
          style={{ display: product === "36" ? "block" : "none" }}
        >
          <div className="panel-row">
            <div>
              <div className="panel-left">
                <h5 className="center">Supercharging non-profit lending</h5>
                <p>
                  Fig36 is a partnership program between Fig and community
                  organizations (like non-profits) interested in offering
                  affordable loan programs. Fig provides our world class risk
                  models, loan management software, credit reporting, regulatory
                  compliance, and customer service so that our partners can
                  operate with the sophistication of a Wall Street bank.
                  Partners provide an unparalleled understanding of their{" "}
                  {"community's"} unique needs. Fig36 pilots are free for{" "}
                  {"Fig's"} community partners.
                </p>
                <p>
                  Fig offers this program because we believe that non-profits
                  should have the best tools available to serve their
                  communities. Inefficient technology solutions waste valuable
                  partner time that should instead be spent with clients. Fig36
                  is currently in a limited beta in Texas. We hope to expand the
                  program in 2020 to all the states that we serve!
                </p>
              </div>
              <div className="panel-right">
                <h5 className="center">Our pilot partners</h5>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/fig36_launch_partners.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
              </div>
              <div className="clear" />
            </div>
            <div className="center">
              <br />
              <Link
                to="/community-loans"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>
        <Trustbox lightDarkMode="dark" />
      </div>
    </React.Fragment>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLocation: (state) => dispatch(setState(state)),
});

const mapStateToProps = (state, props) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(SeoStatePage);
