import { merge } from "lodash";
import { RECEIVE_BLOG_ERRORS, CLEAR_ERRORS } from "../actions/error_actions";
import {
  RECEIVE_BLOG_POST,
  RECEIVE_BLOG_SAMPLES
} from "../actions/blog_actions";

const defaultState = [];

const blogErrorsReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_BLOG_ERRORS:
      return merge({}, oldState, action.errors.errors);
    case RECEIVE_BLOG_POST:
    case RECEIVE_BLOG_SAMPLES:
    case CLEAR_ERRORS:
      return {};
    default:
      return oldState;
  }
};

export default blogErrorsReducer;
