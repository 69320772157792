export const list_of_reviews = [
  {
    quote:
      "Easy, responsive. Very reasonable rates. Will contact you when payment is coming due and give you the option to reschedule your payment to a different date, by yourself and then send you a confirmation email and text. There are very few loans with low interest rates, affordable payback amounts and the option to change the due date.",
    info: "Carrie Buttrum on February 24, 2021",
    link: "https://goo.gl/maps/eJWHDtqZJM7phLGF7",
  },
  {
    quote:
      "Small loans with an amazingly simple process. Interest rates are acceptable compared to other companies. I don't have the best credit and was approved. No penalty if paid off early. Highly recommended if you are in a tight spot and need some help, this is the company to use!!",
    info: "M. Kanna Skinner on January 17, 2021",
    link: "https://goo.gl/maps/7LABh1XNL58C3Mr69",
  },
  {
    quote:
      "THE BEST FOR LOWER CREDIT FOLKS! Deposited money quick and payments were easy and affordable. Great company!",
    info: "21msberry on March 3rd, 2021",
    link: "https://goo.gl/maps/p9qujDRtsLKeCgkJ7",
  },
  {
    quote:
      "“If you need to start building credit Figloans is the place to apply! I'm going on my 3rd loan though them and my credit is looking better and better! Thanks, Figloans 😊",
    info: "Tara Foures on February 11th, 2021",
    link: "https://goo.gl/maps/zkaxm4ArhnRS4pb98",
  },
  {
    quote: `Fig Loans, has the best interest rates. On a small personal loan. They report to all three major credit bureaus. It really helps rebuild your credit. And you don't have to have perfect credit to be considered for a loan. I myself have a several and paid them off. Either early or took the 4 month term. They will help you with payment dates. And if you need a bit more time. You can reschedule your payment on the app. The loans do start out small (each state has thier own laws) for my state it was 300. Again you get four months to pay it back. And over time the loans do increase with your good standing and on-time payments. I just use the ACH option which comes out of my account every month. And there is zero early payoff fees. Acually you get a early payoff amount. Which is very nice. Hope this review helps anyone seeking help from fig loans or trying to decide on whether fig is a good loan option company. I can advise that it is. I've used several companies over the years and fig really does have the best interest rates. Have a great day folks. Again I hope this helps`,
    info: "Steve S on February 17, 2021",
    link: "https://goo.gl/maps/aJHmqwC8o6LcjYEW6",
  },
  {
    quote:
      "They are fast,easy, and help build your credit my credit score went up 48 points just from opening my account and making my first payment don't hesitate get this loan I love the monthly payments and I don't mind paying the extra knowing it's helping my credit score 😌",
    info: "Chetera Walker on January 13, 2021",
    link: "https://goo.gl/maps/oKMForvYqeUr1UXV9",
  },
];
