import React from "react";
import MetaTags from "../MetaTags";

const TexasStory = () => {
  let metaTagData = {
    title: "Installment Loans Born in Texas | Fig Loans",

    description:
      "Fig's installment loans were born in Texas with the aim to help those most in need while providing a flexible repayment schedule.",
    og_title:
      "Why Fig provides Personal Installment Loans in Houston | Fig Loans",
    og_description:
      "Fig's installment loans were born in Texas with the aim to help those most in need while providing a flexible repayment schedule.",
    og_image:
      "https://s3.us-west-2.amazonaws.com/figloans-static-images/about/ts_pdl_mcd_fb.jpg",
    twitter_image:
      "https://s3.us-west-2.amazonaws.com/figloans-static-images/about/ts_pdl_mcd_fb.jpg",
    twitter_title:
      "Installment Loans Born in Texas | Fig Loans",
    twitter_description:
      "Fig's installment loans were born in Texas with the aim to help those most in need while providing a flexible repayment schedule.",
  };
  return (
    <div>
      <MetaTags metaTagData={metaTagData} />
      <div className="container">
        <div className="panel-grey">
          <div className="mission">
            <div className="mission-text">
              <h4>
                {
                  'How Fig Loans Started: From "The Big Heart" City to 6 States & Counting'
                }
              </h4>
              <p>
                The Fig personal loan started as a collaboration
                between Fig and United Way THRIVE in Houston. On October 14,
                2014, we cold called 50 nonprofit organizations to learn more
                about how online payday loans in Texas affect people and
                communities. We wanted to speak to financial coaches who had
                first-hand experience working with payday loan customers. The
                only person to take our call was Jackie at United Way of Greater
                Houston and the rest is history!
              </p>
            </div>
          </div>
        </div>
        <div className="panel-white">
          <div className="row">
            <div className="glance">
              <div className="glance-text">
                <p>
                  Jackie explained that THRIVE is a collaborative of employers,
                  community colleges, financial institutions and government
                  agencies who focus on helping families reach lasting financial
                  independence. We talked about our vision for the Fig personal
                  loan and how it would be the first of many responsible
                  financial products all geared towards helping people regain
                  access to traditional credit sources.
                </p>
                <p>
                  Over the next 6 months, we worked with Amy, Dorian, Jackie and
                  Rushka at United Way of Greater Houston to learn more about
                  the problems caused by payday lending practices. But perhaps
                  more importantly, we used this time to define the Fig Loan to
                  be, in every way, a better loan for customers and communities.
                  On March 18th of 2015, we presented the original Fig Loan to
                  over 80 members of the THRIVE collaborative and asked if
                  anyone would be willing to help us pilot this concept. Enter
                  Richard from Family Services of Greater Houston (Family
                  Houston)!
                </p>
              </div>
              <div className="glance-image">
                <div className="image-container">
                  <img
                    src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/ts_pdl_mcd.jpg"
                    style={{ width: "100%", display: "block" }}
                    className="chart-container"
                    alt="Fig Founder"
                  />
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
        <div className="panel-grey">
          <div className="mission">
            <div className="mission-text">
              <p>
                With support from Family Houston and United Way of Greater
                Houston, Fig Loans began offering installment loans in Texas.
                Over the summer of 2015, we worked closely with Richard, Dan and
                Sonia at Family Houston to fine tune the details of the Fig
                Loan. Their experience working with our future customers shaped
                the language, features and processes that underpin our Texas
                installment loans. It was through this process that Fig’s
                flexible repayment policy was born -- because it’s what you
                would do for a friend. From our THRIVE partners in Houston to
                advocates in Austin, we recieved input from coaches at
                organizations all over Texas. We officially launched our Fig
                Loans pilot on October 1<sup>st</sup> of 2015 and we couldn’t
                have done it without all the support we received from our
                friends in Houston! Now in 2024, we lend in five total states:
                Texas, Missouri, Ohio, Utah, and Florida.
                <br />
                <br />
                Thank you so much for welcoming us into "The Big Heart" city!
              </p>
              <br />
              <div className="center" id="story_signature">
                <img
                  id="founder_signatures"
                  src="https://figloans-static-images.s3.us-west-2.amazonaws.com/about/story-signature-jeff.jpg"
                  style={{ width: "100%", display: "block" }}
                  alt="Founder Signatures"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TexasStory;
