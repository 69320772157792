import React from "react";
import EmailUnsubscribe from "./EmailUnsubscribe";

const VeritecEmailUnsubscribe = () => {
  return (
    <EmailUnsubscribe
      unsubscribeUrl='/emails/unsubscribe_veritec'
      successText="You've successfully unsubscribed from all Veritec email notifications."
      />
  )
}

export default VeritecEmailUnsubscribe