import React from "react";
import MetaTags from "../MetaTags";
import APR from "./compare/APR";
import Length from "./compare/Length";
import Payments from "./compare/Payments";
import Fees from "./compare/Fees";
import CreditReporting from "./compare/CreditReporting";

const CompareLoans = () => {
  let metaTagData = {
    title: "Installment Loans to Fast Cash with Transparency | Fig Loans",
    canonical: "https://www.figloans.com/loan-information/",
    og_title: "Installment Loans to Fast Cash with Transparency | Fig Loans",
    twitter_title: "Installment Loans to Fast Cash with Transparency | Fig Loans",
    description:
      "Fig's guide to comparing different parts of installment loans, so you can stop borrowing from dishonest lenders.",
    og_description:
      "Fig's guide to comparing different parts of installment loans, so you can stop borrowing from dishonest lenders.",
    twitter_description:
      "Fig's guide to comparing different parts of installment loans, so you can stop borrowing from dishonest lenders.",
    og_image:
      "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/social/og_image_default.jpg",
    twitter_image:
      "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/social/twitter_image_default.jpg",
  };

  return (
    <div className="container">
      <MetaTags metaTagData={metaTagData} />
      <div className="panel-grey">
        <div className="mission">
          <div className="mission-text">
            <h4>How To Compare A Loan</h4>
            <p>
              So, you need money fast? {"Don't"} let that loan cost you more in
              the long run! Before you take out any type of loan, it’s important
              to consider all of the costs.
              <b>Fig does not support payday loans or payday lenders.</b> But we
              know sometimes you need emergency cash, so we wrote this 5 step
              guide to help you stop dishonest lenders in their tracks:
            </p>
            <div className="horizontal_list">
              <ul>
                <li>
                  <a href="#apr">APR</a>
                </li>
                <li>
                  <a href="#lengthoftheloan">Length of the Loan</a>
                </li>
                <li>
                  <a href="#payments">Payments</a>
                </li>
                <li>
                  <a href="#fees">Fees</a>
                </li>
                <li>
                  <a href="#creditreporting">Credit Reporting</a>
                </li>
                <span className="stretch clear" />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-white">
        <APR />
      </div>
      <div className="panel-grey">
        <Length />
      </div>
      <div className="panel-white">
        <Payments />
      </div>
      <div className="panel-grey">
        <Fees />
      </div>
      <div className="panel-white">
        <CreditReporting />
      </div>

      <div className="panel-grey">
        <div className="row">
          <div className="overtime">
            <p>
              We hope you enjoyed our 5 step guide to comparing personal loans
              and feel ready to pick out the best personal loan for you.
            </p>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default CompareLoans;
