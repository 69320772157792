import { combineReducers } from "redux";
import session from "./session_errors_reducer";
import application from "./application_errors_reducer";
import user from "./user_errors_reducer";
import blog from "./blog_errors_reducer";
import statements from "./statement_errors_reducer";
import video from "./video_errors_reducer";

const errorsReducer = combineReducers({
  application,
  session,
  user,
  blog,
  statements,
  video
});

export default errorsReducer;
