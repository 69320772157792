export const RECEIVE_UTM = "RECEIVE_UTM";

export const receiveUtm = utm => ({
  type: RECEIVE_UTM,
  utm
});

export const setUtm = utm => dispatch => {
  if (window.dataLayer && utm.source && utm.source.length > 0) {
    window.dataLayer.push({utmSource: utm.source});
  }
  dispatch(receiveUtm(utm));

}
