import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { changeBanner } from "../actions/banner_actions";

const Banner = ({ message, style, duration }) => {
  const [show, setShow] = useState(true);

  const dispatch = useDispatch();

  useEffect(() => {
    new Promise(() => {
      setTimeout(() => {
        dispatch(changeBanner(false, false, false));
      }, duration * 1000);
    }).then(() => {
      setShow(false);
    });
  }, []);

  const close = () => {
    dispatch(changeBanner(false, false, false));
    setShow(false);
  };

  return (
    <div>
      {show && message && (
        <div>
          <div className={`banner-container ${style}`}>
            <p onClick={() => close()} className={`banner-close ${style}`}>
              X
            </p>
            <p className={`banner-message ${style}`}>{message}</p>
          </div>
          <br></br>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  message: state.ui.banner.message,
  style: state.ui.banner.style,
  duration: state.ui.banner.duration,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Banner);

export const BannerStyle = Object.freeze({
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
});
