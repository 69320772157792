/* eslint-disable */
import React from "react";
import MetaTags from "../MetaTags";

const LoanDictionary = () => {
  let metaTagData = {
    title: "Dictionary of Payday and Small Dollar Loan Terms | Fig Loans",
    canonical: "https://www.figloans.com/loan-information/",
    og_title: "Dictionary of Payday and Small Dollar Loan Terms | Fig Loans",
    twitter_title:
      "Dictionary of Payday and Small Dollar Loan Terms | Fig Loans",
    description:
      "Need help understanding the difference between auto loans and installment loans? Or a CAB Fee? Fig’s Plain English Financial Dictionary is here to help!",
    og_description:
      "Need help understanding the difference between auto loans and installment loans? Or a CAB Fee? Fig’s Plain English Financial Dictionary is here to help!",
    twitter_description:
      "Need help understanding the difference between auto loans and installment loans? Or a CAB Fee? Fig’s Plain English Financial Dictionary is here to help!",
    og_image:
      "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/social/og_image_default.jpg",
    twitter_image:
      "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/social/twitter_image_default.jpg",
  };

  return (
    <div className="container">
      <MetaTags metaTagData={metaTagData} />
      <div className="panel-grey">
        <div className="mission">
          <div className="mission-text">
            <h4>Fig’s Plain English Dictionary of Payday Loan Terms</h4>
            <p className="center">
              {" "}
              Not able to find your answer here? Email us at{" "}
              <a
                href="mailto:service@figloans.com?Subject=Question%20about%20Fig"
                target="_top"
              >
                service@figloans.com
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="panel-white">
        <div className="row">
          <div className="glance">
            <div className="glance-text">
              <div className="dictionary-links">
                <a href="#apr">APR</a>
                <br />
                <a href="#bad_credit_loan">Bad Credit Loan</a>
                <br />
                <a href="#balloon_loan">Balloon Loan</a>
                <br />
                <a href="#credit_access_business">
                  Credit Access Business (CAB)
                </a>
                <br />
                <a href="#cab_fees">CAB Fees</a>
                <br />
                <a href="#cab_license">CAB License</a>
                <br />
                <a href="#cretificate_deposit">Certificate of Deposit (CD)</a>
                <br />
                <a href="#credit_builder_loan">Credit Builder Loan</a>
                <br />
                <a href="#credit_reporting">Credit Reporting</a>
                <br />
              </div>
            </div>
            <div className="glance-text">
              <div className="dictionary-links">
                <a href="#auto_loan">Auto Loan</a>
                <br />
                <a href="#credit_reporting_agencies">
                  Credit Reporting Agencies
                </a>
                <br />
                <a href="#fico_score">FICO score</a>
                <br />
                <a href="#individual_deposit_accounts">
                  Individual Development Accounts
                </a>
                <br />
                <a href="#installment_loan">Installment Loan</a>
                <br />
                <a href="#second_chance_account">Second Chance Account</a>
                <br />
                <a href="#title_loan">Title Loan</a>
                <br />
                <a href="#unsecured_loan">Unsecured loan</a>
                <br />
                <a href="#secured_loan">Secured loan</a>
                <br />
              </div>
            </div>
            <div className="clear" />
          </div>
        </div>
        <div className="panel-grey">
          <div className="row">
            <div className="faq">
              <h4 id="apr">APR</h4>
              <p>
                The Annual Percentage Rate (APR) is a measurment of the amount
                of interest you can expect to pay on a loan over a year. You can
                roughly calculate your interest cost by multiplying the APR by
                the amount you would like to borrow divided by 100. However, for
                short term loans this{" "}
                <a
                  href="https://www.quora.com/Why-is-APR-a-bad-measurement-for-payday-loans"
                  target="_blank"
                >
                  {"isn't"} always accurate
                </a>
                .
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="auto_loan">Auto Loan</h4>
              <p>
                A loan that you take out in order to purchase a vehicle. An auto
                loan helps you buy a car that costs more than you can afford
                with cash. You make monthly payments to repay the loan over
                time. The terms of an auto loan depend on various factors,
                including your income and credit history.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="bad_credit_loan">Bad Credit Loan</h4>
              <p>
                A loan available to consumers with a credit score below 630.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="balloon_loan">Balloon Loan</h4>
              <p>
                A loan that is paid off with one large payment at the end of the
                term of your loan. On the day your loan is due you pay back all
                of the principal and interest at once.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="credit_access_business">Credit Access Business (CAB)</h4>
              <p>
                A business that obtains credit (often in the form of a loan) for
                a consumer from an independent third-party lender. A Credit
                Access Business charges fees (see CAB Fees) in addition to the
                interest of a loan.
              </p>
              <a href="#">back to top</a>

              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="cab_fees">CAB Fees</h4>
              <p>
                The fee charged to the consumer by the Credit Access Business
                for obtaining the third party loan. This fee is usually
                calculated as a percentage of the loan amount.
              </p>
              <a href="#">back to top</a>

              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="cab_license">CAB License</h4>
              <p>
                A license that all Credit Access Businesses must obtain. You
                have a right to view a Credit Access Business’ valid CAB license
                before you sign any loan documents. This is the license used by
                many payday lenders to operate in Texas.
              </p>
              <a href="#">back to top</a>

              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="cretificate_deposit">Certificate of Deposit (CD)</h4>
              <p>
                A savings tool that can offer a higher return on your money than
                most standard savings accounts. CD’s typically don’t have
                monthly fees but limit when and how often you can take the money
                out of the account.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="credit_builder_loan">Credit Builder Loan</h4>
              <p>
                A small loan designed to help consumers establish or boost their
                credit profiles. Credit builder loans are specifically designed
                for customers who do not actually need a loan but want to build
                credit history. This loan is offered by some nonprofits, credit
                unions and a few banks.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="credit_reporting">Credit Reporting</h4>
              <p>
                Any loan that reports loan performance to the major credit
                reporting agencies - Equifax, Experian or TransUnion. Credit
                Builder Loans will always report credit. However, title loans,
                payday loans, installment loans and other types of emergency or
                bad credit loans may not.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="credit_reporting_agencies">Credit Reporting Agencies</h4>
              <p>
                Also known as credit bureaus, credit agencies compile all of
                your credit information and history. There are many specialty
                credit reporting agencies, but Equifax, Experian and TransUnion
                are the three major bureaus that lenders use to look at your
                credit reports.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="fico_score">FICO Score</h4>
              <p>
                A credit score developed by FICO that is used by lenders to
                predict what will likely happen if they give you a loan. To
                create credit scores, they use information provided by one of
                the three major credit reporting agencies — Equifax, Experian or
                TransUnion. FICO itself is not a credit reporting agency
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="individual_deposit_accounts">
                Individual Development Accounts (IDA)
              </h4>
              <p>
                A special savings account that matches savings for lower income
                customers. The typical requirement is applicants earn less than
                200% of the Federal Poverty Income level. When you save in an
                IDA, your savings are matched. This means that for every dollar
                you save you receive another dollar or more for free. While most
                organizations will provide a one-to-one match, we’ve seen up to
                an eight-to-one match.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="installment_loan">Installment Loan</h4>
              <p>
                A loan that is repaid by the borrower over multiple payments,
                also known as installments. Installments are generally monthly
                payments of equal size that include both interest and loan
                principal.
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="second_chance_account">Second Chance Account</h4>
              <p>
                A type of checking account that provides customers who have
                previously lost bank accounts a chance to start over and rebuild
                their relationship with traditional banks
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="title_loan">Title Loan</h4>
              <p>
                A short-term loan in which the borrower’s car is used as
                collateral. This is called a title loan because ownership of a
                car is established by the “title to a car.” If the loan is not
                repaid, the lender will take ownership of the car and sell it to
                make up for the loan amount
              </p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="unsecured_loan">Unsecured loan</h4>
              <p>A loan that {"doesn't"} require collateral</p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
          <div className="row">
            <div className="faq">
              <h4 id="secured_loan">Secured loan</h4>
              <p>A loan that requires collateral, like a car title</p>
              <a href="#">back to top</a>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoanDictionary;
