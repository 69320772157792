import React from "react";
import { Link } from "react-router-dom";

const About = ({ handleNavigate }) => {
  return [
    <li key={0}>
      <Link to="/about/fig-texas-story" onClick={handleNavigate}>
        {" "}
        Our Texas Story
      </Link>
    </li>,
    <li key={1}>
      {" "}
      <Link to="/about/responsible-lending-model" onClick={handleNavigate}>
        {" "}
        Lending Model
      </Link>
    </li>,
    <li key={2}>
      {" "}
      <Link to="/loan-information/credit-reporting" onClick={handleNavigate}>
        Credit Reporting
      </Link>
    </li>,
    <li key={3}>
      {" "}
      <Link
        to="/loan-information/compare-payday-loans"
        onClick={handleNavigate}
      >
        Compare Us
      </Link>
    </li>,
    <li className="desktop" key={2}>
      <Link
        onClick={() => this.setState({ mobileMenu: false })}
        to="/what-is-fig"
      >
        What Is Fig
      </Link>
    </li>
  ];
};

export default About;
