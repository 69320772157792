import * as APIUtil from "../util/session_api_util";
import { receiveSessionErrors } from "./error_actions";
import {receiveAppStage, receiveBVInfo, receivePlaidIncomeInfo} from "./application_actions";
import { changeLoading } from "./loader_actions";
export const RECEIVE_CURRENT_USER = "RECEIVE_CURRENT_USER";
export const RECEIVE_NEXT_CUT_OFF = "RECEIVE_NEXT_CUT_OFF";

export const receiveCurrentUser = currentUser => ({
  type: RECEIVE_CURRENT_USER,
  currentUser
});

export const receiveNextCutOff = info => ({
  type: RECEIVE_NEXT_CUT_OFF,
  info
});

export const login = user => dispatch =>
  APIUtil.login(user).then(
    payload => {
      dispatch(receiveCurrentUser(payload));
      // leaving in remnants of plaid income integration, since we may use this again.  Determined it won't affect functionality
      if (payload.plaid_income_verification) {
        dispatch(receivePlaidIncomeInfo(payload.plaid_income_verification));
      }

      if (payload.BVInfo) {
        dispatch(receiveBVInfo(payload.BVInfo));
      }
      dispatch(changeLoading(false));
    },
    errors => {
      dispatch(receiveSessionErrors(errors));
      dispatch(changeLoading(false));
    }
  );
//
export const logout = () => dispatch =>
  APIUtil.logout().then(
    () => {
      dispatch(receiveCurrentUser(null));
      dispatch(receiveAppStage(1));
      dispatch(changeLoading(false));
    },
    () => {
      dispatch(receiveCurrentUser(null));
      dispatch(receiveAppStage(1));
      dispatch(changeLoading(false));
    }
    // errors => dispatch(receiveSessionErrors(errors))
  ); // currentUser should be nooobooody

export const fetchUserInfo = email => dispatch =>
  APIUtil.fetchUserInfo(email).then(
    payload => {
      dispatch(receiveCurrentUser(payload));
      // leaving in remnants of plaid income integration, since we may use this again.  Determined it won't affect functionality
      if (payload.plaid_income_verification) {
          dispatch(receivePlaidIncomeInfo(payload.plaid_income_verification));
      }

      if (payload.BVInfo) {
        dispatch(receiveBVInfo(payload.BVInfo));
      }
      dispatch(changeLoading(false));
    },
    () => {
      dispatch(receiveCurrentUser(null));
      dispatch(changeLoading(false));
    }
  );

export const forceLogin = (forced, callback) => dispatch =>
  APIUtil.forceLogin(forced).then(
    payload => {
      dispatch(receiveCurrentUser(payload));
      if (payload.BVInfo) {
        dispatch(receiveBVInfo(payload.BVInfo));
      }
      dispatch(changeLoading(false));
      callback();
    },
    errors => {
      dispatch(receiveSessionErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const fetchPaymentNextCutOff = () => dispatch =>
  APIUtil.fetchPaymentNextCutOff().then(payload => {
    dispatch(receiveNextCutOff(payload));
  });
