import { domain } from "../helpers/route_helper";
import {asyncFetchPatchRequest} from "./async_util";

export const checkEmail = (email, referral) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/check_email_and_referral`,
    data: { email, referral },
  });
};

export const fetchCoaches = (partner_id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/fetch_coaches`,
    data: { partner_id },
  });
};

export const submitPage1 = (esign1, esign2, time_displayed, user) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/step_1_2`,
    data: { esign1, esign2, time_displayed, user },
  });
};

export const submitPersonal = (address, user_id, user) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/step_3`,
    data: { address, user_id, user },
  });
};

export const investigateSSN = (user_id) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/investigate_ssn`,
    data: { user_id },
  });
};

export const submitSecure = (
  user_id,
  ssn,
  drivers_license,
  bank_account,
  browser,
  loan
) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/step_4`,
    data: { user_id, ssn, drivers_license, bank_account, browser, loan },
  });
};

export const submitPlaid = (public_token, loan_id) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/plaid/public_key`,
    data: { public_token, loan_id },
  });
};

export const sendPlaidUpdateModeSuccessToMonolith = async (loanId) => {
  asyncFetchPatchRequest('/plaid/update_mode', {loan_id: loanId})
    .then(result => {
      console.log('Success when attempting to submit Update Mode success to Monolith.');
    }, error => {
      console.log('Error when attempting to submit Update Mode success to Monolith.');
    });
}


// @deprecated
export const postPlaidIncomeLink = async (loanId) => {
  try {
    const response = await fetch(`${domain}/plaid/income_link`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({loan_id: loanId}),
    });
    if (response.ok) {
      console.log('Successful Plaid Income Link');
    }
  } catch (error) {
    console.error('Error:', error);
  }
}



export const changeLoanAmount = (user_id, loan) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/api/change_loan_amount`,
    data: { user_id, loan },
  });
};

export const registerClick = (user_id, loan_id, click_type, comments) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/register_click`,
    data: { user_id, loan_id, click_type, comments },
  });
};

export const veritecOptIn = (user_id, time_displayed) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/veritec_opt_in`,
    data: { user_id, time_displayed },
  });
};

export const reapplyReminder = (loan_id, days) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/reminder_opt_in`,
    data: { loan_id, days },
  });
};

export const changeLoanTerm = (user_id, action) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/api/change_loan_term`,
    data: { user_id, action },
  });
};

export const neededInfo = (
  user_id,
  bank_account,
  password,
  password_confirmation,
  phone
) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/needed_info`,
    data: { user_id, bank_account, password, password_confirmation, phone },
  });
};

export const updatePhoneNumber = (user_id, phone_number) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/api/update_phone_number`,
    data: { user_id, phone_number },
  });
};

export const verifyPhone = (user_id, code, callback) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/verify_phone`,
    data: { user_id, code },
    success: callback,
  });
};

export const verifySignature = (user_id, signature, callback) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/verify_signature`,
    data: { user_id, signature },
    success: callback,
  });
};

export const getSupplemental = (user_id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/get_supplemental`,
    data: { user_id },
  });
};

export const getTila = (user_id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/get_tila`,
    data: { user_id },
  });
};

export const getCso = (user_id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/get_cso`,
    data: { user_id },
  });
};

export const getLender = (user_id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/get_lender`,
    data: { user_id },
  });
};

export const getFundingOptions = (user_id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/get_funding_options`,
    data: { user_id },
  });
};

export const getPaymentOptions = (user_id, entered_debit) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/get_payment_options`,
    data: { user_id, entered_debit },
  });
};

export const acceptLoan = (
  callback,
  user_id,
  loan_id,
  time_displayed,
  lender_signature,
  accept_ach,
  accept_rcc,
  accept_debit,
  cso_signature,
  eftav2,
  selected_payment_efta,
  funding_method
) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/accept_loan`,
    data: {
      callback,
      user_id,
      loan_id,
      time_displayed,
      lender_signature,
      accept_ach,
      accept_rcc,
      accept_debit,
      cso_signature,
      eftav2,
      selected_payment_efta,
      funding_method,
    },
    success: callback,
  });
};

export const acceptModification = () => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/decline_counter`,
  });
};

export const easyApply = (user_id, loan) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/easy_apply`,
    data: { user_id, loan },
  });
};

export const addFundingCard = (
  user_id,
  debit_card,
  address,
  req_efta,
  efta_type
) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/add_debit_card`,
    data: {
      user_id,
      debit_card,
      address,
      req_efta,
      efta_type,
    },
  });
};

export const preapprove = () => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/preapprove`,
  });
};

export const instantRepeat = () => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/instant_repeat`,
  });
};

export const prequalOrPlaid = (accept) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/prequal_or_plaid`,
    data: { accept }
  });
};