import { merge } from "lodash";
import { RECEIVE_LOADING } from "../actions/loader_actions";
import { RECEIVE_DEBIT_VALID } from "../actions/user_actions";
import { RECEIVE_APPLICATION } from "../actions/application_actions";
import { RECEIVE_PRODUCTS, RECEIVE_STATE } from "../actions/info_actions";
import { RECEIVE_BANNER } from "../actions/banner_actions";

const defaultState = { loading: false, debitValid: false, loanSelected: false, banner: false };

const uiReducer = (state = defaultState, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_LOADING:
      return merge({}, state, { loading: action.loading });
    case RECEIVE_DEBIT_VALID:
      return merge({}, state, { debitValid: action.debitValid });
    case RECEIVE_APPLICATION:
      return merge({}, state, { loanSelected: true });
    case RECEIVE_STATE:
    case RECEIVE_PRODUCTS:
      return merge({}, state, { loading: false });
    case RECEIVE_BANNER:
      return merge({}, state, { banner: { message: action.message, style: action.style, duration: action.duration } });
    default:
      return state;
  }
};

export default uiReducer;
