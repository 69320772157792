import { merge } from "lodash";
import { RECEIVE_VIDEO_ERRORS, CLEAR_ERRORS } from "../actions/error_actions";

const defaultState = [];

const videoErrorsReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_VIDEO_ERRORS:
      return merge({}, oldState, action.errors.errors);
    case CLEAR_ERRORS:
      return {};
    default:
      return oldState;
  }
};

export default videoErrorsReducer;
