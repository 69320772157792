import React from "react";
import EmailUnsubscribe from "./EmailUnsubscribe";

const MarketingEmailUnsubscribe = () => {
  return (
    <EmailUnsubscribe
      unsubscribeUrl='/emails/unsubscribe'
      successText="You've successfully unsubscribed from Fig's marketing emails."
      />
  )
}

export default MarketingEmailUnsubscribe