import React, { useState, useEffect, useLayoutEffect } from "react";

export default function LoanCalculator(props) {
  const [loanAmount, setLoanAmount] = useState(0);
  const [loanInterestRate, setLoanInterestRate] = useState(0);
  const [loanTerm, setLoanTerm] = useState(0);
  const [loanTermUnit, setLoanTermUnit] = useState("months");
  const [transition, setTransition] = useState(false);
  const [outputDisplayed, setOutputDisplayed] = useState(false);
  const [errorInputs, setErrorInputs] = useState([]);
  const [initialRender, setInitialRender] = useState(true);
  const [changeFocus, setChangeFocus] = useState([]);

  const loanAmountClassName = "loan-amount-container";
  const loanInterestRateClassName = "loan-interest-rate-container";
  const loanTermClassName = "loan-term-container";
  const inputElementClassNameList = [
    loanAmountClassName,
    loanInterestRateClassName,
    loanTermClassName
  ];

  function onInputChange(event) {
    document
      .querySelector(".loan-calculator-output")
      .classList.remove("transition");
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (name !== "loanTermUnit") {
      value = Number(value);
    }

    if (name === "loanAmount") {
      setLoanAmount(value);
    } else if (name === "loanInterestRate") {
      value /= 100;
      setLoanInterestRate(value);
    } else if (name === "loanTerm") {
      setLoanTerm(value);
    } else {
      setLoanTermUnit(value);
    }

    setOutputDisplayed(false);
  }

  // all these useEffect functions for each input should be consolidated into one function
  // just not sure what the syntax is
  useEffect(() => {
    let tmpErrorInputs = errorInputs;

    if (loanAmount > 0 && errorInputs.includes(loanAmountClassName)) {
      tmpErrorInputs = errorInputs.filter(
        element => element !== loanAmountClassName
      );
      document
        .getElementsByClassName(loanAmountClassName)[0]
        .classList.remove("input-error");
    }

    if (loanAmount <= 0 && !errorInputs.includes(loanAmountClassName)) {
      tmpErrorInputs.push(loanAmountClassName);
    }

    setErrorInputs(tmpErrorInputs);
  }, [loanAmount]);

  useEffect(() => {
    let tmpErrorInputs = errorInputs;

    if (
      loanInterestRate > 0 &&
      errorInputs.includes(loanInterestRateClassName)
    ) {
      tmpErrorInputs = errorInputs.filter(
        element => element !== loanInterestRateClassName
      );
      document
        .getElementsByClassName(loanInterestRateClassName)[0]
        .classList.remove("input-error");
    }

    if (
      loanInterestRate <= 0 &&
      !errorInputs.includes(loanInterestRateClassName)
    ) {
      tmpErrorInputs.push(loanInterestRateClassName);
    }

    setErrorInputs(tmpErrorInputs);
  }, [loanInterestRate]);

  useEffect(() => {
    let tmpErrorInputs = errorInputs;

    if (loanTerm > 0 && errorInputs.includes(loanTermClassName)) {
      tmpErrorInputs = errorInputs.filter(
        element => element !== loanTermClassName
      );
      document
        .getElementsByClassName(loanTermClassName)[0]
        .classList.remove("input-error");
    }

    if (loanTerm <= 0 && !errorInputs.includes(loanTermClassName)) {
      tmpErrorInputs.push(loanTermClassName);
    }

    setErrorInputs(tmpErrorInputs);
  }, [loanTerm]);

  useEffect(() => {
    if (changeFocus.length !== 0) {
      for (var index in changeFocus) {
        let name = changeFocus[index];
        if (name != null) {
          let changingFocus = document.getElementsByName(changeFocus[index])[0];
          changingFocus.parentElement.classList.toggle("focus");
          changingFocus.nextElementSibling.classList.toggle("focus");
        }
      }
    }
  }, [changeFocus]);

  useLayoutEffect(() => {
    if (outputDisplayed) {
    } else {
      document
        .querySelector(".loan-calculator-output")
        .classList.remove("transition");
    }
  }, [outputDisplayed]);

  useLayoutEffect(() => {
    if (!initialRender) {
      setTimeout(() => {
        setOutputDisplayed(true);
        document
          .querySelector(".loan-calculator-output")
          .classList.add("transition");
        setTransition(false);
      }, 1000);
    } else {
      setInitialRender(false);
    }
  }, [transition]);

  function toggleFocusState(event) {
    let tmpChangeFocus = changeFocus;
    tmpChangeFocus = [event.target.name, ...tmpChangeFocus];
    if (changeFocus.length >= 2) {
      tmpChangeFocus = tmpChangeFocus.slice(0, 2);
    }
    setChangeFocus(tmpChangeFocus);
  }

  function clearForm() {
    setChangeFocus([null, changeFocus[0]]);
    setLoanAmount(0);
    setLoanInterestRate(0);
    setLoanTermUnit("days");
    setLoanTerm(0);
    document.getElementById("loan-form").reset();
    setOutputDisplayed(false);
  }
  function calculateInstallment() {
    inputElementClassNameList.forEach(element => {
      if (errorInputs.includes(element)) {
        document
          .getElementsByClassName(element)[0]
          .classList.add("input-error");
      }
    });

    if (errorInputs.length > 0) {
      alert(
        "Please enter values greater than 0 for the red outlined input boxes"
      );
      return;
    }

    setChangeFocus([null, changeFocus[0]]);
    setTransition(true);
  }

  let termAmountPerYear = 0;
  switch (loanTermUnit) {
    case "days":
      termAmountPerYear = 365;
      break;
    case "weeks":
      termAmountPerYear = 52;
      break;
    case "months":
      termAmountPerYear = 12;
      break;
    case "years":
      termAmountPerYear = 1;
      break;
    default:
      termAmountPerYear = 12;
  }

  const installment =
    Math.round(
      (((loanInterestRate / termAmountPerYear) * loanAmount) /
        (1 - Math.pow(1 + loanInterestRate / termAmountPerYear, -loanTerm))) *
        100
    ) / 100;
  const totalPayments = Math.round(installment * loanTerm * 100) / 100;
  const totalPaidInInterest =
    Math.round((installment * loanTerm - loanAmount) * 100) / 100;

  return (
    <div className="loan-calculator-container">
      <h2>Loan Calculator</h2>
      <div className="loan-input-and-output">
        <form className="loan-input-form" id="loan-form">
          <div className="loan-text-input">
            <div className="loan-amount-container">
              <label>Loan Amount</label>
              <span>
                $
                <input
                  type="number"
                  name="loanAmount"
                  onFocus={ev => toggleFocusState(ev)}
                  placeholder="0.00"
                  onChange={ev => onInputChange(ev)}
                />
                <span class="separator"> </span>
              </span>
            </div>
            <div className="loan-interest-rate-container">
              <label>APR</label>
              <span>
                <input
                  type="number"
                  name="loanInterestRate"
                  placeholder="0"
                  onFocus={ev => toggleFocusState(ev)}
                  onChange={ev => onInputChange(ev)}
                />
                %<span class="separator"> </span>
              </span>
            </div>
            <div className="loan-term-container">
              <label>
                Loan Term in{" "}
                <select name="loanTermUnit" onChange={ev => onInputChange(ev)}>
                  <option value="days">Days</option>
                  <option value="weeks">Weeks</option>
                  <option value="months" selected>
                    Months
                  </option>
                  <option value="years">Years</option>
                </select>
              </label>
              <span>
                <input
                  type="number"
                  name="loanTerm"
                  placeholder="0"
                  onFocus={ev => toggleFocusState(ev)}
                  onChange={ev => onInputChange(ev)}
                />
                <span class="separator"> </span>
              </span>
            </div>
          </div>
          <div className="button-row">
            <button
              id="calculateInstallmentButton"
              onClick={ev => {
                ev.preventDefault();
                calculateInstallment();
              }}
            >
              Calculate
            </button>
            <button
              onClick={ev => {
                ev.preventDefault();
                clearForm();
              }}
            >
              Clear
            </button>
          </div>
        </form>
        <div
          className="loan-calc-spinner"
          style={
            transition && !outputDisplayed
              ? { display: "block" }
              : { display: "none" }
          }
        ></div>
        <div
          className={`loan-calculator-output ${
            outputDisplayed ? "invsibile" : ""
          }`}
        >
          <div id="loan-calculator-output-row-0">
            <p>{loanInterestRate * 100}% APR</p>
            <p>${loanAmount.toLocaleString()} loan</p>
            <p>
              Spread evenly over {loanTerm}{" "}
              {loanTerm === 1 ? loanTermUnit.slice(0, -1) : loanTermUnit}
            </p>
          </div>
          <div id="loan-calculator-output-row-1">
            <h1>${installment.toLocaleString()}</h1>
            <p>{`paid every ${loanTermUnit.slice(0, -1)}`}</p>
          </div>
          <div id="loan-calculator-output-row-2">
            <div>
              <h4>Total Payments</h4>
              <h4>${totalPayments.toLocaleString()}</h4>
            </div>
            <div>
              <h4>APR x Principal</h4>
              <h4>${totalPaidInInterest.toLocaleString()}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
