import { Route, Redirect, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import React from "react";
import { domain } from "../helpers/route_helper";
import asyncComponent from "../components/AsyncComponent";

const Login = asyncComponent(() => import("../components/Session"));

const mapAuthStateToProps = state => {
  return { loggedIn: Boolean(state.session.currentUser) };
};

const AppAuth = ({ component: Component, path, loggedIn, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={props =>
      !loggedIn ? <Component {...props} /> : <Redirect to="/app_middle" />
    }
  />
);

const Auth = ({ component: Component, path, loggedIn, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={props =>
      !loggedIn ? <Component {...props} /> : <Redirect to="/me" />
    }
  />
);

const Protected = ({ component: Component, path, loggedIn, exact }) => (
  <Route
    path={path}
    exact={exact}
    render={props =>
      loggedIn ? <Component {...props} /> : <Redirect to="/signin" />
    }
  />
);

// const betterRoute = ({component: Component, paths, loggedIn, exact}) => (
//   {paths.map()}
// )

export const htmlRoute = ({ component: Component, path, exact, props }) => {
  return [
    <Route
      key={1}
      path={path}
      exact={exact}
      render={props => <Component {...props} />}
    />,
    <Route
      key={2}
      path={path + ".html"}
      exact={exact}
      render={props => <Component {...props} />}
    />
  ];
};

export const Monolith = ({ path, exact }) => {
  window.location.href = `${domain}${window.location.pathname}${
    window.location.search
  }`;
  return <div />;
};

const Gated = ({ component: Component, path, exact, loggedIn, ...props }) => (
  <Route
    path={path}
    exact={exact}
    render={() => (loggedIn ? <Component {...props} /> : <Login />)}
  />
);

export const AppAuthRoute = withRouter(
  connect(
    mapAuthStateToProps,
    null
  )(AppAuth)
);

export const AuthRoute = withRouter(
  connect(
    mapAuthStateToProps,
    null
  )(Auth)
);
export const ProtectedRoute = withRouter(
  connect(
    mapAuthStateToProps,
    null
  )(Protected)
);

export const GatedRoute = withRouter(
  connect(
    mapAuthStateToProps,
    null
  )(Gated)
);
