import React from "react";
import { Helmet } from "react-helmet";

const FloridaRates = ({ products }) => {
  products = products || {
    loans: {
      traditional_loan: {
        "300": { 6: { pmt: 89.37, apr: 2.21 } },
        "400": { 6: { pmt: 119.16, apr: 2.18 } },
        "500": { 6: { pmt: 148.95, apr: 2.16 } }
      }
    }
  };
  const length1 = Object.keys(products.loans.traditional_loan["300"])[0];
  const length2 = Object.keys(products.loans.traditional_loan["400"])[0];
  const length3 = Object.keys(products.loans.traditional_loan["500"])[0];

  const apr1 = products.loans.traditional_loan["300"][length1].apr;
  const apr2 = products.loans.traditional_loan["400"][length2].apr;
  const apr3 = products.loans.traditional_loan["500"][length3].apr;

  const payment1 = products.loans.traditional_loan["300"][length1].pmt;
  const payment2 = products.loans.traditional_loan["400"][length2].pmt;
  const payment3 = products.loans.traditional_loan["500"][length3].pmt;

  return (
    <div className="container">
      <Helmet>
        <title>Florida Rates and Notices | Fig Loans</title>
        <meta
          name="description"
          content="Do you need a loan in Florida? Fig Loans has the friendliest terms in the state!"
        />
        <meta
          property="og:description"
          content="Do you need a loan in Florida? Fig Loans has the friendliest terms in the state!"
        />
      </Helmet>
      <div className="panel-grey">
        <div id="florida">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className='modal-heading'>Florida</h4>
              </div>
              <p>
                Loan costs depend on the amount advanced and length of the loan.
              </p>
              <p>
                <b>Fee Examples</b>
              </p>
              <p>
                <div className="disclosurewrap">
                  <div className="full-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Amount Advanced</th>
                          <td>$300.00</td>
                          <td>$400.00</td>
                          <td>$500.00</td>
                        </tr>
                        <tr>
                          <th>Loan Length</th>
                          <td>{length1 * 14} days</td>
                          <td>{length2 * 14} days</td>
                          <td>{length3 * 14} days</td>
                        </tr>
                        <tr>
                          <th>
                            Loan APR<sup>1</sup>
                          </th>
                          <td>{Math.round(apr1 * 100)}%</td>
                          <td>{Math.round(apr2 * 100)}%</td>
                          <td>{Math.round(apr3 * 100)}%</td>
                        </tr>
                        <tr>
                          <th>Interest Charge</th>
                          <td>
                            $
                            {(
                              Math.round(length1 * payment1 * 100) / 100 -
                              300
                            ).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(
                              Math.round(length2 * payment2 * 100) / 100 -
                              400
                            ).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(
                              Math.round(length3 * payment3 * 100) / 100 -
                              500
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th>Total Repayment Amount</th>
                          <td>
                            $
                            {(
                              Math.round(length1 * payment1 * 100) / 100
                            ).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(
                              Math.round(length2 * payment2 * 100) / 100
                            ).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(
                              Math.round(length3 * payment3 * 100) / 100
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mobile-size">
                    <table>
                      <tr>
                        <th>Sample $300 Dollar {length1} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $300.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length1 * 14} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>
                          {apr1 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> $
                          {(
                            Math.round(length1 * payment1 * 100) / 100 -
                            300
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$
                          {(Math.round(length1 * payment1 * 100) / 100).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $400 Dollar {length2} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $400.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length2 * 14} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          {apr2 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> $
                          {(
                            Math.round(length2 * payment2 * 100) / 100 -
                            400
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br /> $
                          {(Math.round(length2 * payment2 * 100) / 100).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $500 Dollar {length3} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $500.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length3 * 14} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          {apr3 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> $
                          {(
                            Math.round(length3 * payment3 * 100) / 100 -
                            500
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br /> $
                          {(Math.round(length3 * payment3 * 100) / 100).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </p>
              <p className="footnote">
                1. The APR is based on a six payment, 90 day amortizing loan.
                The APR shown is based on the federal Truth in Lending Act and
                does not reflect specialized state APR calculations, if any.
                <br />
              </p>
              <p>
                The loan information shown here is an example and may not
                reflect the rates available on all loans. Before entering into
                any transaction, you should read the disclosure statements and
                contract that are provided to you by Fig Loans.
              </p>

              <p>
                {" "}
                <u> Additional Fees </u>{" "}
              </p>
              <p>
                {" "}
                An additional $5.00 will be added to the first payment of all
                Florida loans. The $5.00 is assessed for the direct costs
                associated with the verification of the borrower’s identity and
                employment information. This $5.00 fee is included in the APR
                advertised above and on our website.{" "}
              </p>
              <p>
                <b>Notices</b>
              </p>
              <p>
                The maximum Annual Percentage Rate Fig Loans will charge on an
                installment loan is 221%. This lender is regulated by Florida
                Office of Financial Regulations, Division of Finance. In the
                event of a complaint or concern, Florida residents may contact
                the:
                <br />
                <br />
                Florida Office of Financial Regulations, Division of Finance
                <br />
                Address: 200 E. Gaines Street, Tallahassee, FL 32399-0381.
                <br />
                Phone: (850) 410-9805.
                <br />
                Website: https://www.flofr.com.
              </p>
              <p>
                For help with consumer credit counseling, please refer to this
                Guide of Consumer Credit Counselors approved by the Florida
                Division of Finance:
                https://www.flofr.com/sitePages/documents/ActiveCreditCounselors.pdf
              </p>
              <p>
                <b>LICENSES</b>
              </p>
              <p>
                Fig Loans is a lender licensed by the State of Florida, Division
                of Finance.
              </p>
              <p>
                <img
                  src="https://figloans-static-images.s3.us-west-2.amazonaws.com/licenses/florida_license_2023.jpg"
                  alt="Lending License"
                  className="img-responsive faq-image"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloridaRates;
