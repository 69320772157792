import React, { useState, useEffect } from "react";
import { useScrollPosition } from "../helpers/useScrollPosition";
import { createCookie } from "../helpers/cookie_helper";

export default function BottomSheet(props) {
  const [hidden, setHidden] = useState(props.triggerOnScroll ? true : false);
  const [closed, setClosed] = useState(false);

  if (props.triggerOnScroll) {
    useScrollPosition(
      ({ currPos }) => {
        if (
          currPos.y >
          (document.body.scrollHeight - window.innerHeight) * 0.25
        )
          setHidden(false);
      },
      [],
      null,
      true,
      250
    );

    useEffect(() => {
      if (closed) {
        setHidden(true);
      }
    }, [closed]);
  }
  return (
    <div
      className={`bottom-sheet ${props.thankYou ? "thank-you" : ""} ${
        closed || hidden ? "hidden" : ""
      }`}
    >
      <p
        className="close"
        onClick={e => {
          setClosed(true);
          createCookie("newsletter", "true", 1);
        }}
      >
        X
      </p>
      {props.children}
    </div>
  );
}
