export const Pages = Object.freeze({
  PLAID_INCOME_VERIFICATION: Symbol('PlaidIncomeVerification'),
  FUNDING_WRAPPER: Symbol('FundingWrapper'),
  INCOME_VERIFICATION: Symbol('IncomeVerification'),
  INCOME_VERIFICATION_PENDING: Symbol('IncomeVerificationPending'),
});

export const ApplicationStage = Object.freeze({
  EMAIL_AND_PASSWORD: 1,
  CONTACT_INFO: 3,
  SECURE_INFO: 4,
  BANK_VERIFICATION: 5,
  APPROVAL_PENDING: 6,
  DENIED: -7,
  PROFILE: 7,
  CURRENT_LOAN: 8,
  INCOME_VERIFICATION: 9,
  PROFILE_TWO: 10,
  APP_FINISH: 11
})