import { domain } from "../helpers/route_helper";

export const login = user => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/session`,
    data: user
  });
};

export const logout = () => {
  return window.$.ajax({
    method: "DELETE",
    url: `${domain}/api/session`
  });
};

export const fetchUserInfo = email => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/session`
  });
};

export const fetchPaymentNextCutOff = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/session/next_cut_off`
  });
};

export const forceLogin = forced => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/session`,
    data: { forced }
  });
};
