import {recordUtm} from '../../util/user_api_util'
import {readCookie, createCookie} from '../../helpers/cookie_helper'
export function storageAvailable(type) {
    var storage;
    try {
        storage = window[type];
        var x = '__storage_test__';
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    }
    catch(e) {
        return e instanceof DOMException && (
            // everything except Firefox
            e.code === 22 ||
            // Firefox
            e.code === 1014 ||
            // test name field too, because code might not be present
            // everything except Firefox
            e.name === 'QuotaExceededError' ||
            // Firefox
            e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            (storage && storage.length !== 0);
    }
}

export function storeUtm(utm){
    if (readCookie('utm') === null){
      createCookie('utm', "{}")
    }
    var utmStore = JSON.parse(readCookie('utm'))
    utmStore[Date.now()] = utm
    createCookie('utm', JSON.stringify(utmStore))
  }

export function recordUtmToDatabase(userID, loanID=null, comment=null){
  if (![null,"{}"].includes(readCookie('utm'))) {
    let utmStore = JSON.parse(readCookie('utm'))
    let lastUtmKey = Object.keys(utmStore)[Object.keys(utmStore).length - 1]
    utmStore[lastUtmKey][comment] = comment
    recordUtm(userID, loanID, utmStore).then(response => {
      if (response.success){
        createCookie('utm', '{}')
      }
    })
  }
}
