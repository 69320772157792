import * as InfoUtil from "../util/info_api_util";
import { changeLoading } from "./loader_actions";
export const RECEIVE_PRODUCTS = "RECEIVE_PRODUCTS";
export const RECEIVE_STATE = "RECEIVE_STATE";

export const receiveProducts = products => ({
  type: RECEIVE_PRODUCTS,
  products
});
export const receiveState = state => ({
  type: RECEIVE_STATE,
  state
});

export const getProducts = (state, user_id, source, partner_id) => dispatch =>
  InfoUtil.getProducts(state, user_id, source, partner_id).then(products => {
    dispatch(receiveProducts(products));
    dispatch(changeLoading(false));
  }, null);

export const setState = state => dispatch => dispatch(receiveState(state));

export const submitResume = resume => dispatch =>
  InfoUtil.submitResume(resume).then(null, () =>
    dispatch(changeLoading(false))
  );

export const submitJobCSS = (token, response_1, response_2) => dispatch =>
  InfoUtil.submitJobCSS(token, response_1, response_2).then(null, () =>
    dispatch(changeLoading(false))
  );

export const registerJobCSSStart = token => dispatch =>
  InfoUtil.registerJobCSSStart(token).then(null, () =>
    dispatch(changeLoading(false))
  );
