import React from "react";
import MetaTags from "../MetaTags";

const CreditReportingDetailed = () => {
  let metaTagData = {
    title: "Installment Loans to Impact Your Credit Score",
    canonical: "https://www.figloans.com/loan-information/",
    og_title: "Installment Loans to Impact Your Credit Score",
    twitter_title: "Installment Loans to Impact Your Credit Score",
    description:
      "Fig's installment loan was designed to help improve your credit score - on-time payments are reported to all three credit bureaus.",
    og_description:
      "Fig's installment loan was designed to help improve your credit score - on-time payments are reported to all three credit bureaus.",
    twitter_description:
      "Fig's installment loan was designed to help improve your credit score - on-time payments are reported to all three credit bureaus.",
    og_image:
      "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/social/og_image_default.jpg",
    twitter_image:
      "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/social/twitter_image_default.jpg",
  };

  return (
    <div className="container">
      <MetaTags metaTagData={metaTagData} />
      <div className="panel-grey">
        <div className="mission">
          <div className="mission-text">
            <h4>How does Fig impact my credit score?</h4>
            <p>
              Let’s look at how your credit score is impacted at every stage of
              your Fig experience:
            </p>
            <div className="horizontal_list">
              <ul>
                <li>
                  <a href="#introduction">Introduction</a>
                </li>
                <li>
                  <a href="#application">Application</a>
                </li>
                <li>
                  <a href="#acceptance">Acceptance</a>
                </li>
                <li>
                  <a href="#payment">Payment</a>
                </li>
                <li>
                  <a href="#completion">Completion</a>
                </li>
                <span className="stretch clear" />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="panel-white">
        <div className="row">
          <div className="glance">
            <h4 id="introduction">1. Introduction</h4>
            <p>
              Congratulations! Reading this along with all of the other great
              information on our site is the first step on the journey to
              improve your financial health. The journey is pretty hard to go
              alone, but Fig is here to provide the tools you need to succeed.
            </p>
            <p>
              The graph below is what a typical customer experiences over the
              course of their time with Fig . We will be using this graph to
              help you understand how each event in your Fig experience affects
              your credit score.
            </p>
            <div className="image-container">
              <p className="example_title">
                <b>Credit score changes over lifetime of Fig Loan</b>
              </p>
              <img
                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/informational/credit_score_graph.jpg"
                alt="average-credit-score-experience"
                style={{ width: "100%", display: "block" }}
              />
              <p className="disclaimer">
                1. This graph is for illustrative purposes only and is not drawn
                to scale. Fig makes no representations or warranties that this
                is representative of your experience.
              </p>
            </div>
            <p>
              Before we dive in, it is important to recognize that your
              financial health is just like your physical health—it’s unique to
              you and only you. In terms of the graph above, everyone has a
              different "Starting Score" because of different items on their
              credit report. This guide is based on a study in 2016 we conducted with
              TransUnion and interviews with you, our customers. Since Fig isn’t
              a credit bureau, we don’t actually calculate your credit score,
              which means that we don’t know exactly what factors are going into
              your score. However, we do promise to tell you everything we know
              and report everything accurately to help peel back the layers of
              this very confusing aspect of life.
            </p>
            <p>
              Let’s review some credit basics so that we’re all on the same
              page:
            </p>
            <div className="credit-intro-3-col-left">
              <p>
                <u>
                  <b>1. Your credit report</b>
                </u>
              </p>
              <p>
                Your credit report is like your report card. It includes the
                entire history of every credit payment you have made: credit
                card payments, mortgages, student loan payments and everything
                in between. All of the information that Fig reports on your loan
                shows up on your credit report.
              </p>
            </div>
            <div className="credit-intro-3-col-middle">
              <img
                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/informational/left_right_triangle.png"
                alt="average-credit-score-experience"
                style={{
                  height: "100%",
                  display: "block",
                  margin: "40px auto",
                }}
              />
            </div>
            <div className="credit-intro-3-col-right">
              <p>
                <u>
                  <b>2. Your credit score</b>
                </u>
              </p>
              <p>
                If your credit report is your report card, then your credit
                score is your GPA. The credit bureaus use all of the information
                on your credit report to calculate your credit score. Similar to
                how bad grades pull your GPA down, late or defaulted payments
                pull your credit score down. This is also why Fig’s impact on
                credit scores differs from person to person; it all depends on
                what kind of credit report you come to us with and how you perform
                with us.
              </p>
            </div>
            <div className="clear" />
          </div>
        </div>
      </div>
      <div className="panel-grey">
        <div className="row">
          <div className="overtime">
            <h4 id="application">2. Applying for the Loan</h4>

            <h5>Applying for a Fig loan DOES NOT affect your credit score!</h5>

            <div className="overtime-text">
              <p>
                We perform a “soft inquiry” into your credit file. Financial
                health websites like CreditSesame and Credit Karma perform soft
                inquiries on your credit report all the time to determine what
                credit card, mortgage and loan offers to show you. Ever wonder why
                credit card companies send you an offer instead of your neighbor?
                {"It's"} because the companies did a soft inquiry and determined that
                you fit their customer profile.
              </p>
              <p>
                So, if Fig does a “soft” inquiry that doesn’t impact your credit
                score, then what type of inquiry may negatively impact your credit
                score? “Hard” inquiries. Hard inquiries lower your credit score
                and stay on your credit report for an average of 2 years because
                taking out multiple long term credit products at the same time
                isn’t a great look (more on this later). Since hard inquiries
                lower your credit score, you should know what type of lending
                product you’re looking for beforehand rather than applying for
                many products and picking after.
              </p>
            </div>
            <div className="overtime-image">
              <img
                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/informational/fig_app_iphone.png"
                alt="average-credit-score-experience"
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="clear" />
          </div>
        </div>
      </div>
      <div className="panel-white">
        <div className="row">
          <div className="glance">
            <h4 id="acceptance">3. Accepting the Loan</h4>
            <div className="credit-accept-25-col-left">
              <img
                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/informational/credit_score_accepting.jpg"
                alt="average-credit-score-experience"
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="credit-accept-75-col-right">
              <p>
                Since accepting your loan signifies an opening of a new credit
                line, it is the first action Fig reports to the credit bureaus
                on your behalf. Generally, this has a neutral or a slightly
                negative impact on our customers’ credit reports.
              </p>
              <p>
                Opening up a new line of credit can have a neutral impact
                because the action indicates that you are a lendable customer.
              </p>
              <p>
                Taking out a new loan can also have a slightly negative impact
                on your credit scores, as you now have a higher debt ratio. It’s
                important to realize that everyone, even billionaires, has a
                limited amount of credit. This line of credit is tied to your
                income. For example, suppose that you earn $50,000 a year.
                Repaying a $500 loan is probably not a problem for you. However,
                repaying a $5,000,000 loan would be pretty difficult (unless you
                won the lottery… in which case, carry on!). Credit bureaus and
                lenders are cautious of this income-to-debt ratio and will offer
                lower lines of credit if you have existing loans.
              </p>
            </div>
            <div className="clear" />
            <p className="disclaimer">
              Above graph is for illustrative purposes only and is not drawn to
              scale. Fig makes no representations or warranties that this is
              representative of your experience.
            </p>
          </div>
        </div>
      </div>

      <div className="panel-grey">
        <div className="row">
          <div className="overtime">
            <h4 id="payment">4. Repaying the Loan</h4>
            <div className="image-container">
              <p className="example_title" />
              <img
                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/informational/credit_score_payments.jpg"
                alt="average-credit-score-experience"
                style={{ width: "100%", display: "block" }}
              />
              <p className="disclaimer">
                Above graph is for illustrative purposes only and is not drawn
                to scale. Fig makes no representations or warranties that this
                is representative of your experience.
              </p>
            </div>
            <p>
              Making on-time payments on your Fig loan is the most important
              part of building good credit. On-time payments show other lenders
              that you are able to stick to a lending contract.
            </p>
            <p>
              The best way to understand this is to think about this from the
              perspective of a mortgage lender. Mortgages are usually 10 to 30
              year loans that require regular monthly payments. Like any long
              term financial relationship, these lenders want to be 100%
              confident that you will hold your end of the bargain. Because most
              about 35% of Americans {"don't"} have much history taking out mortgages,
              the lender will look at your other lines of credit to see whether you have
              kept your commitment in the past.
            </p>
            <p>
              Credit card companies and auto-lenders think similarly and look at
              your payment history on other credit products as gauge when making
              lending decisions. This is why your payment history is the most important
              factor in calculating your credit score.
            </p>
          </div>
        </div>
      </div>

      <div className="panel-white">
        <div className="row">
          <div className="glance">
            <h4 id="completion">5. Completing Your Loan</h4>

            <div className="credit-accept-75-col-left">
              <p>
                Once you’ve finished making all of your payments on your Fig
                loan, we report the loan as “Paid Off”. Having a loan as paid
                off is obviously a good thing, right?
              </p>
              <p>
                Well… it’s more complicated than that. Unfortunately, the credit
                bureaus view paying off an installment loan and closing an
                installment loan as the same thing, even though Fig always closes
                a loan that has been paid off, and a loan must be paid off for it
                to close. Because the credit bureaus don’t make this distinction,
                the month after you finishing paying off your Fig loan, you will
                likely see a slight decrease in your credit score because you have
                one less paying account on your credit report. Don’t worry! This
                should only be a temporary decrease.
              </p>
              <p>
                While your Fig Loan may be considered a closed account, it still
                has a positive impact on your credit report because of the
                consistent payment history. This is a positive indicator that
                will stay on your credit report for 10 years!
              </p>
            </div>
            <div className="credit-accept-25-col-left">
              <img
                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/informational/credit_score_completing.jpg"
                alt="average-credit-score-experience"
                style={{ width: "100%", display: "block" }}
              />
            </div>
            <div className="clear" />
            <p className="disclaimer">
              Above graph is for illustrative purposes only and is not drawn to
              scale. Fig makes no representations or warranties that this is
              representative of your experience.
            </p>
          </div>
        </div>
      </div>
      <div className="panel-grey">
        <div className="row">
          <div className="overtime">
            <p>
              Thanks for taking the time to learn more about credit scores! At
              Fig, we’re not focused on being the biggest lender out there.
              We’re not even focused on being the tenth biggest. We’re focused
              on helping hard-working Americans get the financial products they
              deserve. Step 1 is the opportunity to build healthy credit.
            </p>
            <p>
              Disclaimer: Our studies are done on our past customers’
              experiences and situations in 2016. You may have a different experience
              based on your unique situation.
            </p>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  );
};

export default CreditReportingDetailed;
