export const RECEIVE_BANNER = "RECEIVE_BANNER";

export const receiveBanner = (message, style, duration) => ({
  type: RECEIVE_BANNER,
  style: style,
  message: message,
  duration: duration
});

export const changeBanner = (message, style, duration) => dispatch =>
  dispatch(receiveBanner(message, style, duration));