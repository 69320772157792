import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers/root_reducer";

const middlewares = [thunk];

if (process.env.NODE_ENV === "development") {
  // must use 'require' (import only allowed at top of file)
  const { logger } = require("redux-logger");
  middlewares.push(logger);
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = (preloadedState = {}) => {
  const store = createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept("../reducers/root_reducer", () => {
      const nextRootReducer = require("../reducers/root_reducer");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
};

export default configureStore;
