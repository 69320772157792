import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";

const Footer = ({ location, handleCookiePreferencesPopUpButtonClick }) => {
  const [scrolled, setScrolled] = useState(false);

  if (!scrolled)
    window.addEventListener(
      "scroll",
      () => {
        if (!scrolled) {
          setScrolled(true);
        }
      },
      { once: true }
    );

  if (!scrolled) {
    return <div />;
  }
  let footerText,
    footerLinks = [];

  switch (location) {
    case "TX":
      footerText =
        "An advance of money obtained through a payday loan or auto title loan is not intended to meet long-term financial needs.  A payday loan or auto title loan should only be used to meet immediate short-term cash needs.  Refinancing the loan rather than paying the debt in full when due will require the payment of additional charges.";
      footerLinks = [
        <Link to="/cso">Credit Service Organization</Link>,
        <Link to="/cab">Credit Access Business</Link>,
        <Link to="/license_cab">CAB License</Link>
      ];
      break;
    case "MO":
      footerText =
        "As of May 2018, Fig has launched in the great state of Missouri! From Kansas City to St.Louis to Joplin, we're bringing our affordable payday alternative loans and credit builder loans to Walt Disney's home state!";
      footerLinks = [<Link to="/license_mo">Lender License</Link>];
      break;
    case "OH":
      footerText =
        "As of May 2019, Fig has launched in the great state of Ohio! From Cleveland to Columbus, we're bringing our affordable payday alternative loans and credit builder loans to Lebron James' home state!";
      footerLinks = [<Link to="/license_oh">Lender License</Link>];
      break;
    case "UT":
      footerText =
        "As of March 2019, Fig has launched in the great state of Utah! From Salt Lake City to St. George to Ogden, we're bringing our affordable payday alternative loans and credit builder loans to the Beehive State!";
      footerLinks = [<Link to="/license_ut">Lender License</Link>];
      break;
    default:
      footerText =
        "An advance of money obtained through a payday loan or auto title loan is not intended to meet long-term financial needs.  A payday loan or auto title loan should only be used to meet immediate short-term cash needs.  Refinancing the loan rather than paying the debt in full when due will require the payment of additional charges.";
      break;
  }
  return (
    <section className="section-copyright">
      <div className="content-container">
        <div className="content group disclosures-and-footer-social">
          <div className="column about desktop" id="advice">
            <h3>Some Last Advice!</h3>
            <p>{footerText}</p>
          </div>
          <div id="footer-disclosure" className="column disclosures desktop">
            <h4>Disclosures</h4>
            <a onClick={handleCookiePreferencesPopUpButtonClick} className="pointer">Do Not Sell Or Share My Personal Information</a>
            <p>
              <Link to="/esign">Electronic Signature</Link>
              <br />

              <Link to="/privacy">Privacy Policy</Link>

              <br />

              <Link to="privacy-glba">GLBA Financial Privacy Notice</Link>

              <br />

              <Link to="/testimonial_disclaimer">Testimonial Disclaimer</Link>
              {footerLinks.map(link => (
                <span key={Math.random()}>
                  <br />
                  {link}
                </span>
              ))}
              <br />
              <Link to="ccpa-opt-out">California Privacy Statement</Link>
              <br />
              <a href={process.env.PUBLIC_URL + "/static-archive.html"}>
                Site Archive
              </a>
            </p>
            <p>
              * Payment amounts may vary based on loan terms.
              { location === "FL" && " A $5 verification fee will be added to the first payment." }
            </p>
            <p>
              ** Advertised APRs are exemplary and may change based on
              application quality.
            </p>
          </div>
          <div id="footer-disclosure" className="column disclosures mobile">
            <h4>Disclosures</h4>
            <p>
              <Link to="/privacy">Privacy Policy</Link>
            </p>
            <p>
              <Link to="/esign">Electronic Signature</Link>
            </p>
            <p>
              <Link to="/testimonial_disclaimer">Testimonial Disclaimer</Link>
            </p>
            <p>
              {footerLinks.map(link => (
                <span key={Math.random()}>{link}</span>
              ))}
            </p>
            <p>
              * Payment amounts may vary based on loan terms.
              { location === "FL" && " A $5 verification fee will be added to the first payment." }
            </p>
            <p>
              ** Advertised APRs are exemplary and may change based on
              application quality.
            </p>
          </div>
          <div className="column social">
            <a href="http://goo.gl/oFQ92x">
              <img
                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/figlogo_transparent.png"
                alt="Fig Loans"
              />
            </a>
            <div className="social_icons">
              <a href="https://www.facebook.com/figloans/" id="icons_facebook">
                <i className="fab fa-facebook-square fa-2x" />
              </a>
              <br />
              <a href="https://twitter.com/figloans" id="icons_twitter">
                <i className="fab fa-twitter fa-2x" />
              </a>
              <br />
              <a href="https://instagram.com/figloans" id="icons_instagram">
                <i className="fab fa-instagram fa-2x" />
              </a>
              <br />
              <div className="email" id="icons_email">
                <a href="mailto:service@figloans.com">
                  <i className="far fa-envelope fa-2x" />
                </a>
              </div>
              <div id="address">
                Corporate address: 2245 Texas Drive Ste. 300, Sugar Land, Texas 77479
                <br/>
                Check mailing address: 9450 SW Gemini Dr., Suite 93137, Beaverton, Oregon 97008-7105
                <br />
                Toll Free Number: 1-833-335-0855
              </div>
            </div>
              <p>
                Fig Loans Business Hours
                <br />
                Monday - Friday: 8am - 4pm CST
              </p>
          </div>
          <div className="mobile-app-download-container">
            {/* <a href="https://apps.apple.com/us/app/fig-loans/id1455664656">
              <img
                className="mobile-app-download"
                src="https://figloans-static-images.s3.us-west-2.amazonaws.com/badges/download_on_app_store.png"
                alt="App Store Download Button - Fig Loans"
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.figloans">
              <img
                className="mobile-app-download"
                src="https://figloans-static-images.s3.us-west-2.amazonaws.com/badges/google-play-badge.png"
                alt="Google Play Download Button - Fig Loans"
              />
            </a> */}
          </div>
          <a id="fig-logo-2020" href="/what-is-fig">
            {" "}
            <img
              src="https://figloans-static-images.s3.us-west-2.amazonaws.com/logos/fig-logo-2020+(1).png"
              alt="Fig Loans"
              className="footer-logo"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = state => ({
  location: state.info.location
});

export default withRouter(connect(mapStateToProps)(Footer));
