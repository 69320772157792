import React from "react";
import { Helmet } from "react-helmet";

const OhioRates = ({ products }) => {
  products = products || {
    loans: {
      traditional_loan: {
        "200": { 4: { pmt: 72.31, apr: 1.99 } },
        "250": { 4: { pmt: 90.39, apr: 1.99 } },
        "300": { 4: { pmt: 108.46, apr: 1.99 } },
        "350": { 4: { pmt: 122.66, apr: 1.8034 } },
        "400": { 4: { pmt: 135.9, apr: 1.621 } }
      }
    }
  };
  const length1 = Object.keys(products.loans.traditional_loan["200"])[0];
  const length2 = Object.keys(products.loans.traditional_loan["250"])[0];
  const length3 = Object.keys(products.loans.traditional_loan["300"])[0];

  const apr1 = products.loans.traditional_loan["200"][length1].apr;
  const apr2 = products.loans.traditional_loan["250"][length2].apr;
  const apr3 = products.loans.traditional_loan["300"][length3].apr;

  const payment1 = products.loans.traditional_loan["200"][length1].pmt;
  const payment2 = products.loans.traditional_loan["250"][length2].pmt;
  const payment3 = products.loans.traditional_loan["300"][length3].pmt;

  return (
    <div className="container">
      <Helmet>
        <title>Ohio Rates and Notices | Fig Loans</title>
        <meta
          name="description"
          content="Do you need a loan in Ohio? Fig Loans has the friendliest terms in the state!"
        />
        <meta
          property="og:description"
          content="Do you need a loan in Ohio? Fig Loans has the friendliest terms in the state!"
        />
      </Helmet>
      <div className="panel-grey">
        <div id="ohio">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className='modal-heading'>Ohio</h4>
              </div>
              <p>
                Loan costs depend on the amount advanced and length of the loan.
              </p>
              <p>
                <b>Fee Examples</b>
              </p>
              <p>
                <div className="disclosurewrap">
                  <div className="full-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Amount Advanced</th>
                          <td>$200.00</td>
                          <td>$250.00</td>
                          <td>$300.00</td>
                        </tr>
                        <tr>
                          <th>Loan Length</th>
                          <td>{length1 * 30} days</td>
                          <td>{length2 * 30} days</td>
                          <td>{length3 * 30} days</td>
                        </tr>
                        <tr>
                          <th>
                            Loan APR<sup>1</sup>
                          </th>
                          <td>{apr1 * 100}%</td>
                          <td>{apr2 * 100}%</td>
                          <td>{apr3 * 100}%</td>
                        </tr>
                        <tr>
                          <th>Interest Charge</th>
                          <td>
                            $
                            {(
                              Math.round(length1 * payment1 * 100) / 100 -
                              200
                            ).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(
                              Math.round(length2 * payment2 * 100) / 100 -
                              250
                            ).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(
                              Math.round(length3 * payment3 * 100) / 100 -
                              300
                            ).toFixed(2)}
                          </td>
                        </tr>
                        <tr>
                          <th>Total Repayment Amount</th>
                          <td>
                            $
                            {(
                              Math.round(length1 * payment1 * 100) / 100
                            ).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(
                              Math.round(length2 * payment2 * 100) / 100
                            ).toFixed(2)}
                          </td>
                          <td>
                            $
                            {(
                              Math.round(length3 * payment3 * 100) / 100
                            ).toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mobile-size">
                    <table>
                      <tr>
                        <th>Sample $200 Dollar {length1} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $200.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length1 * 30} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>
                          {apr1 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> $
                          {(
                            Math.round(length1 * payment1 * 100) / 100 -
                            200
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$
                          {(Math.round(length1 * payment1 * 100) / 100).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $250 Dollar {length2} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $250.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length2 * 30} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          {apr2 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> $
                          {(
                            Math.round(length2 * payment2 * 100) / 100 -
                            250
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br /> $
                          {(Math.round(length2 * payment2 * 100) / 100).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $300 Dollar {length3} Month Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $300.00
                        </td>
                        <td>
                          <b>Loan Length:</b> {length3 * 30} days
                        </td>
                        <td>
                          <b>
                            Loan APR<sup>1</sup>:
                          </b>{" "}
                          {apr3 * 100}%
                        </td>
                        <td>
                          <b>Interest Charge:</b> $
                          {(
                            Math.round(length3 * payment3 * 100) / 100 -
                            300
                          ).toFixed(2)}
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br /> $
                          {(Math.round(length3 * payment3 * 100) / 100).toFixed(
                            2
                          )}
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </p>
              <p className="footnote">
                1. The APR is based on a {length1} payment, {length1 * 30} day
                amortizing loan. The APR shown is based on the federal Truth in
                Lending Act and does not reflect specialized state APR
                calculations, if any.
                <br />
              </p>
              <p>
                The loan information shown here is an example and may not
                reflect the rates available on all loans. Before entering into
                any transaction, you should read the disclosure statements and
                contract that are provided to you by Fig Loans.
              </p>

              <p>
                {" "}
                <u> Optional Fees </u>{" "}
              </p>
              <p>
                {" "}
                You have the option of paying with a debit card. If you make a
                payment with a debit card, you will be charged a 3% processing
                fee that will be automatically added to your total payment.{" "}
              </p>
              <p>
                <b>Notices</b>
              </p>
              <p>
                The maximum Annual Percentage Rate Fig Loans will charge on an
                installment loan is {apr1 * 100}%. This lender is regulated by
                Ohio Division of Financial Institutions. In the event of a
                complaint or concern, Ohio residents may contact the:
                <br />
                <br />
                Ohio Division of Financial Institutions
                <br />
                Address: 77 South High Street 21st Floor, Columbus, OH
                43215-6120. <br />
                Phone: (614) 728-8400. <br />
                Fax: (614) 728-0380.
                <br />
                Website: www.com.ohio.gov/fiin. <br />
                E-mail: web.dfi@com.ohio.gov.
              </p>
              <p>
                <b>LICENSES</b>
              </p>
              <p>
                Fig Loans is a lender licensed by the State of Ohio Division of
                Finance.
              </p>
              <p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/licenses/2023/ohio_license.png"
                  alt="CAB License"
                  className="img-responsive faq-image"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OhioRates;
