import React from "react";

const Length = () => {
  return (
    <div className="row">
      <div className="overtime">
        <h4 id="lengthoftheloan">2. How long is the loan for?</h4>
        <p>
          The length of a loan is how long you will be paying the loan back.
          Together, the length and interest rate determine how much {"you'll"}{" "}
          ultimately pay back. The length of the loan is also referred to as the
          term or term length.
        </p>
        <br />
        <div className="image-container">
          <img
            src="https://s3.us-west-2.amazonaws.com/figloans-static-images/informational/rates_formula.png"
            style={{ width: "100%", display: "block" }}
            alt="Rates Location Graphic"
          />
          <p className="disclaimer">
            1. We call it Total Cost of Loan Without Fees because it does not
            include additional fees. Fees are unfortunately a large part of the
            Total Cost of a Loan and often not included in the APR of the loan.
          </p>
          <p className="disclaimer">
            2. This calculation should only be used for illustrative purposes
            regarding the impact of interest rates over time. It does not
            include concepts such as compounding and multiple payment periods.
            Please reach out to your lender directly for a more detailed
            calculation.
          </p>
        </div>
        <br />

        <div className="overtime-text">
          <p>
            Using the formula above, we calculated the Total Cost of a Loan
            Without Fees for a $100 loan over a 2 week, 1 month, 4 month and 6
            month loan term.
          </p>
          <p>
            The first thing you see is that a shorter loan will cost less in
            total.
          </p>
          <p>
            While this is true, it may not be the best payday loan because you
            must be able to make the payments! If you are unable to make a
            payment, the length of your loan increases and you will be charged
            extra fees.
          </p>
          <div className="web">
            <p>
              <u>
                <b>Loan Term Tip:</b>
              </u>
            </p>
            <p>
              Increasing the length of your unsecured loan increases the total
              amount you will pay back. It’s best to choose a payday lender that
              shows you exactly the how much each payment will cost and how many
              payments you need to make so there are no surprises.
            </p>
            <p>
              This leads us to the very important topic of payments. Knowing the
              payment schedule, how often you pay, and the amount you pay are
              crucial to successfully paying off your loan sucessfully. Let’s
              explore this topic below!
            </p>
          </div>
        </div>

        <div className="overtime-image">
          <div id="apr_des_table">
            <p className="example_title">
              <b>Example: Loan Term Comparison Chart for a $100 Loan.</b>
            </p>
            <table cellSpacing="0" cellPadding="0" border="0">
              <tbody>
                <tr>
                  <td className="header" />
                  <td className="header">APR</td>
                  <td className="header">Length of Loan</td>
                  <td className="header">$Paid per 100 (monthly)</td>
                  <td className="header">Total Cost of Loan Without Fees</td>
                </tr>
                <tr>
                  <td rowSpan="4">
                    <img
                      src="https://s3.us-west-2.amazonaws.com/figloans-static-images/peers/freedom_cash_r180.png"
                      className="apr_des_table_img"
                      alt="Payday Lender"
                    />
                  </td>
                  <td rowSpan="4">680%</td>
                  <td>2 weeks</td>
                  <td>$26.00</td>
                  <td>$26.00</td>
                </tr>
                <tr>
                  <td>1 month</td>
                  <td>$56.67</td>
                  <td>$56.67</td>
                </tr>
                <tr>
                  <td>4 months</td>
                  <td>$56.67</td>
                  <td>$226.67</td>
                </tr>
                <tr>
                  <td>6 months</td>
                  <td>$56.67</td>
                  <td>$340.00</td>
                </tr>
              </tbody>
            </table>
            <p className="disclaimer">
              1. This example uses hypothetical data and is only for
              illustrative purposes.
            </p>
          </div>
        </div>
        <div className="mobile">
          <p>
            <u>
              <b>Loan Length Tip:</b>
            </u>
          </p>
          <p>
            When you increase the length of your unsecured loan it increases the
            total amount you will pay in total. It’s best to choose a payday
            lender that shows you exactly the number and amount of each payment
            so there are no surprises.
          </p>
          <p>
            This leads us to the very important topic of payments. Knowing the
            payment schedule, how often you pay and the amount you pay are
            crucial to successfully paying off your loan sucessfully. Let’s
            explore this topic below!
          </p>
        </div>
        <div className="clear" />
      </div>
    </div>
  );
};

export default Length;
