import React, { useEffect, useState } from "react";
import { list_of_reviews } from "../helpers/reviews_helper";

import Trustbox from "./shared/Trustbox";

// Reusable Star Component to Display Animated Ratings
import StarRating from "./shared/StarRating";
import { Redirect } from "react-router";

import MaleRed from "../svg/male_avatar_red";
import FemaleFigGreen from "../svg/female_avatar_fig_green";
import FemaleRed from "../svg/female_avatar_red";
import MaleFigGreen from "../svg/male_avatar_fig_green";

import { AiOutlineGoogle } from "react-icons/ai";

const reviewImages = [MaleRed, FemaleFigGreen, FemaleRed, MaleFigGreen];

const Reviews = () => {
  const [applied, setApplied] = useState(false);

  if (applied) {
    return <Redirect to="/app" />;
  }

  return (
    <div className="reviews">
      <section className="reviews-section review-header">
        <h1 className="reviews-title top-title">Customers Love Fig.</h1>
        <h3 className="reviews-sub-title">Hear why from a real Fig customer</h3>
        <div className="video-review-container">
          <iframe
            width="100%"
            height="250"
            data-src="https://www.youtube.com/embed/2L957ODxqvg?modestbranding=1&controls=0&showinfo=0&rel=0&fs=1"
            class="optanon-category-C0005"
            controls="0"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className="reviews-video"
          ></iframe>
        </div>
        <p className="reviews-text">
          Fig is Voted Best Loan for Bad Credit because we meet borrowers where
          they are, when they need us. Our Customer Service team is always
          responsive and ready to help with every step of the Fig journey. From
          building positive credit history to updating payment schedules, we are
          here for you.
        </p>
      </section>
      <section className="flex-container">
        <div className="accomplishments">
          <div className="flex-section">
            <StarRating size={25} value={4.7} />
            <a href="https://www.google.com/maps/place/Fig+Loans/@29.5966876,-95.6201889,15z/data=!3m1!5s0x8640e6946e2e1c29:0x7edf0a3a27ffd5a5!4m7!3m6!1s0x0:0x2a88430716ac94c4!8m2!3d29.5966876!4d-95.6201889!9m1!1b1">
              <img
                src="https://figloans-static-images.s3.us-west-2.amazonaws.com/reviews/google-logo.png"
                className="google-logo"
                alt="Google Logo"
              />
            </a>
          </div>
          <div className="flex-section">
            <div className="reviews-trustbox">
              <Trustbox forcedHeight="120px" size={"big"} />
            </div>
            <div className="reviews-trustbox-mobile">
              <Trustbox forcedHeight="120px" />
            </div>
          </div>
          <div className="flex-section">
            <img
              src="https://figloans-static-images.s3.us-west-2.amazonaws.com/reviews/b-corp.png"
              alt="B Corp Certified Logo"
              className="reviews-b-corp"
            ></img>
          </div>
        </div>
      </section>
      <section className="reviews-display-section">
        {list_of_reviews.map((review, i) => {
          return <ReviewDisplay key={i} index={i} review={review} />;
        })}
      </section>
      <section className="reviews-cta">
        <h2 className="cta-title">What are you waiting for?</h2>
        <button onClick={() => setApplied(true)} className="cta-button">
          Apply Today
        </button>
      </section>
    </div>
  );
};

export default Reviews;

const ReviewDisplay = ({ index, review }) => {
  let Image = reviewImages[Math.floor(Math.random() * reviewImages.length)];

  return (
    <div
      className={
        index % 2 ? "avatar-review-container rrev" : "avatar-review-container"
      }
    >
      <div className={"avatar-container"}>
        <Image />
      </div>
      <div className="review-container">
        <p className="review">{review.quote}</p>
        <p className="author">
          {review.info}
          <a target="_blank" href={review.link}>
            <AiOutlineGoogle className="review-icon" />
          </a>
        </p>
      </div>
    </div>
  );
};
