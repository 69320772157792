import React from "react";

const BCorp = () => {
  return (
    <div className="content group no-top-padding no-bot-padding">
      <div className="column main full no-bot-padding">
        <div id="press-container">
          <div className="row">
            <div className="center bcorp">
              <a
                href="https://www.bcorporation.net/en-us/find-a-b-corp/company/fig-loans/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/press/bcorp_logo.png"
                  className="logo"
                  alt="BCorp Logo"
                />
              </a>
            </div>
            <div className="center press-text">
              <i>
                “Good Housekeeping puts its seal of approval on quality
                products, like washing machines... since 2006, a nonprofit
                organization called B Lab has been certifying corporations it
                deems to be concerned about their communities and the
                environment.” - NPR
              </i>
            </div>
          </div>
        </div>
      </div>
      <div className="column phablet">
        <div id="press-container">
          <div>
            <img
              src="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/press/bcorp_logo.png"
              className="logo"
              alt="BCorp Logo"
            />
          </div>
          <div className="center press-text">
            <i>
              “Good Housekeeping puts its seal of approval on quality products,
              like washing machines... since 2006, a nonprofit organization
              called B Lab has been certifying corporations it deems to be
              concerned about their communities and the environment.” - NPR
            </i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BCorp;
