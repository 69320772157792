let dom, curr, tabapay, beirut;

if (process.env.NODE_ENV === "development") {
  // dom = "https://app.figloans.com";
  dom = "http://localhost";
  curr = "http://localhost:3000";
  tabapay = "https://sso.sandbox.tabapay.com:8443/SSOFigLoans.html";
  beirut = "http://localhost:3000";
} else if (window.location.host.includes("figloans.com")) {
  dom = "https://app.figloans.com";
  curr = "https://figloans.com";
  tabapay = "https://sso.tabapay.com/SSOFigLoans.html";
  beirut = "https://partners.figloans.com";
} else if (window.location.host.includes("new-s-route")) {
  dom =
    "https://fig-web.new-s-route-1koobwz3hnwns-78065161.us-west-2.convox.site";
  curr =
    "https://figfrontend-web.new-s-route-1koobwz3hnwns-78065161.us-west-2.convox.site";
  tabapay = "https://sso.sandbox.tabapay.com:8443/SSOFigLoans.html";
  beirut = "https://partners.figloans.com";
} else {
  dom = "https://app.figloans.com";
  curr = "https://figloans.com";
  tabapay = "https://sso.tabapay.com/SSOFigLoans.html";
  beirut = "https://partners.figloans.com";
}

export const domain = dom;
export const current = curr;
export const tabapay_sso = tabapay;
export const partner_server = beirut;
