import * as ErrorUtil from "../util/errors_api_util";
export const RECEIVE_SESSION_ERRORS = "RECEIVE_SESSION_ERRORS";
export const RECEIVE_USER_ERRORS = "RECEIVE_USER_ERRORS";
export const RECEIVE_BLOG_ERRORS = "RECEIVE_BLOG_ERRORS";
export const RECEIVE_STATEMENT_ERRORS = "RECEIVE_STATEMENT_ERRORS";
export const RECEIVE_VIDEO_ERRORS = "RECEIVE_VIDEO_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

export const receiveSessionErrors = errors => ({
  type: RECEIVE_SESSION_ERRORS,
  errors
});

export const receiveUserErrors = errors => ({
  type: RECEIVE_USER_ERRORS,
  errors
});

export const receiveBlogErrors = errors => ({
  type: RECEIVE_BLOG_ERRORS,
  errors
});

export const receiveStatementErrors = errors => ({
  type: RECEIVE_STATEMENT_ERRORS,
  errors
});

export const receiveVideoErrors = errors => ({
  type: RECEIVE_VIDEO_ERRORS,
  errors
});

export const receiveClearErrors = () => ({
  type: CLEAR_ERRORS
});

export const logErrors = error => dispatch =>
  ErrorUtil.logErrors(error).then(null, errors =>
    dispatch(receiveSessionErrors(errors))
  );

export const clearErrors = () => dispatch => dispatch(receiveClearErrors());
