import React from "react";
import { Helmet } from "react-helmet";
import { current } from "../helpers/route_helper";

const MetaTags = ({ metaTagData }) => {
  return (
    <Helmet>
      <title>{metaTagData.title}</title>
      <meta name="description" content={metaTagData.description} />
      <meta property="og:url" content={current + window.location.pathname} />
      <meta property="og:title" content={metaTagData.og_title} />
      <meta property="og:description" content={metaTagData.og_description} />
      <meta property="og:image" content={metaTagData.og_image} />
      <meta name="twitter:image" content={metaTagData.twitter_image} />
      <meta name="twitter:title" content={metaTagData.twitter_title} />
      <meta
        name="twitter:description"
        content={metaTagData.twitter_description}
      />
      <meta name="twitter:site" content="@figloans" />
      {metaTagData.canonical ? (
        <link rel="canonical" href={metaTagData.canonical} />
      ) : null}
      {metaTagData.canonical_link ? (
        <link rel="canonical" href={metaTagData.canonical_link} />
      ) : null}
    </Helmet>
  );
};

export default MetaTags;
