import { domain } from "../helpers/route_helper";

export const submitInquiry = (user_id, body, file) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/inquiry`,
    data: { user_id, body, file },
  });
};

export const submitNewBank = (user_id, time_displayed, bank_account) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/new_bank`,
    data: { user_id, time_displayed, bank_account },
  });
};

export const addPaymentCard = (debit_card, address, time_displayed) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/add_payment_card`,
    data: { debit_card, address, time_displayed },
  });
};

export const removeDebitCard = (card_number_last_4) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/remove_card_as_primary`,
    data: { card_number_last_4 },
  });
};

export const submitPaymentChange = (loan_id, payment_id, date) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/api/change_payment`,
    data: { payment: { loan_id, payment_id, date } },
  });
};

export const createPayment = (user_id, payment_type, payment_id) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/create_payment`,
    data: { user_id, payment_type, payment_id },
  });
};

export const retrievePayment = (loan_url) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/retrieve_payment`,
    data: { loan_url },
  });
};

export const resetPassword = (email, callback) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/reset_password`,
    data: { email },
    success: () => callback(),
  });
};

export const unlockAccount = (unlockToken, callback) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/unlock_account`,
    data: { unlockToken },
    success: () => callback(),
  });
};

export const submitPassword = (info, callback) => {
  return window.$.ajax({
    method: "PUT",
    url: `${domain}/api/users/password`,
    data: { info },
    success: () => callback(),
  });
};

export const updateUtm = (user_id, utm, other) => {
  return window.$.ajax({
    method: "PATCH",
    url: `${domain}/api/update_utm`,
    data: { user_id, utm, other },
  });
};

export const recordUtm = (user_id, loan_id, utm, comment) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/record_utm`,
    data: { user_id, utm, comment },
  });
};

/*
 * @deprecated as a result of OH 2023 Exam Remediations
 * Fig has ended the referral program in all states
 */
export const sendReferral = (user_id, type, contact) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/send_referral`,
    data: { user_id, type, contact },
  });
};

export const requestRepresentation = (payment_id) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/request_representation`,
    data: { payment_id },
  });
};

export const repayLoan = (id, ssn, debit_card, address, amt) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/repay_loan`,
    data: { id, ssn, debit_card, address, amt },
  });
};

export const payBalance = (loan_id, method) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/repay_loan`,
    data: { loan_id, method },
  });
};

export const earlyRepayCalculate = (loan_id, date) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/early_repay_calculate`,
    data: { loan_id, date },
  });
};

export const createEarlyRepay = (loan_id, date) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/create_early_repay`,
    data: { loan_id, date },
  });
};

export const customRepay = (
  id,
  ssn,
  increment,
  frequency,
  start,
  bank_account,
  debit_card,
  address
) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/custom_repay`,
    data: {
      id,
      ssn,
      increment,
      frequency,
      start,
      bank_account,
      debit_card,
      address,
    },
  });
};

export const getEfta = (user_id, new_account_type, new_routing_number, new_account_number, new_card_number) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/get_efta`,
    data: { user_id, new_account_type, new_routing_number, new_account_number, new_card_number },
  });
};

export const updateLoanReason = (user_id, loan_reason) => {
  window.$.ajax({
    method: "POST",
    url: `${domain}/api/update_loan_reason`,
    data: { user_id, loan_reason },
  });
};

export const newsletterSignup = (email) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/newsletter_signup`,
    data: { email },
  });
};

export const ipLookup = () => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/ip_lookup`,
  });
};

export const submitCCPARequestForm = (form) => {  
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/ccpa_request_form`,
    data: { form },
  });
};