import { merge } from "lodash";

const setupPolyfills = () => {
  // polyfill for URLSearchParams which isnt on IE
  (function (w) {
    w.URLSearchParams =
      w.URLSearchParams ||
      function (searchString) {
        var self = this;
        self.searchString = searchString;
        self.get = function (name) {
          var results = new RegExp("[&]" + name + "=([^&#]*)").exec(
            self.searchString
          );
          if (results == null) {
            return null;
          } else {
            return decodeURI(results[1]) || 0;
          }
        };
      };
  })(window);

  // lightweight replacement for jquery
  class DOMNodeCollection {
    constructor(arr) {
      this.HTMLarr = arr;
    }

    html(innerText) {
      if (innerText) {
        this.HTMLarr.forEach((node) => (node.innerHTML = innerText));
      } else {
        return this.HTMLarr[0].innerHTML;
      }
    }

    index(idx) {
      return this.HTMLarr[idx];
    }

    empty() {
      this.HTMLarr.forEach((node) => (node.innerHTML = ""));
    }

    each(callback) {
      this.HTMLarr.forEach(callback);
    }

    data(key, value) {
      if (!value) {
        return this.HTMLarr[0].dataset[key];
      }

      this.HTMLarr.forEach((node) => {
        return (node.dataset[key] = value);
      });
    }

    append(arg) {
      if (typeof arg === "string") {
        this.HTMLarr.forEach((node) => (node.innerHTML += arg));
      } else if (typeof arg === "object") {
        if (arg instanceof HTMLElement) {
          this.HTMLarr.forEach((node) => (node.innerHTML += arg.outerHTML));
        } else if (arg instanceof DOMNodeCollection) {
          this.HTMLarr.forEach((node) =>
            arg.HTMLarr.forEach(
              (argNode) => (node.innerHTML += argNode.outerHTML)
            )
          );
        }
      }
    }

    attr(name, value) {
      if (value) {
        this.HTMLarr.forEach((node) => (node[name] = value));
      } else {
        return this.HTMLarr[0][name];
      }
    }

    addClass(name) {
      this.HTMLarr.forEach((node) => (node.className += ` ${name}`));
    }

    removeClass(name) {
      if (name) {
        this.HTMLarr.forEach(
          (node) => (node.className = node.className.replace(`${name}`, ""))
        );
      } else {
        this.HTMLarr.forEach((node) => (node.className = ""));
      }
    }
    // traversal //

    children() {
      let dom = new DOMNodeCollection([]);

      this.HTMLarr.forEach((node) =>
        Array.from(node.children).forEach((child) => dom.HTMLarr.push(child))
      );

      return dom;
    }

    parent() {
      let dom = new DOMNodeCollection([]);

      this.HTMLarr.forEach((node) => dom.HTMLarr.push(node.parentNode));

      return dom;
    }

    find(selector) {
      let dom = new DOMNodeCollection([]);
      let kids = [];

      this.HTMLarr.forEach((node) => {
        kids = Array.from(node.querySelectorAll(selector));
        dom.HTMLarr = dom.HTMLarr.concat(kids);
      });

      return dom;
    }

    remove() {
      this.empty();

      let parentCollection = this.parent();

      for (let i = 0; i < parentCollection.HTMLarr.length; i++) {
        let parent = parentCollection.HTMLarr[i];
        parent.removeChild(this.HTMLarr[i]);
      }
    }

    on(type, callback) {
      this.HTMLarr.forEach((node) => {
        node.addEventListener(type, callback);
      });

      this.attr("eventCallback", callback);
    }

    click(callback) {
      this.on("click", callback);
    }

    off(type) {
      const callback = this.attr("eventCallback");

      this.HTMLarr.forEach((node) => {
        node.removeEventListener(type, callback);
      });
    }

    one(type, callback) {
      this.HTMLarr.forEach((node) => {
        this.on(type, (e) => {
          callback();
          this.off(type);
        });
      });
    }

    trigger(type) {
      const event = new Event(type);
      this.HTMLarr.forEach((node) => {
        node.dispatchEvent(event);
      });
    }

    is(property) {
      return this.attr(property);
    }
  }

  let funcQueue = [];

  document.addEventListener("DOMContentLoaded", () => {
    funcQueue.forEach((func) => func());
  });

  Window.prototype.$ = function (arg) {
    if (typeof arg === "string") {
      return new DOMNodeCollection(Array.from(document.querySelectorAll(arg)));
    } else if (arg === document) {
      return new DOMNodeCollection([document]);
    } else if (typeof arg === "object") {
      if (arg instanceof HTMLElement) {
        if (arg.id) {
          return new DOMNodeCollection([document.getElementById(arg.id)]);
        }
        return new DOMNodeCollection(
          Array.from(document.querySelectorAll([arg.tagName]))
        );
      }
    } else if (typeof arg === "function") {
      if (document.readyState === "complete") {
        arg();
        return;
      }
      funcQueue.push(arg);
    }
  };

  Window.prototype.$.extend = function (obj1, ...objs) {
    while (objs.length > 0) {
      Object.keys(objs[0]).forEach((key) => {
        if (key === "data") {
          obj1[key] = merge({}, obj1[key], objs[0][key]);
        } else {
          obj1[key] = objs[0][key];
        }
      });
      objs.shift();
    }
    return obj1;
  };

  const toQueryString = (obj) => {
    let result = "";
    for (const prop in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, prop)) {
        result += `${prop}=${obj[prop]}&`;
      }
    }
    return result.substring(0, result.length - 1);
  };

  window.$.ajaxSettings = {
    contentType: "application/json; charset=UTF-8",
    method: "GET",
    url: "",
    success: () => {},
    error: () => {},
    data: {},
    dataType: "json",
  };

  Window.prototype.$.ajaxSetup = (options) => {
    window.$.ajaxSettings = window.$.extend(window.$.ajaxSettings, options);
  };

  Window.prototype.$.ajax = (options) => {
    const defaults = merge({}, window.$.ajaxSettings);
    options = window.$.extend(defaults, options);
    options.method = options.method.toUpperCase();
    if (options.method === "GET") {
      // data is query string for get
      options.url += `?${toQueryString(options.data)}`;
    }
    return new Promise(function (resolve, reject) {
      const request = new XMLHttpRequest();
      request.onload = function () {
        if (this.status === 200) {
          options.success(JSON.parse(this.response));
          resolve(JSON.parse(this.response));
        } else {
          options.error(JSON.parse(this.response));
          reject(JSON.parse(this.response));
        }
      };

      request.open(options.method, options.url, true);
      if (options.contentType) {
        request.setRequestHeader("Content-Type", options.contentType);
      }
      request.send(JSON.stringify(options.data));
    });
  };
};

export default setupPolyfills;
