import React, { Component } from "react";
import { Link } from "react-router-dom";
import MetaTags from "../MetaTags";
import Trustbox from "../shared/Trustbox";
import Heart from '../../svg/heart'

export default class LendingModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: "el"
    };
  }

  handleSelect = el => () => this.setState({ product: el });

  render() {
    let metaTagData = {
      title: "Installment Loans with Flexibility That Helps Credit Building",
      og_title: "Installment Loans with Flexibility That Helps Credit Building",
      twitter_title:
        "Installment Loans with Flexibility That Helps Credit Building",
      description:
        "Only an installment loan with flexible repayment plans and full honesty and transparency can help you meet your urgent needs while helping build credit.",
      og_description:
        "Only an installment loan with flexible repayment plans and full honesty and transparency can help you meet your urgent needs while helping build credit.",
      twitter_description:
        "Only an installment loan with flexible repayment plans and full honesty and transparency can help you meet your urgent needs while helping build credit.",
      og_image:
        "https://s3.us-west-2.amazonaws.com/figloans-static-images/model/apr_water_fall_fb.jpg",
      twitter_image:
        "https://s3.us-west-2.amazonaws.com/figloans-static-images/model/apr_water_fall_twitter.jpg"
    };

    return (
      <div className="container">
        <MetaTags metaTagData={metaTagData} />

        <div className="grey">
          <div className="panel-row">
            <h4>{"Philosophy"}</h4>
            <h5>
              Financial tools that meet immediate needs and build future
              wellbeing
            </h5>

            <p>
              {" "}
              {
                "Fig's mission is to provide affordable financial products that help American families meet their current needs and secure their financial future. All our products share the following features:"
              }
            </p>
            <div className="general-product-features">
              <p>
                <b>Transparent, Fair, Honest Pricing</b>
              </p>
              <p>
                Fig Loans are based on the loan you would get from friends &
                family. There are no hidden fees.
              </p>
              <p>
                <b>Flexible Payment Plans</b>
              </p>
              <p>
                We understand that life {" doesn't"} always go according to plan
                and sometimes you just need a few extra days. This is why we
                have never charged a fee for changing your payment date. We do
                not believe in charging customers for doing the right thing and
                proactively managing their budget.
              </p>
              <p>
                <b>Responsive Customer Service</b>
              </p>
              <p>
                Customers can reach Fig staff members by text message or email.
                We also offer tools for customers to make their own changes by
                text message or online. Sometimes you need a custom payment plan
                and we are happy to help!
              </p>
              <p>
                <b>Credit Reporting</b>
              </p>
              <p>
                Fig Loans reports your account history to all 3 major credit bureaus
                (Equifax, Experian, TransUnion) from day 1 because we believe in
                financial freedom. Predatory lenders often
                {" don't"} report at all because {" they're"} not interested in
                helping customers improve their credit.
              </p>
            </div>
            <div className="clear" />
          </div>
          <div className="product-nav">
            <div className="product-nav-internal">
              <h4 className="center">Products</h4>
              <div className="horizontal_list">
                <ul className="lm-products">
                  <li
                    className={
                      "lm-product " +
                      (this.state.product === "el" ? "selected" : "greyscale")
                    }
                  >
                    <img
                      src={
                        "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/product/el_color.png"
                      }
                      alt="emergency_loan_logo"
                      onClick={this.handleSelect("el")}
                      style={{ width: "100%", display: "block" }}
                    />
                  </li>
                  <li
                    className={
                      "lm-product " +
                      (this.state.product === "cb" ? "selected" : "greyscale")
                    }
                  >
                    <img
                      src={
                        "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/product/cb_color.png"
                      }
                      alt="creditbuilder_loan_logo"
                      onClick={this.handleSelect("cb")}
                      style={{ width: "100%", display: "block" }}
                    />
                  </li>
                  <li
                    className={
                      "lm-product " +
                      (this.state.product === "36" ? "selected" : "greyscale")
                    }
                  >
                    <img
                      src={
                        "https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/product/36_color.png"
                      }
                      alt="fig36_logo"
                      onClick={this.handleSelect("36")}
                      style={{ width: "100%", display: "block" }}
                    />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div
          className="panel-container white"
          id="el"
          style={{ display: this.state.product === "el" ? "block" : "none" }}
        >
          <div className="panel-row">
            <div>
              <div className="panel-left">
                <h5 className="center">Quick funds, no debt traps</h5>
                <p>
                  The original Fig Loan provides $50 - $800 in emergency funds to meet unexpected needs. 
                  Loan terms range from 1 to 6 months* but can be extended without any additional interest 
                  or fees thanks to our flexible repayment policy. We review applications daily and can 
                  often deposit funds within one business day of loan acceptance**. 
                </p>
                <p>
                  <b>Who should apply?</b>
                </p>
                <p>
                  Customers who have an unexpected expense (car repair, medical
                  downpayment, etc..) should consider using this product.
                  Additionally, customers who have been using traditional payday
                  or other high interest rate loans should consider refinancing
                  with our credit building service (available in Texas).
                </p>
                <p style={{ fontSize: "0.75rem"}}>
                  <em>* Loan amounts and terms vary by state of residence</em>
                  <br></br>
                  <em>** Funding times may vary and can take up to 4 business days by ACH</em>
                </p>
              </div>

              <div className="panel-right">
                <h5 className="center dark-text">You'll love your Fig Loan!</h5>
                <Heart className="panel-right-svg" />
              </div>
              <div className="clear" />
            </div>
            <div className="center">
              <br />
              <Link
                to="/what-is-fig"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div
          className="panel-container white"
          id="cb"
          style={{ display: this.state.product === "cb" ? "block" : "none" }}
        >
          <div className="panel-row">
            <div>
              <div className="panel-left">
                <h5 className="center">A proven way to build credit</h5>
                <p>
                  Originally used by credit unions and non-profits, credit
                  builders provide customers a cheaper way to build credit
                  history than a normal loan. On approval, Fig deposits the
                  credit builder funds in an escrow account for the benefit of
                  the customer. The customer makes payments as if it were a
                  normal loan, but gets the entire loan amount from the escrow
                  account back at the end of the loan. Credit builder loans are
                  larger and longer to maximize their potential benefit on
                  customer credit.
                </p>
                <p>
                  <b>Who should apply?</b>
                </p>
                <p>
                  Customers who DO NOT need funds today and are able to make
                  regular monthly payments should consider the credit builder
                  over {"Fig's"} other products. The credit builder is better
                  suited and often more effective than {"Fig's"} installment loans
                  for reporting good credit. Please note that all payments
                  are reported to the credit bureaus, including late payments,
                  which can negatively impact a {"customer's"} credit score.
                  However, customers can prepay the loan at any time to release
                  the funds held in escrow.
                </p>
                <p>
                  <b>About the escrow funds</b>
                </p>
                <p>
                  Funds for the benefit of the customer are held at a third
                  party institution and not used for {"Fig's"} normal business
                  operations or lent to other customers. Additional information
                  regarding these funds is included in the credit builder
                  documents.
                </p>
              </div>

              <div className="panel-right">
                <h5 className="center">Cash dispersed at loan completion</h5>
                <p>
                  <b>When the creditbuilder is approved</b>
                </p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/fig_2_escrow.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
                <p>
                  Fig sends your loan funds to a 3rd party bank to hold in
                  escrow
                </p>
                <p>
                  <b>During the loan</b>
                </p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/customer_2_fig.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
                <p>Customers make loan payments to Fig</p>
                <p>
                  <b>When the creditbuilder is completed</b>
                </p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/escrow_2_customer.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
                <p>The escrow funds are released to the customer</p>
              </div>
              <div className="clear" />
            </div>
            <div className="center">
              <br />
              <Link
                to="/credit-builder"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div
          className="panel-container white"
          id="secure"
          style={{
            display: this.state.product === "secure" ? "block" : "none"
          }}
        >
          <div className="panel-row">
            <div className="center">
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <h5>Available December 2018</h5>
              <br />
              <p>We will post more information soon!</p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </div>
          </div>
        </div>

        <div
          className="panel-container white"
          id="36"
          style={{ display: this.state.product === "36" ? "block" : "none" }}
        >
          <div className="panel-row">
            <div>
              <div className="panel-left">
                <h5 className="center">Supercharging non-profit lending</h5>
                <p>
                  Fig36 is a partnership program between Fig and community
                  organizations (like non-profits) interested in offering
                  affordable loan programs. Fig provides our world class risk
                  models, loan management software, credit reporting, regulatory
                  compliance, and customer service so that our partners can
                  operate with the sophistication of a Wall Street bank.
                  Partners provide an unparalleled understanding of their{" "}
                  {"community's"} unique needs. Fig36 pilots are free for{" "}
                  {"Fig's"} community partners.
                </p>
                <p>
                  Fig offers this program because we believe that non-profits
                  should have the best tools available to serve their
                  communities. Inefficient technology solutions waste valuable
                  partner time that should instead be spent with clients. Fig36
                  is currently in a limited beta in Texas. We hope to expand the
                  program in 2020 to all the states that we serve!
                </p>
              </div>
              <div className="panel-right">
                <h5 className="center">Our pilot partners</h5>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/about/fig36_launch_partners.jpg"
                  style={{ width: "100%", display: "block" }}
                  className="chart-container"
                  alt="APR Chart"
                />
              </div>
              <div className="clear" />
            </div>
            <div className="center">
              <br />
              <Link
                to="/community-loans"
                className="button"
                onClick={() => window.scrollTo(0, 0)}
              >
                Learn More
              </Link>
            </div>
          </div>
        </div>

        <div className="panel-grey">
          <div className="mission">
            <div className="mission-text">
              <h4>{"Results"}</h4>
              <Trustbox />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
