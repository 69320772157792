import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import App from "./App_container";

const Root = ({ store }) => (
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <Helmet>
          <title>
            Installment Loans | A Payday Loan Alternative | Fig Loans
          </title>
          <meta property="og:title" content="Fig Loans" />
          <meta
            property="og:description"
            content="Fig works with nonprofits like Family Services to provide affordable small personal loans that are designed to be repaid and build credit"
          />
          <meta
            property="og:url"
            content={`https://figloans.com${window.location.pathname}`}
          />
          <meta
            property="og:image"
            content="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/social/og_image_default.jpg"
          />
          <meta property="og:type" content="website" />
          <meta property="og:site_name" content="Fig Loans" />
          <meta name="twitter:card" content="summary" />
          <meta
            name="twitter:image"
            content="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/social/twitter_image_default.jpg"
          />
          <meta
            name="twitter:title"
            content="Installment Loans | A Payday Loan Alternative | Fig Loans"
          />
          <meta
            name="twitter:description"
            content="Fig works with nonprofits like Family Services to provide affordable small personal loans that are designed to be repaid and build credit"
          />
          <meta name="twitter:site" content="@figloans" />
          <meta
            name="description"
            content="Fig works with nonprofits like Family Services to provide affordable small personal loans that are designed to be repaid and build credit"
          />
          <meta
            name="keywords"
            content="figloans, fig houston, payday loan, nonprofit payday loan"
          />
        </Helmet>
        <App />
      </div>
    </BrowserRouter>
  </Provider>
);

export default Root;
