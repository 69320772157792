import { connect } from "react-redux";
import { setUtm } from "../actions/utm_actions";
import { fetchUserInfo, forceLogin } from "../actions/session_actions";
import { changeLoading } from "../actions/loader_actions";
import { setApplication } from "../actions/application_actions";
import { setState } from "../actions/info_actions";
import { clearErrors } from "../actions/error_actions";
import { changeBanner } from "../actions/banner_actions";
import { withRouter } from "react-router-dom";
import App from "./App";

const mapStateToProps = state => ({
  utm: state.entities.utm,
  currentUser: state.session.currentUser,
  loading: state.ui.loading,
  banner: state.ui.banner
});

const mapDispatchToProps = dispatch => ({
  setUtm: utm => dispatch(setUtm(utm)),
  fetchUserInfo: email => {
    dispatch(changeLoading(true));
    dispatch(fetchUserInfo(email));
  },
  setApplication: application => dispatch(setApplication(application)),
  clearErrors: () => dispatch(clearErrors()),
  forceLogin: (forced, callback) => dispatch(forceLogin(forced, callback)),
  setLocation: state => dispatch(setState(state)),
  changeLoading: state => dispatch(changeLoading(state)),
  changeBanner: state => dispatch(changeBanner(state))
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(App)
);
