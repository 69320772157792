import * as UserUtil from "../util/user_api_util";
import { receiveUserErrors, clearErrors } from "./error_actions";
import { receiveCurrentUser } from "./session_actions";
import { receiveLoading } from "./loader_actions";
export const RECEIVE_PAYMENT_SCHEDULE = "RECEIVE_PAYMENT_SCHEDULE";
export const RECEIVE_DEBIT_VALID = "RECEIVE_DEBIT_VALID";
export const RECEIVE_APP_FINISH = "RECEIVE_APP_FINISH";

export const receivePaymentSchedule = (user) => ({
  type: RECEIVE_PAYMENT_SCHEDULE,
  user,
});

export const receiveDebitValid = (debitValid) => ({
  type: RECEIVE_DEBIT_VALID,
  debitValid,
});

export const receiveAppFinish = (info) => ({
  type: RECEIVE_APP_FINISH,
  info,
});

export const submitInquiry = (user_id, body, file) => (dispatch) =>
  UserUtil.submitInquiry(user_id, body, file).then(null, (errors) =>
    dispatch(receiveUserErrors(errors))
  );

export const submitNewBank =
  (user_id, time_displayed, bank_account, onSuccess, onError) => () =>
    UserUtil.submitNewBank(
      user_id,
      time_displayed,
      bank_account,
      onSuccess,
      onError
    ).then(
      () => {
        onSuccess();
      },
      (errors) => {
        onError(errors.errors);
      }
    );
    
export const addPaymentCard =
  (debit_card, address, time_displayed, onSuccess, onError) => () =>
    UserUtil.addPaymentCard(
      debit_card, 
      address, 
      time_displayed,
      onSuccess,
      onError
    ).then(
      () => {
        onSuccess();
      },
      (errors) => {
        onError(errors.errors);
      }
    );

export const removeDebitCard =
  (cardNumberLastFour, onSuccess, onError) => () =>
    UserUtil.removeDebitCard(
      cardNumberLastFour
    ).then(
      () => {
        onSuccess();
      },
      (errors) => {
        onError(errors.errors);
      }
    );

  

export const submitPaymentChange =
  (loan_id, payment_id, date, onSuccess, onError) => (dispatch) =>
    UserUtil.submitPaymentChange(
      loan_id,
      payment_id,
      date,
      onSuccess,
      onError
    ).then(
      (user) => {
        dispatch(receiveCurrentUser(user));
        onSuccess();
      },
      (errors) => {
        // dispatch(receiveUserErrors(errors));
        onError(errors);
      }
    );

export const createPayment =
  (user_id, payment_type, payment_id, callback) => (dispatch) =>
    UserUtil.createPayment(user_id, payment_type, payment_id).then(
      (user) => {
        callback();
        dispatch(receiveLoading(false));
        dispatch(receiveCurrentUser(user));
      },
      (errors) => {
        callback(errors);
        dispatch(receiveUserErrors(errors));
        dispatch(receiveLoading(false));
      }
    );

export const resetPassword = (email, callback) => (dispatch) =>
  UserUtil.resetPassword(email, callback).then(
    () => dispatch(receiveLoading(false)),
    (errors) => {
      dispatch(receiveUserErrors(errors));
      dispatch(receiveLoading(false));
    }
  );
export const unlockAccount = (unlockToken, callback) => (dispatch) =>
  UserUtil.unlockAccount(unlockToken, callback).then(
    () => dispatch(receiveLoading(false)),
    (errors) => {
      dispatch(receiveUserErrors(errors));
      dispatch(receiveLoading(false));
    }
  );

export const submitPassword = (info, callback) => (dispatch) =>
  UserUtil.submitPassword(info, callback).then(
    () => dispatch(receiveLoading(false)),
    (errors) => {
      dispatch(receiveUserErrors(errors));
      dispatch(receiveLoading(false));
    }
  );

export const updateUtm = (user_id, utm_content, other) => (dispatch) =>
  UserUtil.updateUtm(user_id, utm_content, other);

/*
 * @deprecated as a result of OH 2023 Exam Remediations
 * Fig has ended the referral program in all states
 */
export const sendReferral = (user_id, type, contact) => (dispatch) =>
  UserUtil.sendReferral(user_id, type, contact);

export const requestRepresentation = (payment_id) => (dispatch) =>
  UserUtil.requestRepresentation(payment_id).then(
    () => dispatch(receiveLoading(false)),
    (errors) => {
      dispatch(receiveUserErrors(errors));
      dispatch(receiveLoading(false));
    }
  );

export const repayLoan = (id, ssn, card, address, cb, amt) => (dispatch) =>
  UserUtil.repayLoan(id, ssn, card, address, amt).then(
    (user) => {
      dispatch(receiveCurrentUser(user));
      if (cb) cb();
      dispatch(receiveLoading(false));
    },
    (errors) => {
      dispatch(receiveUserErrors(errors));
      dispatch(receiveLoading(false));
    }
  );

export const payBalance = (loan_id, method) => (dispatch) =>
  UserUtil.repayLoan(loan_id, method).then(
    (user) => {
      dispatch(receiveCurrentUser(user));
      dispatch(receiveLoading(false));
    },
    (errors) => {
      dispatch(receiveUserErrors(errors));
      dispatch(receiveLoading(false));
    }
  );

export const createEarlyRepay =
  (loan_id, date, onSuccess, onError) => (dispatch) =>
    UserUtil.createEarlyRepay(loan_id, date).then(
      (user) => {
        dispatch(receivePaymentSchedule(user));
        dispatch(receiveLoading(false));
        onSuccess();
      },
      (errors) => {
        dispatch(receiveUserErrors(errors));
        dispatch(receiveLoading(false));
        onError(errors.errors[0]);
      }
    );

export const customRepay =
  (id, ssn, increment, frequency, start, account, card, address, cb) =>
  (dispatch) =>
    UserUtil.customRepay(
      id,
      ssn,
      increment,
      frequency,
      start,
      account,
      card,
      address,
      cb
    ).then(
      (user) => {
        dispatch(receiveCurrentUser(user));
        if (cb) cb();
        dispatch(receiveLoading(false));
      },
      (errors) => {
        dispatch(receiveUserErrors(errors));
        dispatch(receiveLoading(false));
      }
    );

export const getEfta = (userId, newAccountType, newRoutingNum, newAccountNum, newCardNum, onSuccess, onError) => (dispatch) =>
  UserUtil.getEfta(userId, newAccountType, newRoutingNum, newAccountNum, newCardNum).then(
    (efta) => {
      onSuccess(efta.efta);
    },
    (errors) => {
      onError(errors.errors);
    }
  );

export const retrievePayment = (loan_url, callback) => (dispatch) => {
  UserUtil.retrievePayment(loan_url).then(
    (user) => {
      callback(user);
      dispatch(receiveCurrentUser(user));
    },
    (errors) => {
      console.log(errors);
    }
  );
};

export const submitCCPARequestForm = (form, onError, onSuccess) => (dispatch) =>
  UserUtil.submitCCPARequestForm(form).then(
    () => {
      dispatch(receiveLoading(false));
      onSuccess(dispatch, clearErrors);
    },
    (errors) => {
      dispatch(receiveLoading(false));
      onError(dispatch, receiveUserErrors, errors);
    }
  );