import React from "react";

const CreditReporting = () => {
  return (
    <div className="row">
      <div className="glance">
        <h4 id="creditreporting">5. Does it report credit?</h4>
        <p>
          A large benefit to taking out loans is credit reporting. Credit
          reporting builds up your credit score, telling other lenders you are a
          good borrower so they can offer you better, cheaper loans!{" "}
        </p>
        <p>
          Lenders should report credit because it is a major benefit for
          borrowers that is easy for the lender to provide.
        </p>
        <p>
          If a payday lender is not reporting credit or claims it’s too
          expensive, you should be very concerned that lender is hiding
          something. Our recommendation? Find a different lender who will help
          you build your credit score, not hurt it.
          <br />
          <b>
            <u>
              All Fig Loans report credit to TransUnion, Equifax and Experian,
              helping Americans build healthy credit since 2015!
            </u>
          </b>
        </p>
        <div className="clear" />
      </div>
    </div>
  );
};

export default CreditReporting;
