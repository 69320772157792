import React, { Component } from "react";
import Loader from "react-loader";

export default class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = { clicked: props.open === undefined ? true : props.open };
  }

  spinnerOptions = {
    lines: 13,
    length: 5,
    width: 5,
    radius: 5,
    scale: 1.0,
    corners: 1,
    color: "#41b29e",
    opacity: 0.25,
    rotate: 0,
    direction: 1,
    speed: 1,
    trail: 60,
    fps: 20,
    zIndex: 2e9,
    left: "50%",
    shadow: false,
    hwaccel: false,
    position: "relative"
  };

  componentDidMount() {
  }

  handleClose = clickSite => () => {
    // MD: Reset the overflow property so the document can be scrolled again
    if (this.props.closeAction && !this.props.loading) {
      if (clickSite === "background" || clickSite === "x") {
        this.props.closeAction();
        this.setState({ clicked: false });
      }
    } else if (!this.props.loading) {
      this.setState({ clicked: false });
    }
  };

  render() {
    return (
      <div id="modal">
        {this.state.clicked || this.props.open ? (
          <div>
            <div
              onClick={this.handleClose("background")}
              id="modal-background"
            />
            <div id="modal-box">
              <div id="modal-content">
                <Loader
                  options={this.spinnerOptions}
                  loaded={!this.props.spinner}
                >
                  <button
                    className={`${
                      this.props.loading ? "partially_disabled" : ""
                    } ${this.props.stickyX ? "stickyX" : ""}`}
                    onClick={this.handleClose("x")}
                    id="modal-close"
                  >
                    x
                  </button>
                  {this.props.children}
                  <div id="modal-text">{this.props.text}</div>
                </Loader>
              </div>
            </div>
          </div>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
