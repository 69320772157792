import React from "react";
import { Helmet } from "react-helmet";

const CaliforniaRates = ({ products }) => {
  products = products || {
    loans: {
      traditional_loan: {
        50: { 6: { pmt: 89.37, apr: 2.21 } },
        100: { 6: { pmt: 119.16, apr: 2.18 } },
        150: { 6: { pmt: 148.95, apr: 2.16 } },
        200: { 6: { pmt: 148.95, apr: 2.16 } },
      },
    },
  };

  return (
    <div className="container">
      <Helmet>
        <title>California Rates and Notices | Fig Loans</title>
        <meta
          name="description"
          content="Do you need a loan in California? Fig Loans has the friendliest terms in the state!"
        />
        <meta
          property="og:description"
          content="Do you need a loan in California? Fig Loans has the friendliest terms in the state!"
        />
      </Helmet>
      <div className="panel-grey">
        <div id="california">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className='modal-heading'>California</h4>
              </div>
              <p>
                Loan costs depend on the amount advanced and length of the loan.
              </p>
              <p>
                <b>Fee Examples</b>
              </p>
              <p>
                <div className="disclosurewrap">
                  <div className="full-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Amount Advanced</th>
                          <td>$50.00</td>
                          <td>$100.00</td>
                          <td>$150.00</td>
                          <td>$200.00</td>
                        </tr>
                        <tr>
                          <th>
                            Loan Length 14 Day APR<sup>1</sup>
                          </th>
                          <td>458.86%</td>
                          <td>458.86%</td>
                          <td>458.86%</td>
                          <td>458.86%</td>
                        </tr>
                        <tr>
                          <th>
                            Loan Length 30 Day APR<sup>1</sup>
                          </th>
                          <td>214.13%</td>
                          <td>214.13%</td>
                          <td>214.13%</td>
                          <td>214.13%</td>
                        </tr>
                        <tr>
                          <th>Fee</th>
                          <td>$8.80</td>
                          <td>$17.60</td>
                          <td>$26.40</td>
                          <td>$35.20</td>
                        </tr>
                        <tr>
                          <th>Total Repayment Amount</th>
                          <td>58.80</td>
                          <td>117.60</td>
                          <td>176.40</td>
                          <td>235.20</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mobile-size">
                    <table>
                      <tr>
                        <th>Sample $50 Dollar 30 Day Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $50.00
                        </td>
                        <td>
                          <b>
                            Loan Length 14 Day APR<sup>1</sup>:
                          </b>{" "}
                          458.86%
                        </td>
                        <td>
                          <b>
                            Loan Length 30 Day APR<sup>1</sup>:
                          </b>{" "}
                          214.13%
                        </td>
                        <td>
                          <b>Fee:</b> $8.80
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$ 58.80
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $100 Dollar 30 Day Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $100.00
                        </td>
                        <td>
                          <b>
                            Loan Length 14 Day APR<sup>1</sup>:
                          </b>{" "}
                          458.86%
                        </td>
                        <td>
                          <b>
                            Loan Length 30 Day APR<sup>1</sup>:
                          </b>{" "}
                          214.13%
                        </td>
                        <td>
                          <b>Fee:</b> $17.60
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$ 117.60
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $150 Dollar 30 Day Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $150.00
                        </td>
                        <td>
                          <b>
                            Loan Length 14 Day APR<sup>1</sup>:
                          </b>{" "}
                          458.86%
                        </td>
                        <td>
                          <b>
                            Loan Length 30 Day APR<sup>1</sup>:
                          </b>{" "}
                          214.13%
                        </td>
                        <td>
                          <b>Fee:</b> $26.40
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$ 176.40
                        </td>
                      </tr>
                      <tr>
                        <th>Sample $100 Dollar 30 Day Loan</th>
                        <td>
                          <b>Amount Advanced:</b> $200.00
                        </td>
                        <td>
                          <b>
                            Loan Length 14 Day APR<sup>1</sup>:
                          </b>{" "}
                          458.86%
                        </td>
                        <td>
                          <b>
                            Loan Length 30 Day APR<sup>1</sup>:
                          </b>{" "}
                          214.13%
                        </td>
                        <td>
                          <b>Fee:</b> $ 35.20
                        </td>
                        <td>
                          <b>Total Repayment Amount:</b>
                          <br />$ 235.20
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </p>

              <p className="footnote">
                1. The APR shown is based on the federal Truth in Lending Act
                and does not reflect specialized state APR calculations, if any.
                <br />
              </p>
              <p>
                The loan information shown here is an example and may not
                reflect the rates available on all loans. Before entering into
                any transaction, you should read the disclosure statements and
                contract that are provided to you by Fig Loans.
              </p>
              <p>
                <u>Optional Fees</u>
              </p>
              <ul>
                <li>
                  You have the option of paying with a debit card. If you make a
                  payment with a debit card, you will be charged a processing
                  fee.
                </li>
                <li>
                  If your check is returned unpaid, you may be charged an
                  additional fee of up to fifteen dollars ($15)
                </li>
              </ul>
              <p>
                <b>Notices</b>
              </p>
              <p>
                Fig may not accept any collateral in conjunction with a deferred
                deposit transaction.
                <br />
                <br />
                The check is being negotiated as part of a deferred deposit
                transaction made pursuant to Section 23035 of the Financial Code
                and is not subject to the provisions of Section 1719 of the
                Civil Code. No customer may be required to pay treble damages if
                this check does not clear.
                <br />
                <br />
                You cannot be prosecuted in a criminal action in conjunction
                with a deferred deposit transaction for a returned check or be
                threatened with prosecution.
                <br />
                <br />
                This lender is regulated by the California Department of
                Financial Protection and Innovation. In the event of a complaint
                or a concern, California residents may contact the Department at
                866-275-2677.
              </p>
              <p>
                <b>LICENSES</b>
              </p>
              <p>
                <img
                  src="https://figloans-static-images.s3.us-west-2.amazonaws.com/licenses/california_cddtl_license.jpg"
                  alt="California CDDTL Lending License"
                  className="img-responsive faq-image"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaliforniaRates;
