import { domain } from "../helpers/route_helper";

export const getBlogPost = path => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/blog/posts`,
    data: { path }
  });
};

export const getBlog = (quantity, tag) => {
  if (tag) {
    tag = tag.replace(/-/g, " ");
  }
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/blog`,
    data: tag ? { quantity, tag } : { quantity }
  });
};
