import * as BlogUtil from "../util/blog_api_util";
import { receiveBlogErrors } from "./error_actions";
import { changeLoading } from "./loader_actions";
export const RECEIVE_BLOG_POST = "RECEIVE_BLOG_POST";
export const RECEIVE_BLOG_SAMPLES = "RECEIVE_BLOG_SAMPLES";

export const receiveBlogPost = (blogPost) => ({
  type: RECEIVE_BLOG_POST,
  blogPost,
});

export const receiveBlogSamples = (samples) => ({
  type: RECEIVE_BLOG_SAMPLES,
  samples,
});

export const getBlogPost = (path) => (dispatch) =>
  BlogUtil.getBlogPost(path).then(
    (blogPost) => {
      dispatch(receiveBlogPost(blogPost));
      dispatch(changeLoading(false));
    },
    (errors) => {
      dispatch(receiveBlogErrors(errors));
      dispatch(changeLoading(false));
    }
  );

export const getBlog = (quantity, tag) => (dispatch) =>
  BlogUtil.getBlog(quantity, tag).then(
    (samples) => {
      if (tag && samples.posts.length === 0) {
        window.location = "/blog";
      } else {
        dispatch(receiveBlogSamples(samples));
        dispatch(changeLoading(false));
      }
    },
    (errors) => {
      dispatch(receiveBlogErrors(errors));
      dispatch(changeLoading(false));
    }
  );
