import React from "react";
import { Link } from "react-router-dom";

const Info = ({ handleNavigate, site }) => {
  return [
    <li>
      <Link
        to={
          site === "MO"
            ? "/loan-information/compare-payday-loans/missouri"
            : "/loan-information/compare-payday-loans"
        }
        onClick={handleNavigate}
      >
        Compare
      </Link>
    </li>,
    <li>
      <Link to="/loan-information/credit-reporting" onClick={handleNavigate}>
        Credit Report
      </Link>
    </li>,
    <li>
      <Link
        to="/loan-information/loan-terms-dictionary"
        onClick={handleNavigate}
      >
        Dictionary
      </Link>
    </li>,
    <li>
      <Link to="/faq" onClick={handleNavigate}>
        Help Center
      </Link>
    </li>,
    <li>
      <Link
        to={`/loan-information/better-loans/${
          {
            TX: "texas",
            IL: "illinois",
            MO: "missouri"
          }[site]
        }`}
        onClick={handleNavigate}
      >
        Non-Profit
      </Link>
    </li>
  ];
};

export default Info;
