import { merge } from "lodash";
import { RECEIVE_UTM } from "../actions/utm_actions";

const utmReducer = (oldState = {}, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_UTM:
      return merge({}, oldState, action.utm);
    default:
      return oldState;
  }
};

export default utmReducer;
