import { LoanCycle } from "./loan_cycle_helper";

export const UnserviceableReasons = Object.freeze({
  CA_SHUTDOWN: "ca_shutdown"
})

// expects to receive the user object contained in state.entities.currentUser as the argument
export const getLoanUnserviceableReason = (currentUser) => {
  let loan = currentUser.loan;
  let activeLoanCycle = currentUser.activeLoanCycle;

  if (LoanCycle.ACTIVE.includes(activeLoanCycle) && loan && loan.reg_regime === "CaDdt") {
    return UnserviceableReasons.CA_SHUTDOWN;
  } else {
    return null;
  }
};