import { merge } from "lodash";
import { RECEIVE_APP_FINISH } from "../actions/application_actions";
import { RECEIVE_CURRENT_USER } from "../actions/session_actions";

const defaultState = {};

const appFinishReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_APP_FINISH:
      if (!action.info.accepted) {
        return merge({}, oldState, action.info);
      } else {
        return merge({}, oldState, action.info);
      }
    case RECEIVE_CURRENT_USER:
      return {};
    default:
      return oldState;
  }
};

export default appFinishReducer;
