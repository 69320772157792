import { domain } from "../helpers/route_helper";

export const sendPageTracking = anonymous_behavior => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/behavior_metric/page_tracking`,
    data: {
      anonymous_behavior
    }
  });
};
