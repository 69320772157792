import Modal from "../Modal";
import React from "react";
import { connect } from "react-redux";
import NoticesRates from "./NoticesRates";


const NoticesRatesModal = ({closeFcn}) => {


  return (
    <Modal
      closeAction={closeFcn}
      text={
        <NoticesRates/>
      }
    />
  );
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticesRatesModal);