import React, {useEffect, useState} from "react";
import {asyncFetchPatchRequest} from "../../util/async_util";
import {Helmet} from "react-helmet";
import Loader from "react-loader";

const EmailUnsubscribe = ({ unsubscribeUrl, successText }) => {
  // Fetch the base64 email from the query parameters in the URL
  const query = new URLSearchParams(window.location.search);
  const email = query.get('email');
  const [showSpinner, setShowSpinner] = useState(true);

  useEffect(() => {
    asyncFetchPatchRequest(unsubscribeUrl, {email: email})
      .then((_) => {
        setShowSpinner(false);
      });
  }, [])

  if (showSpinner) {
    return (
      <div className="content-container application">
        <Helmet>
          <title>Email Unsubscribe</title>
        </Helmet>
        <div className="content">
          <h2 className="center text">
            <b>Processing your request</b>
          </h2>
          <h3 className="center text">
            One moment please
          </h3>
          <br/>
          <Loader loaded={false}/>
        </div>
      </div>
    )
  } else {
    return (
      <div className="content-container application">
        <Helmet>
          <title>Email Unsubscribe</title>
        </Helmet>
        <div className="content">
          <h2 className="text">{successText}</h2>
        </div>
      </div>
    )
  }
}

export default EmailUnsubscribe