import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { setUtm } from "../actions/utm_actions";
import { setState } from "../actions/info_actions";
import { createCookie, readCookie } from "../helpers/cookie_helper";
import BCorp from "./BCorp";
import HomePress from "./HomePress";
import LoanSelector from "./LoanSelector_container";
import Trustbox from "./shared/Trustbox";
import { SimpleImgProvider } from "react-simple-img";
import cdfiLogo from "../styles/cdfi_logo.png";
// import "../styles/css/home.css";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = { scrolled: false };
  }
  componentDidMount() {
    // don't render certain things on initial page load until user scrolls
    window.addEventListener("scroll", () => {
      if (!this.state.scrolled) this.setState({ scrolled: true });
    });
    if (
      !this.props.utm.source ||
      this.props.utm.source === readCookie("utm_source")
    ) {
      const utm = { source: this.props.history.location.pathname.slice(1) };
      this.props.setUtm(utm);
      createCookie("utm_source", utm.source);

      switch (utm.source) {
        case "florida":
          this.props.setLocation("FL");
          break;
        case "texas":
          this.props.setLocation("TX");
          break;
        case "illinois":
          this.props.setLocation("IL");
          break;
        case "missouri":
          this.props.setLocation("MO");
          break;
        case "ohio":
          this.props.setLocation("OH");
          break;
        case "utah":
          this.props.setLocation("UT");
          break;
        case "california":
          this.props.setLocation("CA");
          break;
        case "new_mexico":
          this.props.setLocation("NM");
          break;
        default:
          break;
      }
    }
  }

  render() {
    let welcomeText, figLoan, paydayLoan;

    // const vw = Math.max(
    //   document.documentElement.clientWidth,
    //   window.innerWidth || 0
    // );

    switch (this.props.location) {
      case "TX":
        welcomeText = "";
        figLoan = { apr: 190, payment: 106.86, length: 4, final: 106.86 };
        paydayLoan = { apr: 391, payment: 45, length: 8, final: 345 };
        break;
      case "MO":
        welcomeText = "St. Louis area non-profits like Operation Hope ";
        figLoan = { apr: 176, payment: 78.56, length: 6, final: 78.56 };
        paydayLoan = { apr: 391, payment: 45, length: 8, final: 345 };
        break;
      case "OH":
        welcomeText = "Columbus area non-profits ";
        figLoan = { apr: 199, payment: 108.46, length: 4, final: 108.46 };
        paydayLoan = { apr: 391, payment: 45, length: 8, final: 345 };
        break;
      case "UT":
        welcomeText = "non-profits ";
        figLoan = { apr: 190, payment: 106.86, length: 4, final: 106.86 };
        paydayLoan = { apr: 391, payment: 45, length: 8, final: 345 };
        break;
      default:
        welcomeText = "";
        figLoan = { apr: 190, payment: 106.86, length: 4, final: 106.86 };
        paydayLoan = { apr: 391, payment: 45, length: 8, final: 345 };
        break;
    }

    return (
      <div>
        <SimpleImgProvider
          config={{
            threshold: [0.1]
          }}
        >
          <Helmet>
            <link rel="canonical" href="https://www.figloans.com/" />
            <title>
              Installment Loans | A Payday Loan Alternative | Fig Loans
            </title>
            <meta
              name="description"
              content={
                "Fig works with " +
                welcomeText +
                "to provide affordable small personal loans that are designed to be repaid and build credit"
              }
            />
            <meta
              property="og:description"
              content={
                "Fig works with " +
                welcomeText +
                "to provide affordable small personal loans that are designed to be repaid and build credit"
              }
            />
            <script type="application/ld+json">
              {`{
        			"@context": "http://schema.org",
        			"@id": "https://www.figloans.com/#organization",
        			"@type": "Organization",
        			"name": "Fig Loans",
        			"url": "https://www.figloans.com/",
        			"logo": "https://s3.amazonaws.com/fig-pro/public/figlogo.png",
        			"contactPoint": [
        				{
        					"@type": "ContactPoint",
        					"url": "https://www.figloans.com/",
        					"contactType": "Customer Service",
        	                "email": "service@figloans.com",
        					"areaServed": [ "Texas", "Missouri", "Utah", "Ohio", "Florida", "New Mexico" ]
        				}
        			],
        			"sameAs": [
        				"https://www.facebook.com/figloans/",
        				"https://www.linkedin.com/company/fig-loans",
        				"https://twitter.com/figloans?lang=en"
        			],
        	        "aggregateRating": {
        	          "@type": "AggregateRating",
        	          "ratingValue": "5",
        	          "bestRating": "5",
        	          "worstRating": "1",
        	          "ratingCount": "39"
        	        },
        	      "address": {
        	      "@type": "PostalAddress",
        	      "addressLocality": "Sugar Land",
        	      "addressRegion": "TX",
        	      "postalCode": "77479"
        	    },
        	    "foundingDate": "2015-03",
        	    "foundingLocation": [{      "address": {
        	          "@type": "PostalAddress",
        	      "addressLocality": "Sugar Land",
        	      "addressRegion": "TX",
        	      "postalCode": "77479"}

        	    }]
        	 }`}
            </script>
            <script type="application/ld+json">
              {`{
              "@context": "https://schema.org",
              "@id": "https://www.figloans.com/#webpage",
              "@type": "WebPage",
              "url": "https://www.figloans.com/",
              "name": "Fig Loans"
            }`}
            </script>
            <script type="application/ld+json">
              {`{
            "@context":"https://schema.org",
            "@type":"ItemList",
            "itemListElement":[
             {
               "@type":"ListItem",
               "position":1,
               "url":"https://www.figloans.com/about/fig-texas-story",
               "name":"About"
             },
             {
               "@type":"ListItem",
               "position":3,
               "url":"https://www.figloans.com/about/responsible-lending-model",
               "name":"Lending Model"
             },
             {
               "@type":"ListItem",
               "position":4,
               "url":"https://www.figloans.com/loan-information/compare-payday-loans",
               "name":"Guide"
             },
             {
               "@type":"ListItem",
               "position":2,
               "url":"https://www.figloans.com/app",
               "name":"Application"
             }
            ]
         }`}
            </script>
            <script type="application/ld+json">
              {`{
            "@context": "http://schema.org",
            "@type": "Store",
            "image": "https://s3.amazonaws.com/fig-pro/public/fig-logo-2+spaced.png",
            "@id": "https://www.figloans.com/#store",
            "name": "Fig Loans | installment loans",
            "url": "https://www.figloans.com/",
            "contactPoint": [
            {
              "@type": "ContactPoint",
              "url": "https://www.figloans.com/",
              "contactType": "Customer Service",
              "email": "service@figloans.com",
              "areaServed": [ "Texas", "Missouri", "Utah", "Florida", "Ohio", "New Mexico"]
            }
            ],
            "openingHoursSpecification": [
            {
              "@type": "OpeningHoursSpecification",
              "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday"
                ],
                "opens": "09:00",
                "closes": "17:00"
              }
            ],
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "5",
              "bestRating": "5",
              "worstRating": "1",
              "ratingCount": "39"
            },
            "address": {
              "@type": "PostalAddress",
              "addressLocality": "Sugar Land",
              "addressRegion": "TX",
              "postalCode": "77479"
            }
        }`}
            </script>
          </Helmet>
          <article className="article-main">
            <section
              className="section-panel section-welcome bg-dark"
              id="home-welcome"
            >
              <div className="content-container">
                <div style={{ minHeight: "50vh" }} className="content group">
                  <h1 className="mobile">Welcome to Fig Loans</h1>
                  <aside className="aside-loan-calculator">
                    <div className="content">
                      <h3>Make a smart financial decision today!</h3>
                      <hr />
                      <LoanSelector
                        homscreen={true}
                        edit={false}
                        product="traditional_loan"
                      />
                    </div>
                  </aside>
                  <div className="column left">
                    {<h1 className="desktop">Welcome to Fig Loans</h1>}
                    {welcomeText === "" ? (
                      <div>
                        <div className="cdfi_text">
                          <p className="large">
                            We provide credit building <br className="mobile" />{" "}
                            alternatives to the typical payday loan
                          </p>
                        </div>
                        <div className="clear" />
                      </div>
                    ) : (
                      <div>
                        <p className="large">
                          Fig partners with {welcomeText}
                          <span className="opaque">
                            to offer you a loan that is designed to get you out
                            of debt
                          </span>{" "}
                        </p>
                        <p className="large">
                          <span className="opaque">
                            Fig helps you through financial emergencies and
                            build credit towards a better future
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </div>
                <div className="compare">
                  <div className="content group">
                    <p>
                      Compare a <span>$</span>
                      <span id="compare_loan_amount_title">300</span> loan over{" "}
                      {this.props.location === "IL" ? (
                        <span id="compare_loan_length_title">
                          {figLoan.length * 30} days...
                        </span>
                      ) : (
                        <span id="compare_loan_length_title">
                          {figLoan.length} months...
                        </span>
                      )}
                    </p>
                  </div>
                </div>
              </div>
            </section>

            {/*
            Start of Floodlight Tag: Please do not remove
            Activity name of this tag: Fig Loans - Landing page
            URL of the webpage where the tag is expected to be placed: https://www.figloans.com/
            This tag must be placed between the body tags, as close as possible to the opening tag.
            Creation Date: 08/23/2018
          */
            this.props.utm.source === "transunion"
              ? [
                  <iframe
                    key={2}
                    title="2"
                    src={
                      "https://8859475.fls.doubleclick.net/activityi;src=8859475;type=figl;cat=figlo0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;ord=" +
                      this.props.sessionId +
                      "?"
                    }
                    style={{
                      display: "none",
                      width: "1",
                      height: "1",
                      frameborder: "0"
                    }}
                  />
                ]
              : ""

            // End of Floodlight Tag: Please do not remove
            }

            <section
              className="section-panel bg-white"
              id="section-paydayvsfig"
            >
              <img
                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/arrow.png"
                alt="compare"
                className="arrow"
              />
              {this.state.scrolled ? (
                <div className="content-container">
                  <div className="content group no-bot-padding">
                    <div className="column mobile">
                      <table cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                          <tr>
                            <td className="header" />
                            <td>
                              <img
                                src="https://figloans-static-images.s3.us-west-2.amazonaws.com/logos/table_logo-fig-2020.png"
                                alt="Fig Loans"
                              />
                            </td>
                            <td>
                              <img
                                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/table_logo-payday-orange.png"
                                alt="Payday Loans"
                              />
                            </td>
                          </tr>
                          <tr>
                            <td className="header">APR</td>
                            <td className="fig">
                              <span id="compare_loan_fig_apr_mobile" />
                              <span>{figLoan.apr}%</span>
                            </td>
                            <td className="payday">{paydayLoan.apr}%</td>
                          </tr>
                          <tr>
                            <td className="header">
                              Total Amount Paid For Loan
                            </td>
                            <td className="fig">
                              <span>
                                ${(figLoan.payment * figLoan.length).toFixed(2)}
                              </span>
                              <span id="compare_loan_fig_total_pay_mobile" />
                            </td>
                            <td className="payday">
                              <span id="compare_loan_pdl_total_pay_mobile">
                                $
                                {(
                                  paydayLoan.payment * (paydayLoan.length - 1) +
                                  paydayLoan.final
                                ).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="disclaimer">
                        {
                          "1. “Payday Loan Companies” product refers to a typical two-week balloon payment payday loan as described at (https://www.consumerfinance.gov/ask-cfpb/what-is-a-payday-loan-en-1567/) rolled over seven times. This product may not be available in your state or your state may have mandated a lower interest rate or otherwise altered the product. You should always check with perspective loan company to see the offerings available to you. This example should be used for illustrative purposes only. You may receive an offer from Fig with a lower or higher APR, longer or shorter term and larger or smaller loan size."
                        }
                      </p>
                    </div>
                    <div style={{ textAlign: "left" }} className="column main">
                      <h2>Payday Loans vs. Fig Loans</h2>
                      <p className="large" style={{ textAlign: "left" }}>
                        Fig gives you a fair price and a repayment timeline that
                        works within your budget
                      </p>
                    </div>
                    <div className="column desktop">
                      <table cellSpacing="0" cellPadding="0" border="0">
                        <tbody>
                          <tr>
                            <td className="header" />
                            <td className="header">APR</td>
                            <td className="header">Total Monthly Payments</td>
                            <td className="header">Number of Payments</td>
                            <td className="header">Final Monthly Payment</td>
                            <td className="header">
                              Total Amount Paid For Loan
                            </td>
                          </tr>
                          <tr className="figloans">
                            <td>
                              <img
                                src="https://figloans-static-images.s3.us-west-2.amazonaws.com/logos/table_logo-fig-2020.png"
                                alt="Fig Loans"
                              />
                            </td>
                            <td>
                              <span id="compare_loan_fig_apr_main" />
                              <span>{figLoan.apr}%</span>
                            </td>
                            <td>
                              <span>${figLoan.payment.toFixed(2)}</span>
                              <span id="compare_loan_fig_mon_pay_main" />
                            </td>
                            <td>
                              <span id="compare_loan_fig_pay_num_main">
                                {figLoan.length}
                              </span>
                            </td>
                            <td>
                              <span>${figLoan.final.toFixed(2)}</span>
                              <span id="compare_loan_fig_fin_pay_main" />
                            </td>
                            <td>
                              <span>
                                ${(figLoan.payment * figLoan.length).toFixed(2)}
                              </span>
                              <span id="compare_loan_fig_total_pay_main" />
                            </td>
                          </tr>
                          <tr className="paydayloan">
                            <td>
                              <img
                                src="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/table_logo-payday-orange.png"
                                alt="Payday Lender"
                              />
                            </td>
                            <td>{paydayLoan.apr}%</td>
                            <td>
                              ${(paydayLoan.payment * 2).toFixed(2)}
                              <br />
                              <span className="small">
                                ${paydayLoan.payment.toFixed(2)} Per Payment /
                                Rollover
                              </span>
                            </td>
                            <td>
                              <span id="compare_loan_pdl_pay_num_main">
                                {paydayLoan.length}
                              </span>
                            </td>
                            <td>${paydayLoan.final.toFixed(2)}</td>
                            <td>
                              <span id="compare_loan_pdl_total_pay_main">
                                $
                                {(
                                  paydayLoan.payment * (paydayLoan.length - 1) +
                                  paydayLoan.final
                                ).toFixed(2)}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p className="disclaimer">
                        {
                          "1. “Payday Loan Companies” product refers to a typical two-week balloon payment payday loan as described at (https://www.consumerfinance.gov/ask-cfpb/what-is-a-payday-loan-en-1567/) rolled over seven times. This product may not be available in your state or your state may have mandated a lower interest rate or otherwise altered the product. You should always check with perspective loan company to see the offerings available to you. This example should be used for illustrative purposes only. You may receive an offer from Fig with a lower or higher APR, longer or shorter term and larger or smaller loan size."
                        }
                      </p>
                    </div>
                    <div className="column phablet">
                      <h2 style={{ textAlign: "center" }}>
                        Payday Loans Vs. Fig Loans
                      </h2>
                      <p className="large">
                        Fig gives you a fair price and a repayment timeline that
                        works within your budget.
                      </p>
                    </div>
                  </div>
                  {this.state.scrolled ? <BCorp /> : null}
                  <hr />

                  <div className="content group no-top-padding no-bot-padding">
                    {this.state.scrolled ? <HomePress /> : null}
                  </div>
                </div>
              ) : null}
            </section>

            <section className="section-panel bg-teal" id="section-features">
              <div className="content-container">
                {this.state.scrolled ? (
                  <div className="content group">
                    <div className="column third">
                      <div className="icon">
                        <img
                          src="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/creditreporting.png"
                          alt="credit reporting teal"
                        />
                      </div>
                      <div className="snackable-text">
                        <h3>Credit Reporting</h3>
                        <p>
                          Since 2016, Fig loans have built credit, helping you
                          access more affordable credit in the future.
                        </p>
                      </div>
                    </div>
                    <div className="column third">
                      <div className="icon">
                        <img
                          src="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/transparentterms.png"
                          alt="transparent terms teal"
                        />
                      </div>
                      <h3>Transparent Terms</h3>
                      <p>
                        We have no hidden fees or fine print. The rate you see
                        is exactly how much your loan will cost.
                      </p>
                    </div>
                    <div className="column third">
                      <div className="icon">
                        <img
                          src="https://s3.us-west-2.amazonaws.com/figloans-static-images/home/repaid.png"
                          alt="repaid teal"
                        />
                      </div>
                      <h3>Designed to be Repaid</h3>
                      <p>
                        Modeled after home loans because it works, pay the same
                        amount each month and after the last month your loan is
                        paid off.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
            </section>
            {this.state.scrolled ? (
              <section
                className="section-panel bg-white"
                id="section-testimonials"
              >
                <div className="content-container">
                  <div className="content">
                    <h2 style={{ textAlign: "center", width: "100%" }}>
                      Helping support your dreams!
                    </h2>
                    <Trustbox />
                  </div>
                </div>
              </section>
            ) : null}
          </article>
        </SimpleImgProvider>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  location: state.info.location,
  utm: state.entities.utm,
  sessionId: state.session.id
});

const mapDispatchToProps = dispatch => ({
  setUtm: utm => dispatch(setUtm(utm)),
  setLocation: location => dispatch(setState(location))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
