import _, { merge, assign } from "lodash";
import { RECEIVE_PRODUCTS, RECEIVE_STATE } from "../actions/info_actions";
import { RECEIVE_CURRENT_USER } from "../actions/session_actions";
import { standardProducts } from "../helpers/info_helper";

// const defaultState = { products: { loans: { 300: [6, 189]} } };

const infoReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_PRODUCTS:
      const products = _.isEqual(action.products, {})
        ? standardProducts
        : action.products;
      return assign({}, state, { products });
    case RECEIVE_STATE:
      return merge({}, state, { location: action.state });
    case RECEIVE_CURRENT_USER:
      if (action.currentUser && action.currentUser.state) {
        return merge({}, state, { location: action.currentUser.state });
      } else {
        return state;
      }
    default:
      return state;
  }
};

export default infoReducer;
