import { merge } from "lodash";
import {
  RECEIVE_APP_ERRORS,
  RECEIVE_APP_STAGE,
  RECEIVE_EMAIL_CHECK
} from "../actions/application_actions";
import { RECEIVE_CURRENT_USER } from "../actions/session_actions";
import { CLEAR_ERRORS } from "../actions/error_actions";

const defaultState = [];

const applicationErrorsReducer = (oldState = defaultState, action) => {
  Object.freeze(oldState);
  switch (action.type) {
    case RECEIVE_APP_ERRORS:
      if (action.errors.responseJSON){
        return merge({}, oldState, action.errors.responseJSON.errors);
      }
      return merge({}, oldState, action.errors.errors);
    case RECEIVE_APP_STAGE:
    case RECEIVE_CURRENT_USER:
    case CLEAR_ERRORS:
    case RECEIVE_EMAIL_CHECK:
      return {};
    default:
      return oldState;
  }
};

export default applicationErrorsReducer;
