import { domain } from "../helpers/route_helper";

export const getProducts = (state, user_id, source, partner_id) => {
  return window.$.ajax({
    method: "GET",
    url: `${domain}/api/product_offering`,
    data: { user_id, state, source, partner_id }
  });
};

export const submitResume = resume => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/jobs`,
    processData: false,
    contentType: false,
    dataType: "json",
    data: resume
  });
};

export const registerJobCSSStart = token => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/jobs/registerJobCSSStart`,
    data: {
      token
    }
  });
};

export const submitJobCSS = (token, response_1, response_2) => {
  return window.$.ajax({
    method: "POST",
    url: `${domain}/api/jobs/jobsCSS`,
    data: {
      token,
      response_1,
      response_2
    }
  });
};
