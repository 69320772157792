import React, { Component } from "react";
import { Link } from "react-router-dom";

class TagCloud extends Component {
  render() {
    let tags = this.props.tags;
    if (!tags) {
      return null;
    }
    return (
      <div className="tag_cloud">
        {tags.map((tag, i) => {
          return (
            <Link to={`/blog/${tag.replace(/ /g, "-")}`}>
              <p key={i} className="tag" style={{ marginRight: "0.5rem" }}>
                {tag}
              </p>
            </Link>
          );
        })}
      </div>
    );
  }
}

export default TagCloud;
