import { merge } from "lodash";
import { RECEIVE_USER_ERRORS, CLEAR_ERRORS } from "../actions/error_actions";
import { RECEIVE_DEBIT_VALID } from "../actions/user_actions";
import { RECEIVE_LOADING } from "../actions/loader_actions";

const userErrorsReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_USER_ERRORS:
      if (!action.errors) {
        return merge({}, state, {
          0: "Something is wrong with the connection to the server"
        });
      }
      return merge({}, state, action.errors.errors);
    case RECEIVE_DEBIT_VALID:
      if (action.debitValid) {
        return {};
      } else {
        return state;
      }
    case RECEIVE_LOADING:
      if (action.loading) {
        return {};
      } else {
        return state;
      }
    case CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
};

export default userErrorsReducer;
