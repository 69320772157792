import React, { useState, useEffect } from "react";
import { setState } from "../actions/info_actions";
import { changeLoading } from "../actions/loader_actions";
import { sendPageTracking } from "../util/tracking_util";
import { ipLookup } from "../util/user_api_util";
import { createCookie, readCookie } from "../helpers/cookie_helper";
import Modal from "./Modal";
import { timeout } from "../helpers/request_helper";
import { useScrollPosition } from "../helpers/useScrollPosition";

import { connect } from "react-redux";

const StateSelector = ({ setLocation, load, state, utm, sessionId }) => {
  const [ipState, setIpState] = useState();
  const [warningModalComp, setWarningModalComp] = useState(null);
  const [ipLookupRequestMade, setIpLookupRequestMade] = useState(false);

  useEffect(() => {
    if (state) {
      setLocation(state);
      createCookie("state", state);
    }
  }, [state]);

  useScrollPosition(
    ({ currPos }) => {
      if (
        currPos.y >
        (document.body.scrollHeight - window.innerHeight) * 0.01
      ) {
        if (!state && !ipLookupRequestMade) {
          timeout(3000, ipLookup())
            .then((response) => {
              if (
                response.body.country_name &&
                response.body.country_name !== "United States" &&
                readCookie("outsideUsWarning") === null
              ) {
                triggerOutsideUsWarning();
              } else if (
                response.body.country_name &&
                response.body.country_name === "United States"
              ) {
                setIpState(response.body.region_code);
              }
            })
            .catch((error) => {});
          setIpLookupRequestMade(true);
        }
      }
    },
    [ipState, state],
    null,
    true,
    250
  );

  useEffect(() => {
    if (ipState && !state) {
      if (!["FL", "IL", "MO", "OH", "TX", "UT"].includes(ipState)) {
        const behavior = {
          utm_source: utm.source,
          utm_medium: utm.medium,
          utm_content: utm.content,
          utm_campaign: utm.campaign,
          utm_term: utm.term,
          behavior: "state-selector-other",
          anonymous_id: sessionId,
        };
        sendPageTracking(behavior);
      }
      load();
      setLocation(ipState);
      createCookie("state", ipState);
    }
  }, [ipState]);

  const triggerOutsideUsWarning = () => {
    const comp = (
      <div>
        <p>
          Fig Loans does not currently serve customers outside the United
          States. Any organization claiming to be associated with Fig Loans to
          do business outside of the U.S. is not associated with us. We hope to
          be able to bring our financial products to you soon!
        </p>
      </div>
    );
    setWarningModalComp(comp);
  };

  return (
    <div id="state_selector">
      <select
        id="state_selection"
        onChange={(e) => {
          if (!["FL", "MO", "OH", "TX", "UT"].includes(e.target.value)) {
            const behavior = {
              utm_source: utm.source,
              utm_medium: utm.medium,
              utm_content: utm.content,
              utm_campaign: utm.campaign,
              utm_term: utm.term,
              behavior: "state-selector-other",
              anonymous_id: sessionId,
            };
            sendPageTracking(behavior);
          }
          load();
          setLocation(e.target.value);
        }}
        value={state ? state : ipState ? ipState : ""}
      >
        <option value="" disabled hidden>
          Select your state here!
        </option>
        <option value="FL">Florida</option>
        <option value="MO">Missouri</option>
        <option value="OH">Ohio</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="" disabled>
          -----------------
        </option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
      </select>
      {warningModalComp ? (
        <Modal
          closeAction={() => {
            createCookie("outsideUsWarning", "true", null);
          }}
        >
          {warningModalComp}
        </Modal>
      ) : null}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setLocation: (state) => dispatch(setState(state)),
  load: () => dispatch(changeLoading(true)),
});

const mapStateToProps = (state, props) => ({
  state: state.info.location || props.state,
  utm: state.entities.utm,
  sessionId: state.session.id,
});

export default connect(mapStateToProps, mapDispatchToProps)(StateSelector);
