import React, { Component } from "react";
import MetaTags from "../MetaTags";
import { Link } from "react-router-dom";
import Modal from "../Modal";
import { withRouter } from "react-router";

class Scholarship extends Component {
  constructor(props) {
    super(props);
    this.state = { eligibility: false, selected: null };
  }

  componentDidMount() {
    switch (this.props.location.pathname) {
      case "/scholarship/social":
      case "/scholarship/social.html":
        this.setState({ selected: "social" });
        break;
      case "/scholarship/emerge":
      case "/scholarship/emerge.html":
        this.setState({ selected: "emerge" });
        break;
      default:
        break;
    }
  }

  handleClick = type => () => {
    this.setState({ selected: type, eligibility: false });
    this.props.history.push(`/scholarship/${type}`);
  };

  render() {
    let metaTagData, title, intro, requirements, prompt, deadline;

    switch (this.state.selected) {
      case "social":
        metaTagData = {
          title: "Scholarship for Social Impact | Fig Loans",
          og_title: "Scholarship for Social Impact | Fig Loans",
          twitter_title: "Scholarship for Social Impact | Fig Loans",
          description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          og_description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          twitter_description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          og_image:
            "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/hero_scholarship_fb.jpg",
          twitter_image:
            "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/hero_scholarship_twitter.jpg"
        };
        title = "$1000 Scholarship for Social Impact";
        intro =
          "Everyone at Fig is driven by their passion for creating financially healthy communities. Since decision-making at the local level is becoming an important part of civic life, being a good citizen is more important than ever. Fig is committed to supporting that growth in the communities we serve. The Fig Loans scholarship is one of the many ways we hope to empower the leaders of tomorrow. If you have the desire to make a difference with your career, we would love to hear your story!";
        requirements = (
          <ul>
            <li>
              Full-time student enrolled in a two year, four year, or graduate
              program
            </li>
            <li>
              All majors are welcome; positive impact on communities comes in
              all different forms and we celebrate that!
            </li>
            <li>Permanent residents or U.S. Citizens</li>
          </ul>
        );

        prompt = (
          <p>
            <i>
              At Fig, our passion is to create a more inclusive world by{" "}
              <Link to="/about/social-mission">
                expanding access to financial services
              </Link>
              . How will you create a more inclusive world? Through your studies
              or work experience, what is the issue you are tackling? How did
              you become passionate about the issue? How will you use your
              education to create a long standing solution to this issue?
            </i>
          </p>
        );
        deadline =
          "Applications will be reviewed on a rolling basis for two annual awards. Awards will be applied to your Student Account for the following semester";

        break;
      case "emerge":
        metaTagData = {
          title: "Emerge Scholarship for Social Impact | Fig Loans",
          og_title: "Emerge Scholarship for Social Impact | Fig Loans",
          twitter_title: "Emerge Scholarship for Social Impact | Fig Loans",
          description:
            "Financial health begins with responsible education. Check out our continuing education scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          og_description:
            "Financial health begins with responsible education. Check out our continuing education scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          twitter_description:
            "Financial health begins with responsible education. Check out our continuing education scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          og_image:
            "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/hero_scholarship_fb.jpg",
          twitter_image:
            "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/hero_scholarship_twitter.jpg"
        };
        title = "$1000 Emerge Scholarship for Continuing Education";
        intro =
          "Higher Education is one of the greatest achievements a person can accomplish. That’s why so many students return to school after putting education on hold to pursue jobs, start a family or take care of a loved one. Since we partner with Workforce Development programs at Family Houston and HAUL, we know that returning to school is an accomplishment in and of itself. This is why we created a separate scholarship specifically for Continuing Education students. We want to award this scholarship to someone who is going back to school to be a positive example for others in their community. If this sounds like you, we would love to hear your story!";
        requirements = (
          <ul>
            <li>Student enrolled in a Continuing Education Program</li>
            <li>
              You must be within 24 months of completing your educational goal
            </li>
            <li>Permanent residents or U.S. Citizens</li>
            <li>
              Students in doctoral and professional degree programs are not
              eligible
            </li>
          </ul>
        );
        prompt = (
          <p>
            <i>
              At Fig, our passion is helping communities become more financially
              stable by{" "}
              <Link to="/about/social-mission">
                expanding access to financial services
              </Link>
              . How will you create a positive impact on your community? Through
              your studies or work experience, what is the issue you are
              tackling? How did you become passionate about the issue? How will
              you use your education to create a long standing solution to this
              issue?
            </i>
          </p>
        );
        deadline =
          "Applications will be reviewed on a rolling basis for two annual awards. Awards will be applied to your Student Account for the following semester";

        break;
      case "covid":
        metaTagData = {
          title: "Scholarship for Social Impact | Fig Loans",
          og_title: "Scholarship for Social Impact | Fig Loans",
          twitter_title: "Scholarship for Social Impact | Fig Loans",
          description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          og_description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          twitter_description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          og_image:
            "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/hero_scholarship_fb.jpg",
          twitter_image:
            "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/hero_scholarship_twitter.jpg"
        };
        title = "COVID-19 Summer Stipend";
        intro =
          "With the whirlwind of coronavirus disrupting our education system at every level, we at Fig have decided to create a COVID-19 summer stipend to support those who are dedicating their time towards their community. If you are a current student in a two year, four year, or graduate program or a Class of 2020 graduate (congratulations!) interning at a non-profit with a local community-driven mission, or creating your own personal project dedicated to your surrounding community members, we’d love to hear about your work. Sound like you? Learn more below!";
        requirements = (
          <ul>
            <li>
              Full-time student enrolled in a two year, four year, or graduate
              program or a Class of 2020 graduate from a two year, four year, or
              graduate program
            </li>
            <li>Permanent residents or U.S. Citizens</li>
            <li>
              Students in doctoral and professional degree programs are not
              eligible
            </li>
          </ul>
        );
        prompt = (
          <p>
            <i>
              At Fig, our passion is helping communities become more financially
              stable by{" "}
              <Link to="/about/social-mission">
                expanding access to financial services
              </Link>
              . How will you create a positive impact on your community this
              summer? Through your studies or work experience, what is the issue
              you are tackling? How did you become passionate about the issue?
              How will you use your summer work as a step towards creating a
              long standing solution to this issue?
            </i>
          </p>
        );
        deadline =
          "Applications will be reviewed on a rolling basis for two annual awards. Awards will be applied to your Student Account for the following semester";
        break;
      default:
        metaTagData = {
          title: "Scholarship for Social Impact | Fig Loans",
          og_title: "Scholarship for Social Impact | Fig Loans",
          twitter_title: "Scholarship for Social Impact | Fig Loans",
          description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          og_description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          twitter_description:
            "Financial health begins with responsible education. Check out our college scholarship available for Texas, Missouri, Illinois, Ohio and Utah students.",
          og_image:
            "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/hero_scholarship_fb.jpg",
          twitter_image:
            "https://s3.us-west-2.amazonaws.com/figloans-static-images/home/hero_scholarship_twitter.jpg"
        };
        title = "Scholarships";
        break;
    }

    return (
      <div className="blog">
        <div className="container">
          <MetaTags metaTagData={metaTagData} />
          <div className="panel-white">
            <div className="blog-about">
              <div className="blog-about-text">
                <span className="scholarship-title">
                  <img src="https://figloans-static-images.s3.us-west-2.amazonaws.com/logos/fig-logo-2020+(1).png" alt="Fig Logo"/>
                  <h2>{title}</h2>
                </span>
              </div>
            </div>
          </div>
          <div className="panel-grey">
            <div id="scholarship" />
          </div>
          <div className="panel-grey">
            <div id="post_container">
              <div className="post scholarship">
                <p>
                  {" "}
                  <a href="http://www.figtech.com">Fig</a>
                  {"'s"} scholarships for social impact are currently{" "}<b>CLOSED</b>.<br />
                  <br /> Please review which opportunity you are eligible for{" "}
                  <span
                    className="link"
                    onClick={() => this.setState({ eligibility: true })}
                  >
                    HERE
                  </span>
                  . <br />
                  Then, find out how to apply by clicking below!
                </p>
                <div className="scholarship-buttons">
                  <div
                    className={`scholarship-button ${
                      this.state.selected === "social" ? "selected" : null
                    }`}
                    onClick={this.handleClick("social")}
                  >
                    Social Impact Scholarship
                  </div>
                  <div
                    className={`scholarship-button ${
                      this.state.selected === "emerge" ? "selected" : null
                    }`}
                    onClick={this.handleClick("emerge")}
                  >
                    Emerge Scholarship
                  </div>
                </div>
                {this.state.eligibility ? (
                  <Modal
                    open={this.state.eligibility}
                    closeAction={() => this.setState({ eligibility: false })}
                    text={
                      <div>
                        <p>
                          Apply for the{" "}
                          <span
                            className="link"
                            onClick={this.handleClick("social")}
                          >
                            Social Impact Scholarship
                          </span>{" "}
                          if you are a student currently enrolled in a two year,
                          four year or a graduate program
                        </p>
                        <p>
                          Apply for the{" "}
                          <span
                            className="link"
                            onClick={this.handleClick("emerge")}
                          >
                            Emerge Scholarship
                          </span>{" "}
                          if you are a student currently enrolled in Continuing
                          Education courses
                        </p>
                      </div>
                    }
                  />
                ) : null}
                {this.state.selected ? (
                  <div>
                    <div>
                      <p>{intro}</p>
                      <h4>Who are we looking for? (Eligibility)</h4>
                      {requirements}
                      <h4>What do we need from you? (Application)</h4>
                      <ul>
                        <li>Current transcript</li>
                        <li>Resume</li>
                        <li>400 to 800 word essay on the following topic:</li>
                      </ul>
                      {prompt}
                    </div>

                    <hr />
                    <p>{deadline}</p>
                    <p>
                      Please send your resume, transcript and essay to{" "}
                      <a href="mailto:scholarship@figloans.com">
                        scholarship@figloans.com
                      </a>
                      . Please also include your name in the title of your
                      email. After the submission deadline, our team will review
                      all applications and select a winner!
                    </p>
                  </div>
                ) : null}
              </div>
              <div className="disclaimer">
                <u>Disclaimer:</u> Fig Tech Inc. respects the privacy of
                scholarship applicants. Fig Tech Inc. will only collect
                personally identifiable data, such as your name, address,
                telephone number, or e-mail address, when it is voluntarily
                submitted to Fig Tech Inc. at this Website. Other than that, Fig
                Tech Inc. will not use scholarship applicant’s personal
                information for solicitation at any point in time.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Scholarship);
