import { merge } from "lodash";
import { RECEIVE_CURRENT_USER } from "../actions/session_actions";
import { RECEIVE_SESSION_ERRORS, CLEAR_ERRORS } from "../actions/error_actions";

const sessionErrorsReducer = (state = {}, action) => {
  Object.freeze(state);
  switch (action.type) {
    case RECEIVE_SESSION_ERRORS:
      if (!action.errors) {
        return merge({}, state, {
          0: "Something is wrong with the connection to the server"
        });
      }
      return merge({}, state, action.errors.errors);
    case RECEIVE_CURRENT_USER:
    case CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
};

export default sessionErrorsReducer;
