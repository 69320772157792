import React, { useState, useLayoutEffect } from "react";
import Loader from "react-loader";

export default function Calculator(props) {
  const [loanAmount, setLoanAmount] = useState(0);
  const [loanInterestRate, setLoanInterestRate] = useState(0);
  const [loanTerm, setLoanTerm] = useState(0);
  const [loanTermUnit, setLoanTermUnit] = useState("Weeks");
  const [loanType, setLoanType] = useState("installment");
  const [constantPayment, setConstantPayment] = useState(0);
  const [outputDisplayed, setOutputDisplayed] = useState(false);
  const [calculating, setCalculating] = useState(false);
  // const [errorInputs, setErrorInputs] = useState([]);

  function onInputChange(event) {
    document
      .querySelector(".loan-calculator-output")
      .classList.remove("transition");
    const target = event.target;
    let value = target.value;
    const name = target.name;

    if (!["loanTermUnit", "loanType"].includes(name)) {
      value = Number(value);
    }

    if (name === "loanAmount") {
      setLoanAmount(value);
    } else if (name === "loanInterestRate") {
      value /= 100;
      setLoanInterestRate(value);
    } else if (name === "loanTerm") {
      setLoanTerm(Math.round(value));
    } else if (name === "loanType") {
      setLoanType(value);
    } else if (name === "constantPayment") {
      value = Math.round(value * 100) / 100;
      setConstantPayment(value);
    } else {
      setLoanTermUnit(value);
    }

    setOutputDisplayed(false);
  }

  useLayoutEffect(() => {
    if (outputDisplayed) {
      setCalculating(true);

      setTimeout(() => {
        setCalculating(false);

        document
          .querySelector(".loan-calculator-output")
          .classList.remove("invsibile");

        document
          .querySelector(".loan-calculator-output")
          .classList.toggle("transition");

        document.querySelector(".loan-calculator-output").scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }, 1000);
    } else {
      document
        .querySelector(".loan-calculator-output")
        .classList.remove("transition");
    }
  }, [outputDisplayed]);

  const errorCheck = (balloonPayment) => {
    let errors = [];
    if (isNaN(loanInterestRate) || loanInterestRate <= 0) {
      errors.push("Please enter a positive number for APR");
    }
    if (isNaN(loanAmount) || loanAmount <= 0) {
      errors.push("Please enter a positive number for loan amount");
    }
    if (isNaN(loanTerm) || loanTerm <= 0) {
      errors.push(
        "Please enter a positive number for the number of weeks/months/years"
      );
    }

    if (loanType === "balloon") {
      if (balloonPayment < 0) {
        errors.push(
          `A constant payment of $${constantPayment.toFixed(
            2
          )} results in a negative balloon payment amount. Please reduce the size of your constant payment.`
        );
      } else if (isNaN(constantPayment)) {
        errors.push("Please enter a positive number for constant payment");
      }
    }

    if (errors.length === 0) {
      return false;
    } else {
      alert(errors.join("\n"));
      return true;
    }
  };

  let termAmountPerYear = 0;
  switch (loanTermUnit) {
    case "Weeks":
      termAmountPerYear = 52;
      break;
    case "Months":
      termAmountPerYear = 12;
      break;
    case "Years":
      termAmountPerYear = 1;
      break;
    default:
      termAmountPerYear = 12;
  }

  const installment =
    Math.round(
      (((loanInterestRate / termAmountPerYear) * loanAmount) /
        (1 - Math.pow(1 + loanInterestRate / termAmountPerYear, -loanTerm))) *
        100
    ) / 100;
  const totalPaidInInterest =
    Math.round((installment * loanTerm - loanAmount) * 100) / 100;

  const balloonPayment =
    Math.round(
      (loanAmount *
        Math.pow(1 + loanInterestRate / termAmountPerYear, loanTerm) -
        constantPayment *
          ((Math.pow(1 + loanInterestRate / termAmountPerYear, loanTerm) - 1) /
            (loanInterestRate / termAmountPerYear))) *
        100
    ) / 100;

  var totalPayments =
    loanType === "installment"
      ? Math.round(installment * loanTerm * 100) / 100
      : balloonPayment + constantPayment * loanTerm;

  return (
    <div className="post">
      <h3 className="blog_header">
        What Are You Really Paying On Your Personal Loan? Fig Answers!
      </h3>
      <p>Edited on: 31 August 2020</p>
      <p>Posted on: 27 June 2017</p>
      <div id="calculator" className="form-container">
        <div className="form_header">Calculator</div>
        <div className="formwrap group">
          <div className="group">
            <div className="formleft">
              <div className="padding-right-4">
                <span>% </span>
                <input
                  type="text"
                  name="loanInterestRate"
                  inputMode="decimal"
                  id="apr"
                  placeholder="APR (%)"
                  className="text_input currency"
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
            <div className="formright">
              <div className="padding-left-6">
                <span>$</span>
                <input
                  type="text"
                  name="loanAmount"
                  inputMode="numeric"
                  id="amount"
                  placeholder="Loan Amount"
                  className="text_input currency"
                  onChange={(e) => onInputChange(e)}
                />
              </div>
            </div>
          </div>
          <div className="group">
            <div className="formleft">
              <div className="text_description_full">
                <a href="/loan-information/loan-terms-dictionary/#apr">APR</a>:
                the annual interest rate of your loan
              </div>
            </div>
            <div className="formright">
              <div className="text_description_full">
                The amount borrowed (aka principal)
              </div>
            </div>
          </div>
          <div className="group">
            <div className="formleft">
              <input
                type="text"
                name="loanTerm"
                id="number_of"
                inputMode="numeric"
                placeholder="Number of (#)"
                className="text_input"
                onChange={(e) => onInputChange(e)}
              />
            </div>
            <div className="formright">
              <select
                onChange={(e) => onInputChange(e)}
                name="loanTermUnit"
                id="length"
                className="select_input"
              >
                <option>Weeks</option>
                <option>Months</option>
                <option>Years</option>
              </select>
            </div>
          </div>
          <div className="text_description_full">
            How long will you have the loan for? <br />
            <br />
            For example: if the length of your loan is 4 months, enter '4' in
            the left box above and select 'months' on the right. For a 2 year
            loan, enter '2' on the left and select 'years' on the right.
          </div>
          <div className="group">
            <div className="formfull">
              <select
                onChange={(e) => onInputChange(e)}
                name="loanType"
                id="loanType"
                className="select_input"
              >
                <option value="installment">Installment</option>
                <option value="balloon">Balloon</option>
              </select>
            </div>
          </div>
          <div className="text_description_full">
            What type of payments are you making? Installments payoff a loan in{" "}
            <b>equal</b> amounts. A balloon payment is a one lump sum payment at
            the end of the loan.
          </div>
          {loanType === "balloon" ? (
            <div className="group">
              <div className="formleft">
                <select className="select_input">
                  <option>Constant Payment</option>
                </select>
              </div>
              <div className="formright">
                <div className="padding-left-6">
                  <span>$</span>
                  <input
                    type="text"
                    name="constantPayment"
                    inputMode="numeric"
                    id="constantPayment"
                    placeholder="Constant Payment Amount"
                    className="text_input currency"
                    onChange={(e) => onInputChange(e)}
                  />
                </div>
              </div>
            </div>
          ) : null}

          <div className="group">
            <div
              id="calculate"
              className="button primary"
              onClick={() => {
                if (!errorCheck(balloonPayment)) {
                  setOutputDisplayed(true);
                }
              }}
            >
              <Loader
                loaded={!calculating}
                options={{
                  position: "relative",
                  scale: 0.65,
                  color: "#2398a0",
                  margin: "20px",
                }}
              >
                Calculate
              </Loader>
            </div>
          </div>
        </div>
        <div
          className={`loan-calculator-output ${
            outputDisplayed ? "invsibile" : ""
          }`}
        >
          <div id="loan-calculator-output-row-0">
            <p>{loanInterestRate * 100}% APR</p>
            <p>${loanAmount.toLocaleString()} loan</p>
            {loanType === "installment" ? (
              <p>
                Spread evenly over {loanTerm}{" "}
                {loanTerm === 1
                  ? loanTermUnit.slice(0, -1).toLowerCase()
                  : loanTermUnit.toLowerCase()}
              </p>
            ) : (
              <React.Fragment>
                <p>
                  {loanTerm} {loanTermUnit.slice(0, -1).toLowerCase()} term
                </p>
                <p>Balloon with a ${constantPayment} constant payment</p>
              </React.Fragment>
            )}
          </div>
          <div id="loan-calculator-output-row-1">
            {loanType === "installment" ? (
              <React.Fragment>
                <h4>{`Paid ${loanTermUnit.slice(0, -1)}ly`}</h4>
                <h1>${installment.toLocaleString()}</h1>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <h4>Balloon Payment</h4>
                <h1>${balloonPayment.toLocaleString()}</h1>
              </React.Fragment>
            )}
          </div>
          <div id="loan-calculator-output-row-2">
            <div>
              <h4 className="calc-output-subheader">Total Payments</h4>
              <h4>${totalPayments.toLocaleString()}</h4>
            </div>
            <div>
              {loanType === "installment" ? (
                <React.Fragment>
                  <h4 className="calc-output-subheader">Total Interest</h4>
                  <h4>${totalPaidInInterest.toLocaleString()}</h4>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <h4 className="calc-output-subheader">
                    Sum of Constant Payments
                  </h4>
                  <h4>${(constantPayment * loanTerm).toLocaleString()}</h4>
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div className="group">
          <div className="disclaimer">
            Disclaimer: A number of simplifying assumptions have been made for
            the purposes of this tool. For example, this tool assumes every
            month has only 28 days (twice-monthly = bi-weekly). This tool should
            be used for illustrative purposes only. Fig Tech Inc. makes no
            representation of this {"tool's"} accuracy or adherence any legal or
            regulatory doctrine. Please consult your lender with specific
            questions.
          </div>
        </div>
      </div>
      <p>
        Personal loans can get you out of a bind but it’s important to make an
        informed decision before signing on the dotted line! When {"you're"}
        deciding on what makes the best installment loan, the cost of the loan
        is a primary factor to consdier. At Fig, {"we're"} focused on
        transparency so we calculate and market the total cost upfront without
        leaving anything out, but a lot of lenders out there don’t. This is why
        it’s extremely important to calculate the <b>total cost</b> of your loan
        BEFORE you commit!{" "}
        <a href="/loan-information/compare-payday-loans/#payments">
          {" "}
          Can I Make The Payments?
        </a>{" "}
        is so important that {"it's"} the must ask question before taking out a
        personal loan in our{" "}
        <a href="/loan-information/compare-payday-loans">
          How to Compare Loans Guide
        </a>
        .
      </p>
      <p>
        Unfortunately, figuring out your payment amount and total loan cost
        isn’t always easy… {"That's"} why we created this calculator to help you
        know exactly how much a loan will cost you today and down the road.
        Fig’s calculator takes into account <b>APR</b>, <b>loan amount</b>,{" "}
        <b>time</b> and <b>loan type</b> to give you the true cost of your
        personal loan.
      </p>

      <p>
        To use the calculator, first find the APR from your loan documents.
        Specifically, {"we're"} looking for outlined boxes that say finance
        charge in bold. This is known as the Truth in Lending Act disclosure, or
        TILA for short, and must be included in your loan documents. If you{" "}
        {"don't"} see this in the loan documents, run! The TILA disclosure is
        mandated by national law for all consumer loans. A lender who{" "}
        {"doesn't"} include this disclosure is not abiding national law! Sadly,
        this number may be higher than the advertised APR because it includes
        all the fees that lenders probably {"don't"} want to advertise publicly.
        The bolded APR in this box is the closest approximation to the true cost
        of your loan assuming you make all your payments on time.
      </p>
      <p>
        Enter the bolded APR into our calculator and then select the length and
        payment frequency you want to pay back the loan. If you want to see how
        much you can save paying early, just shorten the length of the loan! Did
        our calculator help you? Have suggestions or ideas to improve it? Give
        us a shout at{" "}
        <a href="mailto:service@figloans.com">service@figloans.com</a> or on{" "}
        <a href="https://www.facebook.com/figloans/" rel="nofollow">
          Facebook
        </a>
        !
      </p>

      <p>
        <a href="https://figloans.com/blog">Go back for more figs!</a>
      </p>

      <div className="share_buttons row">
        <a
          href="http://www.facebook.com/share.php?u=https://www.figloans.com/blog/posts/calculator"
          className="share"
          id="fb_share"
        >
          <div className="fb_div">
            <i className="fab fa-facebook-f fa-2x"></i>
          </div>
        </a>
        <a
          href="https://twitter.com/share?url=https://www.figloans.com/blog/posts/calculator"
          target="_blank"
          rel="noopener noreferrer"
          className="share"
          id="twitter_share"
        >
          <div className="twi_div">
            <i className="fab fa-twitter fa-2x"></i>
          </div>
        </a>
        <div className="clear"></div>
      </div>
      <div className="clear"></div>
    </div>
  );
}
