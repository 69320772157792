import React from "react";
import { hydrate, render } from "react-dom";
import "./styles/css/index.css";
import Root from "./components/Root";
import setupPolyfills from "./helpers/polyfills";

// MM: These can likely be removed, just adding as part of react-scripts 2.0.0 upgrade
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/ie9'; // For IE 9 support

// import registerServiceWorker from "./registerServiceWorker";
import configureStore from "./store/store";
import { readCookie, createCookie } from "./helpers/cookie_helper";
import "core-js/es/object";

if (
  window.location.hostname !== "localhost" &&
  window.location.protocol !== "https:"
) {
  window.location.href = `https://${window.location.host}${window.location.pathname}${window.location.search}`;
} else {
  // registerServiceWorker();
  setupPolyfills();
  let store;
  const email = readCookie("email");
  const api_token = readCookie("apiToken");
  let session_id = readCookie("fig-session");
  if (!session_id) {
    session_id =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    createCookie("fig-session", session_id);
  }
  let preloadedState = { session: { id: session_id } };
  if (email && api_token) {
    window.$.ajaxSetup({ data: { token: api_token } });
    preloadedState["session"]["currentUser"] = { email, api_token };
  }
  window.$.ajaxSetup({
    error: (payload) => {
      if (payload.redirect) {
        window.location.href = payload.redirect;
      }
    },
  });
  store = configureStore(preloadedState);

  const rootElement = document.getElementById("root");

  function supportsIntersectionObserver() {
    return (
      "IntersectionObserver" in global &&
      "IntersectionObserverEntry" in global &&
      "intersectionRatio" in IntersectionObserverEntry.prototype
    );
  }

  if (rootElement.hasChildNodes()) {
    hydrate(<Root store={store} />, rootElement);
  } else {
    if (!supportsIntersectionObserver()) {
      // eslint-disable-next-line no-unused-expressions
      import("intersection-observer");
    }
    render(<Root store={store} />, rootElement);
  }
}
