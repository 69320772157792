import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import Slider from "react-rangeslider";
import { isEqual } from "lodash";
import StateSelector from "./StateSelector";
import { createCookie, readCookie } from "../helpers/cookie_helper";
import "../styles/css/loan_selector.css";
import "react-rangeslider/lib/index.css";
import Modal from "./Modal";
import NoticesRatesModal from "./info/NoticesRatesModal";
import Loader from "react-loader";
import MoneyLionLink from "./shared/MoneyLionLink";
import { sendPageTracking } from "../util/tracking_util";
import { statesJSON } from "../helpers/info_helper";

class LoanSelector extends Component {
  static propTypes = {
    application: PropTypes.object.isRequired,
    saveAction: PropTypes.func,
    close: PropTypes.func,
    edit: PropTypes.bool.isRequired,
    product: PropTypes.string,
    partner_id: PropTypes.string,
    products: PropTypes.object
  };

  constructor(props) {
    super(props);

    this.state = {
      amount: this.props.application.details.amount || 300,
      length: this.props.application.details.length || 4,
      product:
        this.props.product ||
        this.props.application.details.product ||
        "traditional_loan",
      partner_id:
        this.props.partner_id || this.props.application.details.partner_id,
      errors: [],
      userId: props.userInfo.id,
      refiConfirm: false,
      showNoticesRatesModal: false
    };
  }

  componentDidMount() {
    // get products if user loads straight to app middle
    if (this.props.state && this.props.state !== "") {
      this.props.getProducts(
        this.props.state,
        this.props.userInfo.id,
        this.props.source,
        this.state.partner_id
      );
    }

    if (this.state.product === "traditional_loan") {
      const amount = parseInt(readCookie("loan_amount"), 10);
      const length = parseInt(readCookie("loan_length"), 10);
      const product = readCookie("loan_product2");
      if (amount && length && product)
        this.setState({ amount, length, product });
    }
  }

  static getDerivedStateFromProps(props, state) {
    const productType = props.product || state.product || "traditional_loan";
    let products = props.products || {
      loans: {
        traditional_loan: {
          300: {
            4: { apr: 1.9, interest: 1.9, pmt: 106.86, frequency: "monthly" }
          }
        },
        community_loan: {
          300: {
            4: { apr: 0.36, interest: 0.36, pmt: 80.71, frequency: "monthly" }
          }
        },
        community_loan_extended: {
          300: {
            4: { apr: 0.36, interest: 0.36, pmt: 80.71, frequency: "monthly" }
          }
        },
        credit_builder: {
          500: {
            12: { apr: 0.28, interest: 0.28, pmt: 48.25, frequency: "monthly" }
          }
        }
      }
    };

    if (isEqual(products.loans, {})) {
      products = {
        loans: {
          traditional_loan: {
            300: {
              4: { apr: 1.9, interest: 1.9, pmt: 106.86, frequency: "monthly" }
            }
          },
          community_loan: {
            300: {
              4: { apr: 0.36, interest: 0.36, pmt: 80.71, frequency: "monthly" }
            }
          },
          community_loan_extended: {
            300: {
              4: { apr: 0.36, interest: 0.36, pmt: 80.71, frequency: "monthly" }
            }
          },
          credit_builder: {
            500: {
              12: {
                apr: 0.28,
                interest: 0.28,
                pmt: 48.25,
                frequency: "monthly"
              }
            }
          }
        }
      };
    }

    const loanAmounts = Object.keys(products.loans[productType]);

    let length, amount;
    if (loanAmounts.length !== 0) {
      const min = parseInt(loanAmounts[0], 10);
      const max = parseInt(loanAmounts[loanAmounts.length - 1], 10);
      amount =
        state.amount >= min && state.amount <= max
          ? state.amount
          : parseInt(loanAmounts[0], 10);
      const lengths = Object.keys(products.loans[productType][amount]);
      length = lengths.includes(state.length)
        ? state.length
        : parseInt(lengths[0], 10);
    } else {
      length = 4;
      amount = 300;
    }

    // if user arrives at home page with location in url
    if (props.state && props.state !== state.state) {
      props.getProducts(
        props.state,
        props.userInfo.id,
        props.source,
        state.partner_id
      );
      return { state: props.state, userId: props.userInfo.id, length, amount };
      // if user signs out/in mid session
    } else if (props.userInfo.id !== state.userId) {
      props.getProducts(
        props.state,
        props.userInfo.id,
        props.source,
        state.partner_id
      );
      return { userId: props.userInfo.id, length, amount };
    } else {
      return { length, amount };
    }
  }

  handleChange = field => {
    return e => {
      let value;
      if (field === "state") {
        value = e.target.value;
        this.props.setLocation(value);
        this.props.getProducts(
          value,
          this.props.userInfo.id,
          null,
          this.state.partner_id
        );
      } else if (!e.target) {
        value = e;
      } else {
        value = e.target.value;
      }
      this.setState({ [field]: value });
    };
  };

  toggleShowNoticesRatesModal = () => {
    this.setState({showNoticesRatesModal: !this.state.showNoticesRatesModal})
  };

  handleSubmit = () => {
    if (
      ["ab06", "ab07"].includes(this.props.userInfo.activeLoanCycle) &&
      !this.state.refiConfirm &&
      this.props.userInfo.loan.currentBalance > 0
    ) {
      this.setState({ refiConfirm: true });
      return;
    }

    if (!this.props.state) {
      this.setState({
        errors: ["You must select a state before clicking Apply Now"]
      });
      return;
    }

    if (!this.props.products.loans) {
      alert(
        "This product is not eligible to be selected. Please refresh the page and try again."
      );
      return;
    }
    const apr = this.props.products.loans[this.state.product][
      this.state.amount
    ][this.state.length]["apr"];

    const loan = {
      apr,
      amount: this.state.amount,
      length: this.state.length,
      product: this.state.product,
      partner_id: this.state.partner_id
    };

    if (this.props.saveAction) {
      this.props.saveAction(this.props.userInfo.id, loan);
    } else {
      this.props.setApplication(loan);
    }
    if (!this.props.edit) {
      this.props.history.push("/app/email?edit=false");
    } else {
      this.props.close();
    }

    // if (window.fbq) window.fbq("trackCustom", "App - Start");
    if (window.dataLayer && !window.dataLayer.find(x => x.event === "App - Start"))
      window.dataLayer.push({
        event: "App - Start",
        conversionValue: this.state.amount,
        utmSource: this.props.userInfo.utmSource
      });

    // createCookie("loan_amount", this.state.amount);
    // createCookie("loan_state", this.props.state);
    // createCookie("loan_product2", this.state.product);
    // createCookie("loan_length", this.state.length);
  };

  makeLabels = (min, max) => {
    let labels = {};

    // for (let i = this.state.min; i <= this.state.max; i += 50) {
    //   labels[i] = `$${i.toString()}`;
    // }
    if (min && max) {
      labels[min.toString()] = `$${min.toLocaleString()}`;
      labels[max.toString()] = `$${max.toLocaleString()}`;
    } else {
      labels["300"] = "$300";
      labels["500"] = "$500";
    }
    return labels;
  };

  render() {
    // prevent error from unchosen state
    let products = this.props.products || {
      loans: {
        traditional_loan: {
          300: {
            4: { apr: 1.9, interest: 1.9, pmt: 178.1, frequency: "monthly" },
            6: { apr: 1.9, interest: 1.9, pmt: 140.12, frequency: "monthly" },
            8: { apr: 1.9, interest: 1.9, pmt: 106.55, frequency: "monthly" }
          }
        },
        community_loan: {
          300: {
            4: { apr: 0.36, interest: 0.36, pmt: 80.71, frequency: "monthly" }
          }
        },
        community_loan_extended: {
          300: {
            4: { apr: 0.36, interest: 0.36, pmt: 80.71, frequency: "monthly" }
          }
        },
        credit_builder: {
          500: {
            12: { apr: 0.28, interest: 0.28, pmt: 48.25, frequency: "monthly" }
          }
        }
      }
    };

    if (isEqual(products.loans, {})) {
      products = {
        loans: {
          traditional_loan: {
            300: {
              4: { apr: 1.9, interest: 1.9, pmt: 106.86, frequency: "monthly" }
            }
          },
          community_loan: {
            300: {
              4: { apr: 0.36, interest: 0.36, pmt: 80.71, frequency: "monthly" }
            }
          },
          community_loan_extended: {
            300: {
              4: { apr: 0.36, interest: 0.36, pmt: 80.71, frequency: "monthly" }
            }
          },
          credit_builder: {
            500: {
              12: {
                apr: 0.28,
                interest: 0.28,
                pmt: 48.25,
                frequency: "monthly"
              }
            }
          }
        }
      };
    }

    let product =
      this.props.product || this.state.product || "traditional_loan";
    const amt = products.loans[product][this.state.amount]
      ? this.state.amount
      : 300;
    const loanAmounts = Object.keys(products.loans[product]);
    // Fallback in case loans come back as empty for product.
    if (loanAmounts.length === 0)
      return (
        <div style={{ color: "red" }}>
          <StateSelector />
          You are not eligible for this loan product at this time. Please
          contact service@figloans.com with any questions.
        </div>
      );
    const min = parseInt(loanAmounts[0], 10) || 300;
    const max = parseInt(loanAmounts[loanAmounts.length - 1], 10) || 300;
    const payment = products.loans[product][amt][this.state.length]["pmt"];
    const apr = products.loans[product][amt][this.state.length]["apr"];
    const frequency =
      products.loans[product][amt][this.state.length]["frequency"];
    let totalLength;
    let length = this.state.length;
    switch (frequency) {
      case "30":
        totalLength = length * 30 + " days";
        break;
      case "monthly":
        totalLength = length + ` month${length > 1 ? "s" : ""}`;
        break;
      case "biweekly":
        totalLength = length * 14 + " days";
        break;
      default:
        totalLength = length + " months";
        break;
    }

    const interest =
      products.loans[product][amt][this.state.length]["interest"];
    const lengths = Object.keys(products.loans[product][amt]);

    const RefiConfirmation = this.state.refiConfirm ? (
      <Modal
        open={this.state.refiConfirm}
        closeAction={() => this.setState({ refiConfirm: false })}
        text={
          <div>
            <h2 style={{ color: "red" }} className="center">
              Refinance Confirmation{" "}
            </h2>
            <p>
              Since you currently have an open loan balance with us, this new
              application will count as a refinance. This means that the new $
              {this.state.amount} loan would pay off your remaining balance of $
              {this.props.userInfo.loan.currentBalance.toFixed(2)} and net you a
            </p>
            <br />

            <h3 style={{ color: "#3fb39d" }} className="center">
              Deposit of: $
              {(
                this.state.amount -
                this.props.userInfo.loan.currentBalance.toFixed(2)
              ).toFixed(2)}
            </h3>
            <p>
              Please keep in mind that your balance will increase over time with
              interest if your application requires a hold for any reason.
            </p>
            <p>
              If you would like a larger deposit, please click "Change My
              Amount!" at the bottom of this message and select a larger loan
              amount.
            </p>

            <br />
            <div
              className="pseudo_submit button primary"
              onClick={this.handleSubmit}
            >
              Confirm!
            </div>
            <div className="form_text_center">
              <a onClick={() => this.setState({ refiConfirm: false })}>
                Change My Amount!
              </a>
            </div>
          </div>
        }
      />
    ) : null;

    let cb = this.props.product === "credit_builder";
    let remitly = this.props.location.pathname === "/credit-builder/remitly";

    // In the following conditions, it either (1) doesn't make sense to show the marketplace
    // link (e.g. "want a bigger loan?").
    // 1. CBs - not a loan!
    // 2. max > 500 - should we be encouraging users in our demographic to take out large loans?
    //    (MM 07/2023 - unsure if there was a regulatory reason for this)
    // 3. in OH we cannot raise the amount a user applies for, or market them to apply for more
    let hideFigMarketplace = cb || max > 500 || this.props.state == 'OH';

    return (
      <div id="loan_slider">
        {!this.props.state ? null : ![
            "FL",
            "MO",
            "OH",
            "TX",
            "UT"
          ].includes(this.props.state) ? (
          <p style={{ textAlign: "center" }}>
            Sorry, Fig does not currently offer loans in{" "}
            {statesJSON[this.props.state]}
          </p>
        ) : null}
        {this.props.noState ? null : <StateSelector state={this.props.partner_id && this.props.partner_id !== "access-ventures" ? "TX" : null}/>}
        <Loader loaded={!this.props.loading}>
          {!this.props.state ? null : ![
              "FL",
              "MO",
              "OH",
              "TX",
              "UT",
              "n/a"
            ].includes(this.props.state) ? (
            <div className="moneylion-container">
              <b>but our trusted partner MoneyLion</b>
              <b>can help you find the right loan today.</b>

              <MoneyLionLink
                source="state"
                sessionId={this.props.sessionId}
                userId={this.props.userInfo.id}
                text="See Your Options!"
                iframe={true}
              />
            </div>
          ) : (
            <div>
              {RefiConfirmation}

              {this.state.showNoticesRatesModal ?
                <NoticesRatesModal closeFcn={this.toggleShowNoticesRatesModal} />
                : null
              }

              <div className="slider_label">
                <div className="text">
                  <p className="p">{cb ? "Savings Amount" : "Loan Amount"}</p>
                </div>
              </div>
              {loanAmounts.length === 1 ? null : (
                <Slider
                  value={amt}
                  onChange={this.handleChange("amount")}
                  min={min}
                  max={max}
                  step={this.state.partner_id === "access-ventures" ? 500 : 50}
                  labels={this.makeLabels(min, max)}
                  tooltip={true}
                />
              )}
              <br />
              {hideFigMarketplace ? null : (
                <div className="looking-for-a-bigger-loan-container">
                  <p
                    className="looking-for-a-bigger-loan-label"
                    onClick={() => {
                      this.setState({ biggerLoanModalOpen: true });
                      const behavior = {
                        utm_source: this.props.utm.source,
                        utm_medium: this.props.utm.medium,
                        utm_content: this.props.utm.content,
                        utm_campaign: this.props.utm.campaign,
                        utm_term: this.props.utm.term,
                        behavior: "loan-selector-looking-for-a-bigger-loan",
                        anonymous_id: this.props.sessionId
                      };
                      sendPageTracking(behavior);
                    }}
                  >
                    Looking for a bigger loan?
                  </p>
                  {this.state.biggerLoanModalOpen ? (
                    <Modal
                      isOpen={this.state.biggerLoanModalOpen}
                      closeAction={() => {
                        this.setState({ biggerLoanModalOpen: false });
                      }}
                    >
                      <div>
                        <h3>Fig Loans</h3>
                        <p>
                          Our first loans start low, which is why {"we're"} able
                          to offer them at the best rates around. As you develop
                          a solid payment history with Fig, our loan amounts
                          increase!
                        </p>
                        {!this.props.userInfo.id ? (
                          <p>
                            {" "}
                            If {"you're"} in need of a loan larger than $1000,
                            feel free to check your options with our partners{" "}
                            <a
                              href="https://compare.figloans.com/products/personal-loan/application/0?utm_source=figloans"
                              className="moneylion-link"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              HERE
                            </a>{" "}
                            and if you {"don't"} see any offers you like, come
                            on back!
                          </p>
                        ) : null}
                      </div>
                    </Modal>
                  ) : null}
                </div>
              )}
              <hr />
              <div className="sliders_right">
                <p>
                  <div className="loan-selector-output-data">
                    {cb ? "Savings Amount" : "Original Loan"}
                    <span id="amount_label_left">${amt.toLocaleString()}</span>
                  </div>
                </p>
                <p>
                  <div className="loan-selector-output-data">
                    {cb ? "Credit Building Length" : "Repayment Length"}
                    <span>
                      {lengths.length > 1 ? (
                        <select
                          onChange={this.handleChange("length")}
                          value={this.state.length}
                        >
                          {lengths.map(length => (
                            <option key={length} value={length}>
                              {length}
                            </option>
                          ))}
                        </select>
                      ) : (
                        totalLength
                      )}
                    </span>
                  </div>
                </p>
                <p>
                  <div className="loan-selector-output-data">
                    Estimated {frequency === "biweekly" ? "Biweekly" : "Monthly"} Payment
                    <span id="slider_monthly_pay">${payment.toFixed(2)}<sup>*</sup></span>
                    {cb ? (
                      <div className="opaque">( all-inclusive! )</div>
                    ) : null}
                  </div>
                </p>

                {cb || true ? null : (
                  <p>
                    <div className="loan-selector-output-data">
                      Total Amount Repaid
                      <span id="slider_total_pay">
                        $
                        {(
                          Math.round(this.state.length * payment * 100) / 100
                        ).toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        })}
                      </span>
                    </div>
                  </p>
                )}
                {cb ? (
                  <p id="slider_apr_label">
                    <div className="loan-selector-output-data">
                      Average Monthly Fee
                      <span id="slider_apr">
                        $
                        {(
                          Math.round(
                            (payment - amt / this.state.length) * 100
                          ) / 100
                        ).toFixed(2)}
                      </span>
                      <div className="opaque">
                        ({" "}
                        {remitly
                          ? `${(apr * 100).toFixed(2)}% APR`
                          : `${(interest * 100).toFixed(2)}% interest`}{" "}
                        )
                      </div>
                    </div>
                  </p>
                ) : (
                  <p id="slider_apr_label">
                    <div className="loan-selector-output-data">
                      Estimated APR
                      <span id="slider_apr">
                        {(apr * 100).toFixed(2)}%<sup>**</sup>
                      </span>
                    </div>
                  </p>
                )}
              </div>
              {cb && !remitly ? (
                <div>
                  {!this.state.moreInfo
                    ? [
                        <div className="opaque">
                          APR: {(apr * 100).toFixed(2)}%
                        </div>,
                        <div
                          onClick={() => this.setState({ moreInfo: true })}
                          className="more-info"
                        >
                          More Pricing Info
                        </div>
                      ]
                    : [
                        <hr />,
                        <div className="sliders_right">
                          <p>
                            Opening Fee:
                            <br />
                            <span id="slider_monthly_pay">
                              ${payment.toFixed(2)}
                            </span>
                            <br />
                            <div className="opaque">( non-refundable )</div>
                          </p>{" "}
                          <p id="slider_apr_label">
                            APR:
                            <br />
                            <span id="slider_apr">
                              {(apr * 100).toFixed(2)}%
                            </span>
                            <br />
                            <div className="opaque">( includes fees )</div>
                          </p>
                          <p id="slider_apr_label">
                            Late Fees:
                            <br />
                            <span id="slider_apr">None!</span>
                          </p>
                          <p id="slider_apr_label">
                            Early Closing Fees:
                            <br />
                            <span id="slider_apr">None!</span>
                          </p>
                        </div>
                      ]}
                </div>
              ) : null}
              <div className="selector-bottom">
                <div>
                  <div className="loan-selector-output-data">
                    <button
                      onClick={this.handleSubmit}
                      className="button primary"
                    >
                      {this.props.edit ? "Save" : "Apply Now"}
                    </button>
                  </div>
                </div>
                {this.props.edit ? (
                  ""
                ) : (
                  <div className="disclosures">
                    <a href="javascript:void(0)" onClick={this.toggleShowNoticesRatesModal} className="disclosures">Notices and Rate Disclosures</a>
                  </div>
                )}
              </div>{" "}
            </div>
          )}
        </Loader>
      </div>
    );
  }
}

export default withRouter(LoanSelector);
