import React from "react";
import { Helmet } from "react-helmet";

const IllinoisRates = ({ products }) => {
  products = products || {
    loans: {
      traditional_loan: {
        "300": { 6: { pmt: 55.38, apr: 0.36 } },
        "350": { 6: { pmt: 64.61, apr: 0.36 } },
        "400": { 6: { pmt: 73.84, apr: 0.36 } },
        "450": { 6: { pmt: 83.07, apr: 0.36 } },
        "500": { 6: { pmt: 92.3, apr: 0.36 } }
      }
    }
  };

  return (
    <div className="container">
      <Helmet>
        <title>Illinois Rates and Notices | Fig Loans</title>
        <meta
          name="description"
          content="Do you need a loan in Illinois? Fig Loans has the friendliest terms in the state!"
        />
        <meta
          property="og:description"
          content="Do you need a loan in Illinois? Fig Loans has the friendliest terms in the state!"
        />
      </Helmet>
      <div className="panel-grey">
        <div id="illinois">
          <div className="row">
            <div className="faq">
              <div className="mission-text center">
                <h4 className='modal-heading'>Illinois</h4>
              </div>
              <p>
                <b>ABOUT PAYDAY LOANS</b>
              </p>
              <p>
                WARNING: This loan is not intended to meet long-term financial
                needs. This loan should be used only to meet short-term cash
                needs. The cost of your loan may be higher than loans offered by
                other lending institutions. This loan is regulated by the
                Department of Financial and Professional Regulation.
              </p>
              <p>
                <i>
                  ADVERTENCIA: Este préstamo no está destinado a satisfacer
                  necesidades financieras a largo plazo. Este préstamo debería
                  ser utilizado únicamente para necesidades de efectivo a corto
                  plazo. El costo de su préstamo puede ser más alto que otros
                  préstamos ofrecidos por otras instituciones financieras. Este
                  préstamo está regulado por el Departamento de Regulación
                  Financiera y Profesional ("Department of Financial and
                  Professional Regulation" en Inglés).
                </i>
              </p>
              <p>
                The Lender (Fig) cannot use the criminal process against a
                consumer to collect any payday loan.
              </p>
              <p>
                <i>
                  El Prestamista (Fig) no puede comenzar un proceso criminal
                  hacia el consumidor para recolectar cualquier préstamo de día
                  de pago.
                </i>
              </p>
              <p>
                INTEREST-FREE REPAYMENT PLAN: If you still owe on one or more
                payday loans, other than an installment payday loan, after 35
                days, you are entitled to enter into a repayment plan. The
                repayment plan will give you at least 55 days to repay your loan
                in installments with no additional finance charges, interest,
                fees, or other charges of any kind.
              </p>
              <p>
                <i>
                  PLAN DE REPAGO SIN INTERESES: Si usted todavía debe uno o más
                  préstamos de día de pago, sin incluir préstamos de día de pago
                  a plazos, después de 35 días, usted tiene derecho a un plan de
                  repago. El plan de repago le dará al menos 55 días para
                  repagar su préstamo a plazos sin cargos adicionales,
                  intereses, tarifas o cargos de cualquier otro tipo.
                </i>
              </p>
              <p>
                <a
                  href="https://www.idfpr.com/dfi/ccd/pdfs/PaydayLoanPamphlet.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Illinois Guide to Payday Loans - English
                </a>
              </p>
              <p>
                <a
                  href="https://www.idfpr.com/dfi/ccd/pdfs/PaydayLoanPamphlet_Spanish.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Guia de Prestamos de Dia de Pago de Illinois - Espanol
                </a>
              </p>

              <b>Fee Examples</b>
              <p>
                Loan costs depend on the amount advanced and length of the loan.
              </p>
              <p>
                <div className="disclosurewrap">
                  <div className="full-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Amount Advanced</th>
                          <td>$100.00</td>
                          <td>$400.00</td>
                          <td>$400.00</td>
                        </tr>
                        <tr>
                          <th>Loan Length</th>
                          <td>13 days</td>
                          <td>30 days</td>
                          <td>180 days</td>
                        </tr>
                        <tr>
                          <th>
                            Loan APR<sup>1</sup>
                          </th>
                          <td>36%</td>
                          <td>36%</td>
                          <td>36%</td>
                        </tr>
                        <tr>
                          <th>Interest Charge</th>
                          <td>$1.30</td>
                          <td>$12.00</td>
                          <td>$43.03</td>
                        </tr>
                        <tr>
                          <th>Total Repayment Amount</th>
                          <td>$101.30</td>
                          <td>$412.00</td>
                          <td>$443.03</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="mobile-size">
                    <table>
                      <tbody>
                        <tr>
                          <th>Sample $100 Dollar 13 Day Loan</th>
                          <td>
                            <b>Amount Advanced:</b> $100.00
                          </td>
                          <td>
                            <b>Loan Length:</b> 13 days
                          </td>
                          <td>
                            <b>
                              Loan APR<sup>1</sup>:
                            </b>{" "}
                            36%
                          </td>
                          <td>
                            <b>Interest Charge:</b> $1.30
                          </td>
                          <td>
                            <b>Total Repayment Amount:</b>
                            <br />
                            $101.30
                          </td>
                        </tr>
                        <tr>
                          <th>Sample $400 Dollar 30 Day Loan</th>
                          <td>
                            <b>Amount Advanced:</b> $400.00
                          </td>
                          <td>
                            <b>Loan Length:</b> 30 days
                          </td>
                          <td>
                            <b>
                              Loan APR<sup>1</sup>:
                            </b>{" "}
                            36%
                          </td>
                          <td>
                            <b>Interest Charge:</b> $12.00
                          </td>
                          <td>
                            <b>Total Repayment Amount:</b>
                            <br /> $412.00
                          </td>
                        </tr>
                        <tr>
                          <th>Sample $400 Dollar 180 Day Loan</th>
                          <td>
                            <b>Amount Advanced:</b> $400.00
                          </td>
                          <td>
                            <b>Loan Length:</b> 180 days
                          </td>
                          <td>
                            <b>
                              Loan APR<sup>1</sup>:
                            </b>{" "}
                            36%
                          </td>
                          <td>
                            <b>Interest Charge:</b> $43.03
                          </td>
                          <td>
                            <b>Total Repayment Amount:</b>
                            <br /> $443.03
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </p>
              <p className="footnote">
                1. The APR is based on a six payment, 180 day amortizing loan.
                The APR shown is based on the federal Truth in Lending Act and
                does not reflect specialized state APR calculations, if any.
                <br />
              </p>
              <p>
                The loan information shown here is an example and may not
                reflect the rates available on all loans. Before entering into
                any transaction, you should read the disclosure statements and
                contract that are provided to you by Fig Loans.
              </p>

              <p>
                {" "}
                <u> Optional Fees </u>{" "}
              </p>
              <p>
                {" "}
                You have the option of paying with a debit card. If you make a
                payment with a debit card, you will not be charged aprocessing
                fee.
              </p>
              <p>
                <b>Notices</b>
              </p>
              <p>
                This lender is regulated by Illinois Department of Financial &
                Professional Regulation . In the event of a complaint or
                concern, Illinois residents may contact the:
                <br />
                <br />
                Illinois Department of Financial & Professional Regulation{" "}
                <br />
                Division of Financial Institutions <br />
                Consumer Credit Section <br />
                100 W. Randolph, Suite 9-100 <br />
                Chicago, IL 60601 <br />
                1-888-298-8089 <br />
                www.idfpr.com
              </p>
              <p>
                <b>LICENSES</b>
              </p>
              <p>
                Fig Loans is a lender licensed by the State of Illinois
                Department of Financial & Professional Regulation.
              </p>
              <p>
                <img
                  src="https://s3.us-west-2.amazonaws.com/figloans-static-images/licenses/illinois_license_2022.png"
                  alt="CAB License"
                  className="img-responsive faq-image"
                  style={{ clip: "rect(0px, 100px, 1000px, 100px)" }}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IllinoisRates;
