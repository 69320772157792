import React from "react";

const APR = () => {
  return (
    <div className="row">
      <div className="glance">
        <h4 id="apr">1. All About APR</h4>
        <div className="apr-text">
          <p>
            The most basic way to compare the cost of online payday loans is by
            looking at the interest rate on the loan over a year. This is the
            APR or Annual Percentage Rate. By law, payday lenders must show you
            the interest rate as an APR. You should be very careful when a loan
            gives you rate that isn’t APR. We’ve seen payday lenders provide
            rates as "$ fees per $100 borrowed," which often makes a loan feel
            cheaper than it really is.
          </p>
          <p>
            We looked at the APRs from 4 of the most commonly used online payday
            lenders throughout Texas.
          </p>
          <p>
            As you can see, the APR can be wildly different even if the
            difference in $ Fee is just a few dollars more.
          </p>
          <p>
            Unfortunately, there’s more that online payday lenders will do to
            hide the rate. We’ve seen lenders hide their APRs far down on pages
            of their website or advertise a lower rate only to add application
            and maintenance fees, which increase the total cost of your loan.
          </p>
        </div>
        <div className="apr-image">
          <div id="peer_comp_table">
            <p className="example_title">
              <b>Example: The cost to borrow a 2 week, $100 Loan</b>
            </p>
            <table cellSpacing="0" cellPadding="0" border="0">
              <tbody>
                <tr>
                  <td className="header" />
                  <td className="header">APR</td>
                  <td className="header">2-Week $ Fee</td>
                </tr>
                <tr>
                  <td>
                    <img
                      src="https://s3.us-west-2.amazonaws.com/figloans-static-images/logos/figlogo_lg.png"
                      alt="Fig Loans"
                      className="peer_comp_table_img"
                    />
                  </td>
                  <td>190%</td>
                  <td>$7.31</td>
                </tr>
                <tr>
                  <td>
                    Lender #1
                  </td>
                  <td>477%</td>
                  <td>$18.35</td>
                </tr>
                <tr>
                  <td>
                    Lender #2
                  </td>
                  <td>661%</td>
                  <td>$25.42</td>
                </tr>
                <tr>
                  <td>
                    Lender #3
                  </td>
                  <td>680%</td>
                  <td>$26.15</td>
                </tr>
              </tbody>
            </table>
            <p className="disclaimer">
              1. Please check respective {"lenders'"} websites for latest up to
              date rates. These rates are for illustrative purposes only.
            </p>
          </div>
        </div>
        <div className="clear" />
      </div>
    </div>
  );
};

export default APR;
