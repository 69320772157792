import React from "react";
import { Link } from "react-router-dom";

const Fees = () => {
  return (
    <div className="row">
      <div className="overtime">
        <h4 id="fees">4. Are there extra fees?</h4>
        <div className="center">
          <p>
            <a href="#res_fees">
              Is there a late fee for rescheduling payments? How much is it?
            </a>
          </p>
          <p>
            <a href="#nsf_fees">
              Is there a fee if my payment is returned? How much is it?
            </a>
          </p>
          <p>
            <a href="#ear_fees">Does it cost more to repay my loan early?</a>
          </p>
        </div>
        <p>
          These are questions you should ask your payday lender before accepting
          a loan. It’s important to get clear answers and double check the fees
          in your loan documents before signing!
        </p>
        <p>
          <b>
            <i>
              Be extra careful with fees because they are NOT counted in the APR
              of the loan but very significantly affect the total cost of your
              loan.
            </i>
          </b>
        </p>
        <p>
          Unfortunately, fees allow lenders to make more money without telling
          you that the total cost of the loan will be higher. But {"we'll"} give
          you the tools to call them out! Below are the most notable fees that
          you should look out for:
        </p>
        <br />
        <h5 id="res_fees">Late or Change Fees</h5>
        <div className="overtime-text">
          <p>
            What if you need to reschedule? Even if your payment is 1 day late,
            you will be at the mercy of your state’s regulation on grace period
            (and without which payday lenders often charge a late fee up to $30!)
            The best place to find the late or change fees is in your loan documents,
            although sometimes they are shown in the Consumer Disclosure. We’ve included
            an example of a Consumer Disclosure with late fees highlighted. If
            there is no mention of late fees anywhere, call the payday lender to
            make sure before you take out a loan!
          </p>
          <p>
            Only payments for installment loans can be changed! Customers are
            often promised “rescheduling” for payday (balloon) payments but
            actually end up paying to reborrow or “rollover” the loan instead.
            This may seem like extending your payment, but is actually a trick
            to trap you in debt. The payday lender will often ask you to pay all
            your interest + fees on the original due date and then continue to
            charge you interest + fees so you’ve basically taken out a new loan!
            <br />
            <b>
              When you contact Fig to change a payment,{" "}
              <Link to="/about/responsible-lending-model">
                you will never be charged a late or change fee
              </Link>!
            </b>
          </p>
        </div>
        <div className="overtime-image">
          <img
            src="https://s3.us-west-2.amazonaws.com/figloans-static-images/informational/hidden_fees_4.jpg"
            alt="Payday Lender"
            className="hidden_fees_img"
          />
          <p className="disclaimer">
            1. Hidden fees are not just fees usually not disclosed, but also including fees
            that are disclosed only in the loan agreement
          </p>
        </div>
        <div className="clear" />
        <div className="overtime-text mobile">
          <p>
            Since only installment loans can be rescheduled, let’s look at the
            impact of late and rescheduling fees on a Lender D $100 loan for 4
            months at 680% APR in our previous example.
          </p>
          <p>
            Using a sample online payday lender’s rates, if your payment is more
            than 10 days late your late fees will be $20. So if we paid a loan
            payment 10 days late to Payday Lender D, we would be charged an
            additional $20.
          </p>
          <p>
            If your original Total Cost of Loan without Fees was $271, a single
            late fee can increase your Total Cost of Loan by over $20! If you
            reschedule two payments or the same payment twice, you are looking
            at an extra $40 that you didn’t realize you needed to pay!
          </p>
          <p>
            If you are at all worried about making every payment in full and on
            time, you will want to look closely at a lender’s late or
            rescheduling fees and factor them into your Total Cost of Loan With
            Fees.
          </p>
        </div>
        <div className="overtime-image">
          <div id="fee_table">
            <p className="example_title">
              <b>
                {" "}
                Loan D Example: $100, 4-month Installment Loan with Late Charge
              </b>
            </p>
            <table cellSpacing="0" cellPadding="0" border="0">
              <tbody>
                <tr>
                  <td rowSpan="5">
                    <img
                      src="https://s3.us-west-2.amazonaws.com/figloans-static-images/peers/freedom_cash_r180.png"
                      alt="Payday Lender"
                      className="fee_table_img"
                    />
                  </td>
                  <td>Amount per Loan Payment</td>
                  <td>$67.95</td>
                </tr>
                <tr>
                  <td>Total Paid Back with no Fees</td>
                  <td>$271.78</td>
                </tr>
                <tr>
                  <td>Late Charge</td>
                  <td>$20.00</td>
                </tr>
                <tr>
                  <td>Total Paid Back Including Fees</td>
                  <td>$291.78</td>
                </tr>
                <tr>
                  <td>% Increase in Total Paid Back</td>
                  <td>7%</td>
                </tr>
              </tbody>
            </table>
            <p className="disclaimer">
              1. This example uses hypothetical information and is for
              illustrative purposes only.
            </p>
          </div>
        </div>
        <div className="overtime-text web">
          <p>
            Since only installment loans can be rescheduled, let’s look at the
            impact of late and rescheduling fees on a Lender D $100 loan for 4
            months at 680% APR in our previous example.
          </p>
          <p>
            Using a sample online payday lender’s rates, if your payment is more
            than 10 days late your late fees will be $20. So if we paid a loan
            payment 10 days late to Payday Lender D, we would be charged an
            additional $20.
          </p>
          <p>
            If your original Total Cost of Loan without Fees was $271, a single
            late fee can increase your Total Cost of Loan by over $20! If you
            reschedule two payments or the same payment twice, you are looking
            at an extra $40 that you didn’t realize you needed to pay!
          </p>
          <p>
            If you are at all worried about making every payment in full and on
            time, you will want to look closely at a lender’s late or
            rescheduling fees and factor them into your Total Cost of Loan With
            Fees.
          </p>
        </div>
        <div className="clear" />

        <br />
        <h5 id="nsf_fees">
          NSF Fees -- AKA Returned Item or Insufficient Fund Fees
        </h5>
        <p>
          NSF fees occur when one of your payments is returned by your bank to
          the lender. The bank often charges you an overdraft fee, but some
          payday lenders will charge an additional fee on top of that. This is
          very similar to a returned check fee you might see at grocery stores.
        </p>
        <div className="overtime-text">
          <p>
            A single returned payment can cause you to owe an extra $65. How? If
            your payment bounces, a bank overdraft fee is $35 and the lender’s
            NSF fee is another $30!
          </p>
          <p>
            {"Let's"} look at Loan D with NSF Fees. If your payment is returned
            your bank would charge you $35 and then Payday Lender D would charge
            you an additional $30 in fees. This would take your original payment
            of $67.95 up to $132.95. That’s almost double your original payment
            amount!
          </p>
          <p>
            Our problem with NSF fees is that they are ENTIRELY AVOIDABLE when a
            lender and borrower work together. <br />
            <b>
              At Fig we work with you avoid NSF fees, and it works:{" "}
              <Link to="/about/responsible-lending-model">
                we have no change fees
              </Link>!
            </b>
          </p>
        </div>
        <div className="overtime-image">
          <div id="fee_table">
            <p className="example_title">
              <b>Loan D Example: $100 4-Month Installment Loan with NSF Fee</b>
            </p>
            <table cellSpacing="0" cellPadding="0" border="0">
              <tbody>
                <tr>
                  <td rowSpan="5">
                    <img
                      src="https://s3.us-west-2.amazonaws.com/figloans-static-images/peers/freedom_cash_r180.png"
                      alt="Payday Lender"
                      className="fee_table_img"
                    />
                  </td>
                  <td>Amount per Loan Payment</td>
                  <td>$67.95</td>
                </tr>
                <tr>
                  <td>Total Paid Back with no Fees</td>
                  <td>$271.78</td>
                </tr>
                <tr>
                  <td>Return + Bank Fee</td>
                  <td>$30.00 + $35.00</td>
                </tr>
                <tr>
                  <td>Total Paid Back Including Fees</td>
                  <td>$336.78</td>
                </tr>
                <tr>
                  <td>% Increase in Total Paid Back</td>
                  <td>24%</td>
                </tr>
              </tbody>
            </table>
            <p className="disclaimer">
              1. This example uses hypothetical information and is for
              illustrative purposes only.
            </p>
          </div>
        </div>
        <div className="clear" />
        <br />
        <h5 id="ear_fees">Early Repayment Fees</h5>
        <p>
          Payday lenders often charge extra when a borrower wants to pay off
          their loan before the due date. This is called an Early Repayment Fee.
          They do this because they make less money when a loan is paid back
          early!
        </p>
        <p>
          Payday lenders should not make it harder (and more expensive) for good
          borrowers who are trying to settle thier debt. It shouldn’t be up to
          them!
        </p>
        <p>
          When you see an early repayment fee, you should question if the lender
          is truly interested in helping you improve your financial future.
          <br />
          <b>
            You will never find an early repayment fee at Fig. You can pay off
            your loan early and{" "}
            <Link to="/about/social-mission">
              {"we'll"} even give you a discount
            </Link>!
          </b>
        </p>

        <div className="clear" />
      </div>
    </div>
  );
};

export default Fees;
